var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      class: ["video-promo", _vm.banner.class],
      attrs: { visible: _vm.visible },
      on: { close: _vm.close },
    },
    [
      _c("template", { slot: "content" }, [
        _c("button", {
          staticClass: "video-promo__close",
          on: { click: _vm.close },
        }),
        _c("div", { staticClass: "video-promo__img" }, [
          _vm.banner.img
            ? _c(
                "picture",
                [
                  _vm._l(_vm.banner.img.sources, function (item, i) {
                    return [
                      item.webp
                        ? _c("source", {
                            attrs: {
                              srcset: item.webp,
                              type: "image/webp",
                              media: item.media,
                            },
                          })
                        : _vm._e(),
                      item.jpg
                        ? _c("source", {
                            attrs: { srcset: item.jpg, media: item.media },
                          })
                        : _vm._e(),
                    ]
                  }),
                  _c("img", {
                    staticClass: "video-promo__image",
                    attrs: { src: _vm.banner.img.default, loading: "lazy" },
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.banner.video
            ? _c(
                "video",
                {
                  ref: "video",
                  staticClass: "video-promo__video",
                  attrs: {
                    poster: _vm.banner.video.poster,
                    autoplay: "autoplay",
                    loop: "loop",
                    muted: "",
                    playsinline: "",
                  },
                  domProps: { muted: true },
                },
                _vm._l(_vm.banner.video.sources, function (item, i) {
                  return _c("source", {
                    key: i,
                    attrs: {
                      type: item.type,
                      src: item.src,
                      media: item.media,
                    },
                  })
                }),
                0
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "video-promo__content" },
          [
            _c("div", { staticClass: "video-promo__content-box" }, [
              _c("h1", {
                staticClass: "video-promo__title",
                domProps: { innerHTML: _vm._s(_vm.banner.title) },
              }),
              _c("div", {
                staticClass: "video-promo__text",
                domProps: { innerHTML: _vm._s(_vm.banner.description) },
              }),
            ]),
            _c(
              "UiButton",
              {
                staticClass: "video-promo__btn",
                attrs: {
                  "data-popup-with-chats": "",
                  "data-popup-with-chats-title":
                    _vm.banner.text_button || "Узнать подробнее",
                },
                on: { click: _vm.close },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.banner.text_button || "Узнать подробнее") +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }