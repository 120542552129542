var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "time-block__container": true,
        "time-block__container_mobile": _vm.mobile,
      },
    },
    [
      _c("div", { staticClass: "time-block__content" }, [
        _c(
          "div",
          { staticClass: "time-block__list" },
          _vm._l(_vm.items, function (item, index) {
            return _c("div", { key: index }, [
              _c(
                "div",
                {
                  class: [
                    "time-block__item",
                    "time-item",
                    index === _vm.items.length - 1 || _vm.dismissAfterElem
                      ? "dismiss-after"
                      : "",
                  ],
                },
                [
                  _c("div", { staticClass: "time-item__content" }, [
                    _c("div", { staticClass: "time-item__header" }, [
                      _c("span", { staticClass: "time-item__header_big" }, [
                        _vm._v(_vm._s(item.big)),
                      ]),
                      _c("span", { staticClass: "time-item__header_normal" }, [
                        _vm._v(_vm._s(item.normal)),
                      ]),
                    ]),
                    _c("div", { staticClass: "time-item__text" }, [
                      _vm._v(" " + _vm._s(item.text) + " "),
                    ]),
                  ]),
                ]
              ),
            ])
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "time-block__text" }, [_vm._t("block")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }