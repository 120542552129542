<template>
  <button :disabled="disabled" :class="classes" @click="click">
    <slot />
  </button>
</template>

<script>
export default {
  name: "UiButton",
  props: {
    outline: {
      type: Boolean,
      default: false,
    },
    line: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "ui-button": true,
        "ui-button--outline": this.outline,
        "ui-button--light": this.light,
        "ui-button--line": this.line,
        "ui-button--loading": this.loading,
      };
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.ui-button {
  $this: &;
  padding: 12px 35px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 300ms ease-out;
  font-size: 14px;
  letter-spacing: 0.2em;
  outline: none;
  font-family: $font-family-bebas;
  border: 1px solid #e14313;
  background-color: #e14313;
  color: #ffffff;

  &:hover {
    background-color: #ff5926;
    border: 1px solid #ff5926;
  }

  &:active {
    background-color: #c63206;
    border: 1px solid #c63206;
  }

  &:disabled {
    opacity: 0.8;
    background-color: #e14313;
    color: #ffffff;
    pointer-events: none;
  }

  &--outline {
    background-color: transparent;
    border: 1px solid #e14313;
    color: #e14313;

    &#{$this}--light {
      border: 1px solid #ffffff;
      color: #ffffff;

      &:disabled {
        border: 1px solid #ffffff;
        color: #ffffff;
        background-color: transparent;

        &:hover {
          border: 1px solid #ffffff;
          color: #ffffff;
          background-color: transparent;
        }
      }
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid #e14313;
      color: #e14313;

      &:hover {
        color: #e14313;
        border: 1px solid #e14313;
      }
    }

    &:hover {
      border-color: #ff5926;
      color: #ffffff;
    }

    &:active {
      border-color: #c63206;
      color: #ffffff;
    }
  }

  &--line {
    padding: 14px 14px;
    background-color: transparent;
    color: #e14313;
    border: none;
    &:hover {
      background-color: transparent;
      border: none;
    }
  }

  &--loading {
    background-color: #ff5926;
    color: #fff;
    animation-name: loading;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;

    @keyframes loading {
      0% {
        opacity: 0.6;
      }

      50% {
        opacity: 0.8;
      }

      100% {
        opacity: 0.6;
      }
    }
  }
}
</style>
