var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    { attrs: { visible: _vm.visible }, on: { close: _vm.close } },
    [
      _c("modal-success", {
        attrs: { slot: "content" },
        on: { close: _vm.close },
        slot: "content",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }