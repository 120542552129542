var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: { "icon-arrow-left": true },
      attrs: {
        width: "48",
        height: "13",
        viewBox: "0 0 48 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          r: "4.5",
          transform: "matrix(-1 0 0 1 43 7)",
          stroke: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M31 6.5H1M1 6.5L6.83333 1M1 6.5L6.83333 12",
          stroke: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }