<template>
  <modal-dialog
    :visible="visible"
    class="smart-house-modal-dialog"
    @close="close"
  >
    <smart-house-modal-block
      slot="content"
      :image-desk="require('@/assets/images/promo/img_desk_tabl.png')"
      :image-mob="require('@/assets/images/promo/img_mob.png')"
      href="https://profi.erzrf.ru/poll/smart-home-technology-for-buyers/?utm_source=zk-mainstreet.ru"
      @hide="close"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";
import SmartHouseModalBlock from "@/components/blocks/SmartHouseModalBlock";

export default {
  name: "SubscribeModalDialog",
  components: { ModalDialog, SmartHouseModalBlock },
  data: () => ({
    id: "subscribe",
    visible: false,
    name: "",
    phone: "",
    checkbox: true,
    success: false,
    description: null,
    title: null,
    buttonName: null,
    idModal: null,
  }),
  computed: {
    isPhoneValid() {
      return this.phone.length >= 18 || this.phone.length < 1;
    },
  },
  methods: {
    show(title = null, description = null, buttonName = null, idModal = null) {
      this.visible = true;
      this.name = "";
      this.phone = "";
      this.checkbox = true;
      this.success = false;
      this.title = title;
      this.buttonName = buttonName;
      this.description = description;
      this.idModal = idModal;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.smart-house-modal-dialog {
  display: flex;
  ::v-deep {
    .modal-dialog__body {
      background: transparent;
      padding: 15px;
      width: auto;
      box-shadow: none;
      margin: auto;
      top: 0;
    }
    .modal-body__close {
      display: none;
    }
    .modal-body__title {
      display: none;
    }
  }
}
</style>
