<template>
  <div>
    <the-header banner-visible />
    <transition name="fade" mode="out-in">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

export default {
  name: "Default",
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      heading: "this-default-layouts",
    };
  },
};
</script>
