var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: {
        "icon-arrow-right": true,
        "icon-arrow-right--hovered": _vm.hovered,
      },
      attrs: {
        width: "48",
        height: "13",
        viewBox: "0 0 48 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "5", cy: "7", r: "4.5", stroke: _vm.color },
      }),
      _c("path", {
        attrs: {
          d: "M17 6.5H47M47 6.5L41.1667 1M47 6.5L41.1667 12",
          stroke: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }