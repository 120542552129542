<template>
  <svg
    :class="{ 'icon-arrow-left': true }"
    width="48"
    height="13"
    viewBox="0 0 48 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle r="4.5" transform="matrix(-1 0 0 1 43 7)" :stroke="color" />
    <path d="M31 6.5H1M1 6.5L6.83333 1M1 6.5L6.83333 12" :stroke="color" />
  </svg>
</template>

<script>
export default {
  name: "IconArrowLeft",
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style scoped lang="scss"></style>
