var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      staticClass: "smart-house-modal-dialog",
      attrs: { visible: _vm.visible },
      on: { close: _vm.close },
    },
    [
      _c("smart-house-modal-block", {
        attrs: {
          slot: "content",
          "image-desk": require("@/assets/images/promo/img_desk_tabl.png"),
          "image-mob": require("@/assets/images/promo/img_mob.png"),
          href: "https://profi.erzrf.ru/poll/smart-home-technology-for-buyers/?utm_source=zk-mainstreet.ru",
        },
        on: { hide: _vm.close },
        slot: "content",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }