<template>
  <modal-dialog class="video-review" :visible="visible" @close="close">
    <div slot="content" class="video-review__container">
      <button class="video-review__close" @click="close">×</button>
      <iframe
        class="video-review__frame"
        src="https://www.youtube.com/embed/4TqDZ71GPKk?autoplay=1&controls=1&disablekb=1&fs=0&modestbranding=1&loop=1&rel=0"
        title="ЖК «MainStreet» | Обзор проекта и его окружения | Ключевые факторы при выборе правильного жилья"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        >
      </iframe>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";

export default {
  name: "SuccessModalDialog",
  components: { ModalDialog },
  data: () => ({
    visible: false,
  }),
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.video-review {
  display: flex;
  &__frame {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }
  &__container {
    padding-bottom: 56.25%;
    position: relative;
  }
  &::v-deep {
    .modal-dialog__body {
      width: 100%;
      max-width: 1280px;
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 0;
      padding-top: 0;
      top: auto;
      margin-top: auto;
      margin-bottom: auto;
      overflow: visible;
    }
    .modal-body__close {
      display: none;
    }
    .modal-body__title {
      display: none;
    }
  }
  &__close {
    position: absolute;
    top: -50px;
    right: 0;
    color: #fff;
    font-size: 40px;
    margin-left: auto;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}
</style>
