var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dayCount > 0
    ? _c("div", { staticClass: "countdown" }, [
        _c("div", {
          staticClass: "countdown__box",
          domProps: { innerHTML: _vm._s(_vm.title) },
        }),
        _c("div", { staticClass: "countdown__table" }, [
          _c(
            "div",
            {
              key: _vm.intervalDayCount,
              staticClass: "countdown__cards",
              class: { "countdown__cards--flip": _vm.animationActive },
            },
            [
              _c("div", { staticClass: "countdown__card" }, [
                _c("div", { staticClass: "countdown__card-top" }, [
                  _vm._v(_vm._s(_vm.intervalDayCount)),
                ]),
                _c("div", {
                  staticClass: "countdown__card-bottom",
                  attrs: { "data-i": _vm.intervalDayCount + 1 },
                }),
                _c(
                  "div",
                  {
                    staticClass: "countdown__card-back",
                    attrs: { "data-i": _vm.intervalDayCount + 1 },
                  },
                  [
                    _c("div", {
                      staticClass: "countdown__card-bottom",
                      attrs: { "data-i": _vm.intervalDayCount },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "countdown__line" }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }