<template>
  <modal-dialog :visible="visible" @close="close">
    <template v-if="!success" slot="title">
      {{ title ? title : "Прописка уже скоро!" }}
    </template>
    <div v-if="success" slot="content" class="presentation-modal">
      <modal-success @close="close" />
    </div>
    <div
      v-if="!success"
      :id="idModal"
      slot="content"
      class="presentation-modal"
    >
      <div class="presentation-modal__advantages">
        <div v-if="shownPresentation" class="presentation advantages">
          <div class="advantages-item">
            <icon-type-finish />
            <p class="advantages-item__desc">Виды отделки с примерами</p>
          </div>
          <div class="advantages-item">
            <icon-use-material />
            <p class="advantages-item__desc">используемые материалы</p>
          </div>
          <div class="advantages-item">
            <icon-features />
            <p class="advantages-item__desc">Особенности жилого комплекса</p>
          </div>
        </div>

        <div v-else class="booklet advantages">
          <div class="advantages-item">
            <icon-advantag-apartartament />
            <p class="advantages-item__desc">Презентация апартаментов</p>
          </div>
          <div class="advantages-item">
            <icon-material-features />
            <p class="advantages-item__desc">Материалы и особенности</p>
          </div>
          <div class="advantages-item">
            <icon-unique />
            <p class="advantages-item__desc">Акционные предложения</p>
          </div>
        </div>
      </div>
      <form
        :id="id"
        ref="presentation-form"
        method="POST"
        class="presentation-modal__form presentation-form"
        @submit.prevent="submit"
      >
        <div class="presentation-form__row">
          <div class="presentation-form__col">
            <ui-input
              :id="id + '-name'"
              ref="form-name"
              v-model="name"
              placeholder-value="Имя"
              hint="Введите корректные данные"
              type="text"
              placeholder-white
              required
            />
          </div>
          <div class="presentation-form__col">
            <ui-input
              :id="id + '-phone'"
              ref="form-phone"
              v-model="phone"
              v-mask="'+7 (###) ###-##-##'"
              type="tel"
              hint="Введите корректный номер телефона"
              placeholder-value="телефон"
              placeholder-white
              required
            />
            <div v-if="!isPhoneValid" class="form-phone_valid">
              Введите корректный номер телефона
            </div>
          </div>
        </div>
        <div class="presentation-form__row">
          <ui-button type="submit" @click="submitForm">
            {{ buttonName }}
          </ui-button>
        </div>
        <div class="presentation-form__row">
          <ui-input
            :id="id + '-agreement'"
            ref="form-agreement"
            v-model="checkbox"
            class="row"
            type="checkbox"
            dark
            required
          >
            <template #label>
              <span
                >Нажимая кнопку «Отправить», я соглашаюсь с политикой обработки
                <router-link :to="{ name: 'UserAgreementPage' }" target="_blank"
                  >персональных данных</router-link
                >
              </span>
            </template>
          </ui-input>
        </div>
      </form>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";
import UiInput from "@/components/ui/UIInput";
import UiButton from "@/components/ui/UIButton";
import ModalSuccess from "@/components/dialogs/ModalSuccess";
import IconAdvantagApartartament from "@/components/icons/IconAdvantagApartartament";
import IconTypeFinish from "@/components/icons/IconTypeFinish";
import IconUseMaterial from "@/components/icons/IconUseMaterial";
import IconFeatures from "@/components/icons/IconFeatures";
import IconMaterialFeatures from "@/components/icons/IconMaterialFeatures";
import IconUnique from "@/components/icons/IconUnique";

export default {
  name: "SubscribeModalDialog",
  components: {
    ModalSuccess,
    UiButton,
    UiInput,
    ModalDialog,
    IconAdvantagApartartament,
    IconTypeFinish,
    IconUseMaterial,
    IconFeatures,
    IconUnique,
    IconMaterialFeatures,
  },
  data: () => ({
    id: "presentation",
    idModal: null,
    visible: false,
    name: "",
    phone: "",
    checkbox: true,
    success: false,
    title: null,
    buttonName: null,
    shownPresentation: Boolean,
  }),
  computed: {
    isPhoneValid() {
      return this.phone.length >= 18 || this.phone.length < 1;
    },
  },
  methods: {
    show(
      title = null,
      buttonName = null,
      shownPresentation = true,
      idModal = null
    ) {
      this.visible = true;
      this.name = "";
      this.phone = "";
      this.checkbox = true;
      this.success = false;
      this.title = title;
      this.buttonName = buttonName;
      this.shownPresentation = shownPresentation;
      this.idModal = idModal;
    },
    close() {
      this.visible = false;
    },
    sendMessage() {
      window.Comagic.addOfflineRequest(
        {
          name: "Имя: " + this.name + " | " + "Форма: " + this.title,
          phone: this.phone,
          message:
            "Описание: " +
            this.title +
            " | " +
            "URL: " +
            document.URL +
            " | " +
            "ID: " +
            this.idModal,
        },
        () => {}
      );
      this.name = "";
      this.phone = "";
    },
    submit(e) {
      if (this.phone.length === 18 && this.name.trim()) {
        this.success = true;
        this.sendMessage();
        e.preventDefault();
      }
    },
    submitForm() {
      this.$refs["form-name"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-phone"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-agreement"].$refs.VueInputUi.classList.add("attempt");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-body__title {
  text-align: center !important;
}
.presentation-modal {
  .advantages {
    display: block;

    @media screen and (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      border-bottom: 1px solid #4c4c4c;
    }

    &-item {
      display: flex;
      margin-bottom: 10px;

      @media screen and (min-width: $screen-sm) {
        display: block;
      }

      &__desc {
        font-family: Bebas Neue;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.1em;
        line-height: 1.5;
        color: #ffffff;
        margin-left: 15px;

        @media screen and (min-width: $screen-sm) {
          margin-left: 0;
          width: 75%;
        }

        @media screen and (min-width: $screen-md) {
          width: 99%;
        }
      }
    }
  }
  .booklet {
    .advantages-item {
      margin-right: 10px;
    }
  }
  &__form,
  .presentation-form {
    margin-top: 30px;
    &__row {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
      }
    }

    &__col {
      position: relative;
      display: block;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;

      @media screen and (min-width: $screen-sm) {
        display: flex;
        flex-direction: column;
      }

      .vue-input-ui {
        margin-top: 10px;
      }

      .ui-button {
        margin-top: 30px;
        height: 45px;
        @media screen and (min-width: $screen-sm) {
          margin: 0 0 10px 30px;
        }
      }

      @media screen and (min-width: $screen-sm) {
        width: 100%;
      }

      &:first-child {
        padding-right: 0;

        @media screen and (min-width: $screen-sm) {
          padding-right: 15px;
        }
      }

      &:last-child {
        padding-left: 0;
      }

      .form-phone_valid {
        position: absolute;
        bottom: -9px;
        font-family: Rubik;
        font-size: 12px;
        text-transform: none;
        color: #ed0000;
        line-height: 132.5%;
      }
    }
  }
}
</style>
