var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-success" }, [
    _c(
      "div",
      {
        staticClass: "modal-success__body",
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c("icon-success", { staticClass: "modal-success__icon" }),
        _c("div", { staticClass: "modal-success__title" }, [
          _vm._v("Заявка принята!"),
        ]),
        _c("div", { staticClass: "modal-success__text" }, [
          _vm._v(" консультант свяжется с Вами в ближайшее время "),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "modal-success__footer",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "ui-button",
          {
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" хорошо! ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }