var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-item" }, [
    _c("div", { staticClass: "content-item__icon" }, [_vm._t("icon")], 2),
    _c("div", { staticClass: "content-item__title" }, [
      _c("div", { staticClass: "content-item__text" }, [_vm._t("text")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }