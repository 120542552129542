var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-line" },
    _vm._l(_vm.list, function (item, i) {
      return _c(
        item.href ? "a" : "div",
        {
          key: i,
          tag: "component",
          class: [
            "info-line__item",
            {
              "info-line__item--animate":
                _vm.list.length > 1 && i === _vm.activeIndex,
            },
            { "info-line__item--no-animate": _vm.list.length === 1 },
          ],
          attrs: { href: item.href, target: "_blank" },
          on: {
            click: function ($event) {
              !item.href ? _vm.$emit("click", $event, item) : null
            },
          },
        },
        [
          _c("div", {
            staticClass: "info-line__text",
            domProps: { innerHTML: _vm._s(item.text) },
          }),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }