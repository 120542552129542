<template>
  <div class="menu-block">
    <slot name="link" />
  </div>
</template>

<script>
export default {
  name: "MenuBlock",
};
</script>
