// Значения поумолчанию если API админки недоступно

export const locations = [
  {
    image: require("./assets/images/location/convenience.png"),
    category: "Удобство",
    id: "сonvenience",
  },
  {
    image: require("./assets/images/location/relax.png"),
    category: "Гармония",
    id: "harmony",
  },
  {
    image: require("./assets/images/location/body.png"),
    category: "Развитие",
    id: "evolution",
  },
  {
    image: require("./assets/images/location/children.png"),
    category: "Семья",
    id: "family",
  },
];

export const crisis = [
  {
    minPay: "14,3",
    maxPay: "18,6",
    percent: "30",
  },
  {
    minPay: "16,9",
    maxPay: "22",
    percent: "20",
  },
  {
    minPay: "22,6",
    maxPay: "29,4",
    percent: "15",
  },
];

export const features = {
  business: {
    features: [
      {
        name: "Лаконичные стены",
        description:
          "Гипоалергенная акцентная штукатурка Oikos, Tilas или аналог",
      },
      {
        name: "Эстетичный пол",
        description: "Экологичная инженерная доска Finex, Coswick или аналог",
      },
      {
        name: "Элегантная ванная комната",
        description:
          "Долговечный керамогранит Imola Ceramica, Atlas Concorde или аналог",
      },
    ],
    images: [
      {
        image: require("./assets/images/gallery/business/1.jpeg"),
        name: "Концепция интерьера с отделкой Business",
        description: "Элегантность расставленных акцентов",
      },
      {
        image: require("./assets/images/gallery/business/2.jpeg"),
        name: "Концепция интерьера с отделкой Business",
        description: "Умиротворяющие оттенки стен",
      },
      {
        image: require("./assets/images/gallery/business/3.jpeg"),
        name: "Концепция интерьера с отделкой Business",
        description: "Стильная и долговечная сантехника",
      },
      {
        image: require("./assets/images/gallery/business/4.jpeg"),
        name: "Концепция интерьера с отделкой Business",
        description: "Идеальный фон для любого интерьера",
      },
      {
        image: require("./assets/images/gallery/business/5.jpeg"),
        name: "Концепция интерьера с отделкой Business",
        description: "Благородное сочетание природных текстур",
      },
      {
        image: require("./assets/images/gallery/business/6.jpeg"),
        name: "Концепция интерьера с отделкой Business",
        description: "Больше света, больше воздуха, больше пространства",
      },
      {
        image: require("./assets/images/gallery/business/7.jpeg"),
        name: "Концепция интерьера с отделкой Business",
        description: "Сдержанная элегантность цветовых сочетаний",
      },
      {
        image: require("./assets/images/gallery/business/8.jpeg"),
        name: "Концепция интерьера с отделкой Business",
        description: "Гармоничные и строгие линии",
      },
    ],
  },
  optima: {
    features: [
      {
        name: "Выразительные стены",
        description: "Краска премиальных брендов Delux, Caparol или аналог",
      },
      {
        name: "Качественный пол ",
        description: "Несодержащий фенол ламинат Kössen, Kronotex или аналог",
      },
      {
        name: "Стильная ванная комната",
        description:
          "Надежный керамогранит Kerama Marazzi, Cersanit или аналог",
      },
    ],
    images: [
      {
        image: require("./assets/images/gallery/optima/1.jpeg"),
        name: "OptimaКонцепция интерьера с отделкой Optima 1",
        description: "Комбинация «вечных и умных» цветов",
      },
      {
        image: require("./assets/images/gallery/optima/2.jpeg"),
        name: "Концепция интерьера с отделкой Optima",
        description: "Зрительно-увеличивающие пространство решения",
      },
      {
        image: require("./assets/images/gallery/optima/3.jpeg"),
        name: "Концепция интерьера с отделкой Optima",
        description: "Классические элементы декора",
      },
      {
        image: require("./assets/images/gallery/optima/4.jpeg"),
        name: "Концепция интерьера с отделкой Optima",
        description: "Лаконичность акцентов",
      },
      {
        image: require("./assets/images/gallery/optima/5.jpeg"),
        name: "Концепция интерьера с отделкой Optima",
        description: "Гармоничное сочетание текстур",
      },
      {
        image: require("./assets/images/gallery/optima/6.jpeg"),
        name: "Концепция интерьера с отделкой Optima",
        description: "Элегантный и долговечный керамогранит",
      },
      {
        image: require("./assets/images/gallery/optima/7.jpeg"),
        name: "Концепция интерьера с отделкой Optima",
        description: "Гармонично дополняющая любой дизайн фурнитура",
      },
      {
        image: require("./assets/images/gallery/optima/8.jpeg"),
        name: "Концепция интерьера с отделкой Optima",
        description: "Полотно стен, готовое к «полету» мысли дизайнера",
      },
    ],
  },
};

export const personsGallery = [
  {
    avatar: require("./assets/images/managers/aleksandr.png"),
    name: "Александр",
    position: "Эксперт проекта",
    message:
      "Приватное пространство двора, на котором расположатся различные площадки для детей и взрослых.  Собственный уголок для релакса, отдыха и занятия спортом прямо у подъезда.",
    category: "Архитектура",
  },
  {
    avatar: require("./assets/images/managers/dmitriy.png"),
    name: "Дмитрий",
    position: "Эксперт проекта",
    message:
      "Вместо привычного двора – уютная зеленая «гостиная» дома с лаунж-верандами, площадкой для воркаута и большой песочницей для будущих творцов.",
    category: "Благоустройство",
  },
  {
    avatar: require("./assets/images/managers/aleksandr.png"),
    name: "Александр",
    position: "Эксперт проекта",
    message:
      "Вместо привычных подъездов – залитое солнечным светом просторное лобби, располагающее к встрече гостей и теплой, неспешной беседе.",
    category: "Лобби",
  },
  // {
  //   avatar: require("./assets/images/managers/anton.png"),
  //   name: "Антон",
  //   position: "Эксперт проекта",
  //   message:
  //     "Мы позаботились об экономии Вашего времени на отделку. Выберите собственное «я» в вариантах Optima, Business или My Design.",
  //   category: "Интерьер",
  // },
];

export const imageGallery = [
  // Архитектура
  {
    image: {
      source: require("./assets/images/gallery/architecture/1.jpg"),
      mobile: require("./assets/images/gallery/architecture/1-m.jpg"),
    },
    category: "Архитектура",
  },
  {
    image: {
      source: require("./assets/images/gallery/architecture/2.jpg"),
      mobile: require("./assets/images/gallery/architecture/2-m.jpg"),
    },
    category: "Архитектура",
  },
  {
    image: {
      source: require("./assets/images/gallery/architecture/3.jpg"),
      mobile: require("./assets/images/gallery/architecture/3-m.jpg"),
    },
    category: "Архитектура",
  },
  {
    image: {
      source: require("./assets/images/gallery/architecture/4.jpg"),
      mobile: require("./assets/images/gallery/architecture/4-m.jpg"),
    },
    category: "Архитектура",
  },

  // Благоустройство

  {
    image: {
      source: require("./assets/images/gallery/landscape/1.jpg"),
      mobile: require("./assets/images/gallery/landscape/1-m.jpg"),
    },
    category: "Благоустройство",
  },
  {
    image: {
      source: require("./assets/images/gallery/landscape/2.jpg"),
      mobile: require("./assets/images/gallery/landscape/2-m.jpg"),
    },
    category: "Благоустройство",
  },
  {
    image: {
      source: require("./assets/images/gallery/landscape/3.jpg"),
      mobile: require("./assets/images/gallery/landscape/3-m.jpg"),
    },
    category: "Благоустройство",
  },
  {
    image: {
      source: require("./assets/images/gallery/landscape/4.jpg"),
      mobile: require("./assets/images/gallery/landscape/4.jpg"),
    },
    category: "Благоустройство",
  },
  {
    image: {
      source: require("./assets/images/gallery/landscape/5.jpg"),
      mobile: require("./assets/images/gallery/landscape/5-m.jpg"),
    },
    category: "Благоустройство",
  },
  {
    image: {
      source: require("./assets/images/gallery/landscape/6.jpg"),
      mobile: require("./assets/images/gallery/landscape/6-m.jpg"),
    },
    category: "Благоустройство",
  },
  {
    image: {
      source: require("./assets/images/gallery/landscape/8.jpg"),
      mobile: require("./assets/images/gallery/landscape/8-m.jpg"),
    },
    category: "Благоустройство",
  },
  {
    image: {
      source: require("./assets/images/gallery/landscape/9.jpg"),
      mobile: require("./assets/images/gallery/landscape/9-m.jpg"),
    },
    category: "Благоустройство",
  },
  {
    image: {
      source: require("./assets/images/gallery/landscape/10.jpg"),
      mobile: require("./assets/images/gallery/landscape/10-m.jpg"),
    },
    category: "Благоустройство",
  },

  // Лобби

  {
    image: {
      source: require("./assets/images/gallery/lobby/1.jpg"),
      mobile: require("./assets/images/gallery/lobby/1-m.jpg"),
    },
    category: "Лобби",
  },
  {
    image: {
      source: require("./assets/images/gallery/lobby/2.jpg"),
      mobile: require("./assets/images/gallery/lobby/2-m.jpg"),
    },
    category: "Лобби",
  },
  {
    image: {
      source: require("./assets/images/gallery/lobby/3.jpg"),
      mobile: require("./assets/images/gallery/lobby/3-m.jpg"),
    },
    category: "Лобби",
  },
  {
    image: {
      source: require("./assets/images/gallery/lobby/4.jpg"),
      mobile: require("./assets/images/gallery/lobby/4-m.jpg"),
    },
    category: "Лобби",
  },
  {
    image: {
      source: require("./assets/images/gallery/lobby/5.jpg"),
      mobile: require("./assets/images/gallery/lobby/5-m.jpg"),
    },
    category: "Лобби",
  },
  {
    image: {
      source: require("./assets/images/gallery/lobby/6.jpg"),
      mobile: require("./assets/images/gallery/lobby/6-m.jpg"),
    },
    category: "Лобби",
  },
  {
    image: {
      source: require("./assets/images/gallery/lobby/7.jpg"),
      mobile: require("./assets/images/gallery/lobby/7-m.jpg"),
    },
    category: "Лобби",
  },

  // Интерьер

  {
    image: {
      source: require("./assets/images/gallery/interior/1.jpg"),
      mobile: require("./assets/images/gallery/interior/1-m.jpg"),
    },
    category: "Интерьер",
  },
  {
    image: {
      source: require("./assets/images/gallery/interior/2.jpg"),
      mobile: require("./assets/images/gallery/interior/2-m.jpg"),
    },
    category: "Интерьер",
  },
  {
    image: {
      source: require("./assets/images/gallery/interior/3.jpg"),
      mobile: require("./assets/images/gallery/interior/3-m.jpg"),
    },
    category: "Интерьер",
  },
  {
    image: {
      source: require("./assets/images/gallery/interior/4.jpg"),
      mobile: require("./assets/images/gallery/interior/4-m.jpg"),
    },
    category: "Интерьер",
  },

  // Шоурум
  {
    image: "",
    video: require("@/assets/gallery_show-room.mp4"),
    category: "дизайнерская отделка",
  },
];

export const contactDocuments = [
  {
    name: "Отчет за последний квартал 2019 года. pdf",
    date: "15.01.2020 13:21",
    link: "https://yandex.ru/",
  },
  {
    name: "Отчет за последний квартал 2019 года. pdf",
    date: "15.01.2020 13:21",
    link: "https://yandex.ru/",
  },
];

export const conditions = [
  {
    number: "01",
    title: "Купить офлайн",
    description:
      "Позвоните нам по телефону, оставьте заявку на просмотр, заключите договор.",
  },
  {
    number: "02",
    title: "купить онлайн",
    description:
      "Выберите апартамент на сайте, оставьте заявку на покупку на странице",
    link: "Онлайн покупка",
  },
];

export const specials = [
  {
    title: "Выгодная цена на место в паркинге!",
    // image: require('./assets/images/banks/sberbank.png'),
    description:
      "С 15 февраля ожидаем повышение цен на машино-места в двухуровневом подземном паркинге. Успейте оформить!",
    to: "/",
  },
];

export const documents = [
  {
    name: "Изменения в ПД ( Экспертиза).pdf",
    date: "15.01.2020 13:21",
    link: "",
  },
  {
    name: "Изменения в ПД ( раздел 19.7- август 2020г).pdf.sig",
    date: "15.01.2020 13:21",
    link: "",
  },
  {
    name: "Изменения в ПД ( раздел 19.7- август 2020г).pdf",
    date: "15.01.2020 13:21",
    link: "",
  },
  {
    name: "РнС до 30.06.22г. Эксп..pdf.sig",
    date: "15.01.2020 13:21",
    link: "",
  },
  {
    name: "РнС до 30.06.22г. Эксп..pdf",
    date: "15.01.2020 13:21",
    link: "",
  },
  {
    name: "Заключение экспертизы 54-20 .pdf.sig",
    date: "15.01.2020 13:21",
    link: "",
  },
];

export const apartmentGallery = {
  design: [
    {
      images: [
        {
          srcset: require("@/assets/images/apartment/gallery/design/001.webp"),
        },
        {
          srcset: require("@/assets/images/apartment/gallery/design/001.jpg"),
        },
      ],
      thumb: require("@/assets/images/apartment/gallery/design/001.jpg"),
    },
    {
      images: [
        {
          srcset: require("@/assets/images/apartment/gallery/design/002.webp"),
        },
        {
          srcset: require("@/assets/images/apartment/gallery/design/002.jpg"),
        },
      ],
      thumb: require("@/assets/images/apartment/gallery/design/002.jpg"),
    },
    {
      images: [
        {
          srcset: require("@/assets/images/apartment/gallery/design/003.webp"),
        },
        {
          srcset: require("@/assets/images/apartment/gallery/design/003.jpg"),
        },
      ],
      thumb: require("@/assets/images/apartment/gallery/design/003.jpg"),
    },
    {
      images: [
        {
          srcset: require("@/assets/images/apartment/gallery/design/005.webp"),
        },
        {
          srcset: require("@/assets/images/apartment/gallery/design/005.jpg"),
        },
      ],
      thumb: require("@/assets/images/apartment/gallery/design/005.jpg"),
    },
    {
      images: [
        {
          srcset: require("@/assets/images/apartment/gallery/design/006.webp"),
        },
        {
          srcset: require("@/assets/images/apartment/gallery/design/006.jpg"),
        },
      ],
      thumb: require("@/assets/images/apartment/gallery/design/006.jpg"),
    },
  ],
  whitebox: [
    {
      images: [
        {
          srcset: require("@/assets/images/apartment/gallery/whitebox/001.webp"),
        },
        {
          srcset: require("@/assets/images/apartment/gallery/whitebox/001.jpg"),
        },
      ],
      thumb: require("@/assets/images/apartment/gallery/whitebox/001.jpg"),
    },
  ],
};

export const individualGallery = {
  "k1-aА1.21.248": [
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/1.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/1.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/1.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/2.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/2.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/2.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/3.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/3.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/3.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/4.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/4.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/4.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/5.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/5.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/5.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/6.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/6.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/6.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/7.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/7.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/7.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/8.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/8.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/8.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/9.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/9.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.248/9.jpg"),
    },
  ],
  "k1-aА1.21.239": [
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/1.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/1.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/1.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/2.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/2.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/2.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/3.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/3.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/3.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/4.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/4.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/4.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/5.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/5.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/5.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/6.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/6.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/6.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/7.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/7.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/7.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/8.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/8.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/8.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/9.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/9.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/9.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/10.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/10.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/10.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/11.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/11.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/11.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/12.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/12.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/12.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/13.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/13.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/13.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/14.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/14.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.21.239/14.jpg"),
    },
  ],
  "k1-aА1.08.083": [
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/1.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/1.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/1.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/2.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/2.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/2.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/3.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/3.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/3.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/4.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/4.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/4.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/5.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/5.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/5.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/6.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/6.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/6.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/7.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/7.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/7.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/8.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/8.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.08.083/8.jpg"),
    },
  ],
  "k1-aА1.07.075": [
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/8.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/8.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/8.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/1.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/1.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/1.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/2.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/2.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/2.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/3.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/3.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/3.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/4.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/4.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/4.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/5.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/5.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/5.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/6.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/6.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/6.jpg"),
    },
    {
      images: [
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/7.webp") },
        { media: "(min-width: 0)", type: 'image/webp', srcset:  require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/7.jpg") },
      ],
      thumb: require("@/assets/images/apartment/gallery/individual/k1-aА1.07.075/7.jpg"),
    },
  ],
};
