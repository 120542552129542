<template>
  <transition name="modal-fade" mode="out-in">
    <div v-if="isVisible" id="modal-dialog" class="modal-dialog">
      <div class="modal-dialog__overlay" @click="close" />
      <div class="modal-dialog__body modal-body">
        <div class="modal-body__close" tabindex="2" @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              opacity="0.5"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.29634 7.00005L0.00390625 13.2928L0.710995 13.9999L7.00343 7.70717L13.296 14.0001L14.0031 13.293L7.71052 7.00005L14.0031 0.707125L13.296 0L7.00343 6.29292L0.710996 0.000168525L0.00390625 0.707293L6.29634 7.00005Z"
              fill="white"
            />
          </svg>
        </div>
        <div
          :class="[
            'modal-body__title',
            { 'modal-body__title--small': isSmallTitle },
          ]"
        >
          <slot name="title" />
        </div>
        <div class="modal-body__content">
          <slot name="content" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isSmallTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isVisible: false,
  }),
  watch: {
    visible() {
      this.isVisible = this.visible;
    },
    isVisible(newVal) {
      if (newVal) {
        document.body.style = "overflow: hidden;";
      } else {
        document.body.style = "";
      }
    },
  },
  created() {
    this.isVisible = this.visible;
  },
  methods: {
    open() {
      this.isVisible = true;
      this.$emit("open");
    },
    close() {
      this.isVisible = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.modal-fade-enter {
  opacity: 0;
}

.modal-fade-leave-to {
  opacity: 0;
}
</style>

<style scoped lang="scss">
.modal-dialog {
  position: fixed;
  overflow: auto;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    opacity: 0.5;
    background: #000;
    z-index: 10001;
  }

  &__body,
  .modal-body {
    background: #000000;
    box-shadow: 11px 20px 41px rgba(0, 0, 0, 0.57);
    width: 650px;
    position: relative;
    top: 20%;
    margin: 0 auto 100px;
    color: #ffffff;
    padding: 60px;
    box-sizing: border-box;
    z-index: 10002;
    left: 0;
    right: 0;
    overflow: hidden;

    @media only screen and (max-width: $screen-sm) {
      width: 90%;
      box-sizing: border-box;
      margin: 0 auto 50px;
      padding: 30px;
      position: sticky;
    }

    &__close {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
      transition: all 0.3s ease-in-out;
      outline: none;
      background-color: transparent;

      &:hover {
        transform: scale(1.1);
      }

      &:focus {
        background: #232323;
        box-shadow: 0 0 20px 0 #ffffff8a;
      }
    }

    &__title {
      font-family: $font-family-bebas;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 105%;
      letter-spacing: 0.05em;
      margin-bottom: 25px;
      width: 90%;

      @media screen and (min-width: $screen-sm) {
        font-size: 36px;
      }

      &--small {
        line-height: 1.2;

        @media (min-width: $screen-sm) {
          font-size: 34px;
        }
      }
    }

    &__content {
    }
  }
}
</style>
