var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "169",
        height: "37",
        viewBox: "0 0 169 37",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M48.5885 0.115234H43.1152V20.9782H48.5885V0.115234Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.7489 20.9782H42.2221L34.7383 0.115234H34.6266H29.3768H29.1534L21.7812 20.9782H27.2545L28.1481 18.3271H35.7436L36.7489 20.9782ZM29.8235 13.7165L31.9458 7.60745L34.0681 13.7165H29.8235Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M65.7906 20.9782L68.6947 12.6791V0.115234H63.2215V10.8349L56.8547 0.115234H51.4932V0.806823V20.9782H56.8547V10.028L63.2215 20.8629V20.9782H65.7906Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M20.6642 0.115234H20.2174H15.191L10.2763 10.6043L5.47323 0.115234H5.36153H2.79247L0 8.06851V20.9782H5.36153V10.8349L8.154 16.8286H12.3985L15.191 10.7196V20.9782H20.6642V0.115234Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M116.502 14.0623C115.832 12.9096 115.273 12.1028 114.156 11.6417C117.284 11.4112 119.183 9.33642 119.183 5.99374C119.183 2.30527 116.949 0.115234 113.374 0.115234H104.773V21.0934H105.779C106.002 21.0934 106.114 20.9782 106.114 20.7476V11.9875H110.805C113.151 11.9875 114.268 12.7944 115.273 14.7539L118.401 21.2087H118.959H119.518H119.964L116.502 14.0623ZM113.598 10.4891H106.114V1.26788H113.263C116.39 1.26788 118.066 3.11212 117.842 6.4548C117.619 8.76009 115.832 10.4891 113.598 10.4891Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M73.9443 2.30536C74.9496 1.38324 76.5134 0.922179 78.4123 0.922179C81.6515 0.922179 83.4387 2.19009 84.6674 4.84118L84.8908 5.18698L85.2259 5.07171L85.7844 4.84118L86.1195 4.72592L86.0078 4.38012C84.5557 1.26797 82.3217 -0.230469 78.524 -0.230469C77.0719 -0.230469 75.7315 6.08712e-05 74.7262 0.46112L73.9443 2.30536Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M79.0822 9.79739C76.0663 9.4516 73.0505 8.99054 72.7154 5.99365L71.9335 8.18368C73.1622 10.3737 76.0663 10.8348 78.7471 11.1806C81.8747 11.5264 84.7788 11.9874 84.7788 15.4454C84.7788 18.5575 82.6566 20.056 78.5237 20.056C75.2845 20.056 73.2739 18.6728 72.0452 15.6759V15.3301L71.9335 15.4454L71.375 17.0591C72.8271 20.056 75.0611 21.4391 78.7471 21.4391C83.7735 21.4391 86.3426 19.2491 86.3426 15.4454C86.1192 10.8348 82.5449 10.1432 79.0822 9.79739Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M134.15 19.7103H124.432V10.9502H137.278L137.836 9.68224H124.432V1.26791H140.852L141.41 0H123.092V20.9782H133.703L134.15 19.7103Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M145.209 0L144.762 1.26791H161.07V20.9782H162.41V1.26791H169V0H145.209Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M87.5713 0V1.26791H87.9064H94.0498V19.3645V20.9782H95.0551H95.3902V20.6324C95.3902 20.6324 95.3902 20.6324 95.3902 20.5171V1.26791H101.534H101.869V0.922118V0H87.5713Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M106.114 19.5952H104.773V20.9784H106.114V19.5952Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M148.559 19.7104H137.948L137.501 20.9784H148.112L148.559 19.7104Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M152.134 9.68262H141.634L141.188 10.9505H151.687L152.134 9.68262Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.8769 36.7691H18.6535C18.4301 36.7691 18.4301 36.7691 18.3184 36.6538L17.4248 34.8096C17.2014 34.3485 16.8663 34.118 16.3078 34.118H15.5259V36.6538C15.5259 36.7691 15.5259 36.7691 15.4142 36.7691H15.1908C15.0791 36.7691 15.0791 36.7691 15.0791 36.6538V31.1211C15.0791 31.0059 15.0791 31.0059 15.1908 31.0059H15.4142C15.5259 31.0059 15.5259 31.0059 15.5259 31.1211V33.5417H16.3078H16.5312L18.3184 31.1211C18.4301 31.0059 18.4301 31.0059 18.6535 31.0059H18.9886C19.1003 31.0059 19.1003 31.1211 19.1003 31.1211L17.2014 33.657C17.6482 33.7722 17.9833 34.118 18.2067 34.5791L19.212 36.6538C18.9886 36.7691 18.9886 36.7691 18.8769 36.7691Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M25.2434 32.389C25.2434 33.0806 25.02 33.5417 24.4615 33.6569C25.02 33.7722 25.4668 34.3485 25.4668 35.1554C25.4668 36.1927 24.7966 36.7691 23.7914 36.7691H21.4457C21.334 36.7691 21.334 36.7691 21.334 36.6538V31.1211C21.334 31.0058 21.334 31.0058 21.4457 31.0058H23.6797C24.6849 30.8906 25.2434 31.4669 25.2434 32.389ZM23.6797 31.4669H21.8925V33.5417H23.6797C24.3498 33.5417 24.6849 33.1959 24.6849 32.5043C24.6849 31.8127 24.3498 31.4669 23.6797 31.4669ZM23.7914 34.118H21.8925V36.308H23.7914C24.4615 36.308 24.9083 35.9622 24.9083 35.2706C24.9083 34.4638 24.4615 34.118 23.7914 34.118Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.281 36.7691H32.0576C31.8342 36.7691 31.8342 36.7691 31.7225 36.6539L31.164 35.0402H28.7066L28.0364 36.6539C28.0364 36.7691 27.9247 36.7691 27.7013 36.7691H27.4779C27.3662 36.7691 27.3662 36.6539 27.3662 36.6539L29.4885 31.0059C29.4885 30.8906 29.6002 30.8906 29.7119 30.8906H29.9353C30.047 30.8906 30.1587 30.8906 30.1587 31.0059L32.281 36.7691C32.3926 36.7691 32.3926 36.7691 32.281 36.7691ZM29.9353 31.4669C29.9353 31.4669 29.9353 31.8127 29.8236 32.0433L28.8183 34.5791H30.9406L29.9353 32.0433C29.9353 31.8127 29.9353 31.4669 29.9353 31.4669Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M38.7591 32.7346C38.7591 33.8873 38.0889 34.5789 36.9719 34.5789H35.2964V36.6536C35.2964 36.7689 35.2964 36.7689 35.1847 36.7689H34.9613C34.8496 36.7689 34.8496 36.7689 34.8496 36.6536V31.1209C34.8496 31.0056 34.8496 31.0056 34.9613 31.0056H36.9719C38.0889 30.8904 38.7591 31.582 38.7591 32.7346ZM38.2006 32.7346C38.2006 31.9278 37.7538 31.4667 36.9719 31.4667H35.2964V34.0025H36.9719C37.6421 34.0025 38.2006 33.5415 38.2006 32.7346Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M44.9022 31.1215V31.352C44.9022 31.4673 44.9022 31.4673 44.7905 31.4673H43.2268V36.6542C43.2268 36.7695 43.2268 36.7695 43.1151 36.7695H42.8917C42.78 36.7695 42.78 36.7695 42.78 36.6542V31.4673H41.2162C41.1045 31.4673 41.1045 31.4673 41.1045 31.352V31.1215C41.1045 31.0062 41.1045 31.0062 41.2162 31.0062H45.0139C44.7905 30.891 44.9022 31.0062 44.9022 31.1215Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M50.8226 36.7691H50.5992C50.3758 36.7691 50.3758 36.7691 50.2641 36.6539L49.7056 35.0402H47.2482L46.6897 36.6539C46.6897 36.7691 46.578 36.7691 46.3546 36.7691H46.1312C46.0195 36.7691 46.0195 36.6539 46.0195 36.6539L48.1418 31.0059C48.1418 30.8906 48.2535 30.8906 48.3652 30.8906H48.5886C48.7003 30.8906 48.812 30.8906 48.812 31.0059L50.9343 36.6539C51.046 36.7691 50.9343 36.7691 50.8226 36.7691ZM48.4769 31.4669C48.4769 31.4669 48.4769 31.8127 48.3652 32.0433L47.3599 34.5791H49.4822L48.4769 32.0433C48.4769 31.8127 48.4769 31.4669 48.4769 31.4669Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M57.3013 31.1215V36.6542C57.3013 36.7695 57.3013 36.7695 57.1896 36.7695H56.9662C56.8545 36.7695 56.8545 36.7695 56.8545 36.6542V31.4673H54.5089V32.9657C54.5089 36.1932 53.8387 36.539 53.1685 36.8848C53.0568 36.8848 53.0568 36.8847 52.9451 36.7695L52.8334 36.539C52.7217 36.4237 52.8334 36.4237 52.9451 36.3084C53.5036 35.9626 53.9504 35.7321 53.9504 32.9657V31.1215C53.9504 31.0062 53.9504 31.0062 54.0621 31.0062H57.0779C57.1896 30.891 57.3013 31.0062 57.3013 31.1215Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M67.8008 31.1215V36.6542C67.8008 36.7695 67.8008 36.7695 67.6891 36.7695H67.4657C67.354 36.7695 67.354 36.7695 67.354 36.6542V34.1184H64.3382V36.6542C64.3382 36.7695 64.3382 36.7695 64.2265 36.7695H63.7797C63.668 36.7695 63.668 36.7695 63.668 36.6542V31.1215C63.668 31.0062 63.668 31.0062 63.7797 31.0062H64.0031C64.1148 31.0062 64.1148 31.0062 64.1148 31.1215V33.5421H67.1306V31.1215C67.1306 31.0062 67.1306 31.0062 67.2423 31.0062H67.4657C67.8008 30.891 67.8008 31.0062 67.8008 31.1215Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M74.9495 36.7691H74.7261C74.5027 36.7691 74.5027 36.7691 74.391 36.6539L73.8325 35.0402H71.3752L70.8167 36.6539C70.8167 36.7691 70.705 36.7691 70.4816 36.7691H70.2582C70.1465 36.7691 70.1465 36.6539 70.1465 36.6539L72.2688 31.0059C72.2688 30.8906 72.3805 30.8906 72.4922 30.8906H72.7155C72.8272 30.8906 72.939 30.8906 72.939 31.0059L74.9495 36.7691C75.0612 36.7691 75.0612 36.7691 74.9495 36.7691ZM72.6039 31.4669C72.6039 31.4669 72.6039 31.8127 72.4922 32.0433L71.4869 34.5791H73.6091L72.7155 32.0433C72.6039 31.8127 72.6039 31.4669 72.6039 31.4669Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M84.8905 36.7691H84.6671C84.4437 36.7691 84.4437 36.7691 84.332 36.6538L83.4384 34.8096C83.215 34.3485 82.88 34.118 82.3215 34.118H81.5396V36.6538C81.5396 36.7691 81.5396 36.7691 81.4279 36.7691H81.2045C81.0928 36.7691 81.0928 36.7691 81.0928 36.6538V31.1211C81.0928 31.0059 81.0928 31.0059 81.2045 31.0059H81.4279C81.5396 31.0059 81.5396 31.0059 81.5396 31.1211V33.5417H82.3215H82.5449L84.332 31.1211C84.4437 31.0059 84.4437 31.0059 84.6671 31.0059H85.0022C85.1139 31.0059 85.1139 31.1211 85.1139 31.1211L83.2151 33.657C83.6618 33.7722 83.9969 34.118 84.2203 34.5791L85.2256 36.6538C85.0022 36.7691 85.0022 36.7691 84.8905 36.7691Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M91.3687 31.1215L88.6879 36.6542C88.5762 36.7695 88.5762 36.7695 88.4645 36.7695H88.1295C88.0178 36.7695 88.0178 36.6542 88.0178 36.6542L88.7996 35.1558L86.7891 31.1215C86.7891 31.0062 86.7891 31.0062 86.9008 31.0062H87.1242C87.3476 31.0062 87.3476 31.0062 87.4593 31.1215L89.1347 34.4642L90.6985 31.1215C90.8102 31.0062 90.8102 31.0062 91.0336 31.0062H91.257C91.3687 30.891 91.3687 31.0062 91.3687 31.1215Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M97.2897 31.1214V31.3519C97.2897 31.4672 97.2897 31.4672 97.178 31.4672H95.6142V36.6541C95.6142 36.7694 95.6142 36.7694 95.5025 36.7694H95.0557C94.944 36.7694 94.944 36.7694 94.944 36.6541V31.4672H93.3803C93.2686 31.4672 93.2686 31.4672 93.2686 31.3519V31.1214C93.2686 31.0061 93.2686 31.0061 93.3803 31.0061L97.2897 31.1214C97.2897 30.8908 97.2897 31.0061 97.2897 31.1214Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M103.656 31.1215L100.975 36.6542C100.863 36.7695 100.863 36.7695 100.752 36.7695H100.417C100.305 36.7695 100.305 36.6542 100.305 36.6542L101.087 35.1558L99.0762 31.1215C99.0762 31.0062 99.0762 31.0062 99.1879 31.0062H99.4113C99.6347 31.0062 99.6347 31.0062 99.7464 31.1215L101.422 34.4642L102.986 31.1215C103.097 31.0062 103.097 31.0062 103.321 31.0062H103.544C103.656 30.891 103.656 31.0062 103.656 31.1215Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M108.906 33.7723C109.576 34.0028 109.799 34.5791 109.799 35.1555C109.799 36.1928 109.129 36.8844 107.789 36.8844C106.448 36.8844 105.778 36.3081 105.555 35.5012C105.555 35.386 105.555 35.2707 105.666 35.2707L105.89 35.1555C106.001 35.1555 106.001 35.1555 106.113 35.2707C106.337 35.9623 106.783 36.3081 107.789 36.3081C108.794 36.3081 109.241 35.847 109.241 35.1555C109.241 34.4639 108.906 34.0028 108.235 34.0028H106.895C106.783 34.0028 106.783 34.0028 106.783 33.8875V33.657C106.783 33.5417 106.783 33.5417 106.895 33.5417H108.235C108.906 33.5417 109.241 33.0807 109.241 32.5044C109.241 31.8128 108.794 31.3517 107.9 31.3517C106.895 31.3517 106.448 31.6975 106.225 32.3891C106.225 32.5044 106.113 32.5044 106.001 32.5044L105.778 32.3891C105.666 32.3891 105.666 32.2738 105.666 32.1586C106.001 31.2364 106.56 30.7754 107.9 30.7754C109.129 30.7754 109.799 31.3517 109.799 32.3891C109.688 33.0807 109.464 33.5417 108.906 33.7723Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M114.491 30.7754C115.943 30.7754 116.725 31.5822 116.725 32.8502V34.8097C116.725 36.0776 115.943 36.8844 114.491 36.8844C113.039 36.8844 112.257 36.0776 112.257 34.8097V32.8502C112.257 31.5822 113.039 30.7754 114.491 30.7754ZM114.491 31.3517C113.486 31.3517 112.815 31.928 112.815 32.8502V34.8097C112.815 35.7318 113.374 36.3081 114.491 36.3081C115.496 36.3081 116.055 35.7318 116.055 34.8097V32.8502C116.055 31.928 115.496 31.3517 114.491 31.3517Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M123.315 32.389C123.315 33.0806 123.091 33.5417 122.533 33.6569C123.091 33.7722 123.538 34.3485 123.538 35.1554C123.538 36.1927 122.868 36.7691 121.863 36.7691H119.517C119.405 36.7691 119.405 36.7691 119.405 36.6538V31.1211C119.405 31.0058 119.405 31.0058 119.517 31.0058H121.751C122.756 30.8906 123.315 31.4669 123.315 32.389ZM121.639 31.4669H119.852V33.5417H121.639C122.309 33.5417 122.645 33.1959 122.645 32.5043C122.645 31.8127 122.309 31.4669 121.639 31.4669ZM121.751 34.118H119.852V36.308H121.751C122.421 36.308 122.868 35.9622 122.868 35.2706C122.868 34.4638 122.533 34.118 121.751 34.118Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M128.118 30.7754C129.346 30.7754 130.017 31.2364 130.24 32.1586C130.24 32.2738 130.24 32.3891 130.128 32.3891L129.905 32.5044C129.793 32.5044 129.793 32.5044 129.682 32.3891C129.458 31.6975 129.011 31.3517 128.118 31.3517C127.112 31.3517 126.554 31.928 126.554 32.8502V34.8097C126.554 35.7318 127.112 36.3081 128.118 36.3081C129.011 36.3081 129.458 35.9623 129.682 35.2707C129.682 35.1555 129.793 35.1555 129.905 35.1555L130.128 35.2707C130.24 35.2707 130.24 35.386 130.24 35.5012C129.905 36.4234 129.346 36.9997 128.118 36.9997C126.666 36.9997 125.884 36.1928 125.884 34.9249V32.9654C125.884 31.5822 126.666 30.7754 128.118 30.7754Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M136.719 36.7691H136.495C136.272 36.7691 136.272 36.7691 136.16 36.6538L135.267 34.8096C135.043 34.3485 134.708 34.118 134.15 34.118H133.368V36.6538C133.368 36.7691 133.368 36.7691 133.256 36.7691H133.033C132.921 36.7691 132.921 36.7691 132.921 36.6538V31.1211C132.921 31.0059 132.921 31.0059 133.033 31.0059H133.256C133.368 31.0059 133.368 31.0059 133.368 31.1211V33.5417H134.15H134.373L136.16 31.1211C136.272 31.0059 136.272 31.0059 136.495 31.0059H136.83C136.942 31.0059 136.942 31.1211 136.942 31.1211L135.043 33.657C135.49 33.7722 135.825 34.118 136.048 34.5791L137.054 36.6538C136.83 36.7691 136.83 36.7691 136.719 36.7691Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M141.187 30.7754C142.639 30.7754 143.421 31.5822 143.421 32.8502V34.8097C143.421 36.0776 142.639 36.8844 141.187 36.8844C139.735 36.8844 138.953 36.0776 138.953 34.8097V32.8502C138.953 31.5822 139.735 30.7754 141.187 30.7754ZM141.187 31.3517C140.182 31.3517 139.512 31.928 139.512 32.8502V34.8097C139.512 35.7318 140.07 36.3081 141.187 36.3081C142.192 36.3081 142.751 35.7318 142.751 34.8097V32.8502C142.751 31.928 142.192 31.3517 141.187 31.3517Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M151.128 31.1214V36.6541C151.128 36.7694 151.128 36.7694 151.016 36.7694H150.793C150.681 36.7694 150.681 36.7694 150.681 36.6541V33.3114C150.681 32.8503 150.681 32.3893 150.793 31.813C150.569 32.274 150.346 32.7351 150.122 33.0809L148.894 35.2709C148.894 35.3862 148.782 35.3862 148.67 35.3862H148.559C148.447 35.3862 148.447 35.3862 148.335 35.2709L147.106 33.0809C146.883 32.6198 146.66 32.274 146.548 31.813C146.66 32.3893 146.66 32.8503 146.66 33.3114V36.6541C146.66 36.7694 146.66 36.7694 146.548 36.7694H146.325C146.213 36.7694 146.213 36.7694 146.213 36.6541V31.1214C146.213 31.0061 146.213 31.0061 146.325 31.0061H146.548C146.66 31.0061 146.771 31.0061 146.771 31.1214L148.782 34.6946L150.793 31.1214C150.904 31.0061 150.904 31.0061 151.016 31.0061L151.128 31.1214C151.128 30.8908 151.128 31.0061 151.128 31.1214Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }