var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "parent",
      staticClass: "vue-input-ui",
      class: [
        {
          "is-focused": _vm.isFocus,
          "is-valid": _vm.valid,
          "has-value": _vm.value,
          "has-error": _vm.error,
          "is-disabled": _vm.disabled,
          "is-dark": _vm.dark,
          "has-hint": _vm.hint,
        },
        _vm.size,
      ],
      on: { click: _vm.focusInput },
    },
    [
      _vm.type === "checkbox"
        ? _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                ref: "VueInputUi",
                class: {
                  "vue-input-ui__input": true,
                  "vue-input-ui__input--placheholder-white":
                    _vm.placeholderWhite,
                },
                attrs: {
                  id: _vm.id,
                  placeholder: _vm.placeholderValue,
                  disabled: _vm.disabled,
                  required: _vm.required,
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(_vm.inputValue)
                    ? _vm._i(_vm.inputValue, null) > -1
                    : _vm.inputValue,
                },
                on: {
                  keydown: _vm.keyDown,
                  keyup: _vm.keyUp,
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  click: function ($event) {
                    return _vm.$emit("click")
                  },
                  change: function ($event) {
                    var $$a = _vm.inputValue,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.inputValue = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.inputValue = $$c
                    }
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          )
        : _vm.type === "radio"
        ? _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                ref: "VueInputUi",
                class: {
                  "vue-input-ui__input": true,
                  "vue-input-ui__input--placheholder-white":
                    _vm.placeholderWhite,
                },
                attrs: {
                  id: _vm.id,
                  placeholder: _vm.placeholderValue,
                  disabled: _vm.disabled,
                  required: _vm.required,
                  type: "radio",
                },
                domProps: { checked: _vm._q(_vm.inputValue, null) },
                on: {
                  keydown: _vm.keyDown,
                  keyup: _vm.keyUp,
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  click: function ($event) {
                    return _vm.$emit("click")
                  },
                  change: function ($event) {
                    _vm.inputValue = null
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          )
        : _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue",
                  },
                ],
                ref: "VueInputUi",
                class: {
                  "vue-input-ui__input": true,
                  "vue-input-ui__input--placheholder-white":
                    _vm.placeholderWhite,
                },
                attrs: {
                  id: _vm.id,
                  placeholder: _vm.placeholderValue,
                  disabled: _vm.disabled,
                  required: _vm.required,
                  type: _vm.type,
                },
                domProps: { value: _vm.inputValue },
                on: {
                  keydown: _vm.keyDown,
                  keyup: _vm.keyUp,
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  click: function ($event) {
                    return _vm.$emit("click")
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.inputValue = $event.target.value
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          ),
      _c(
        "label",
        {
          ref: "label",
          staticClass: "vue-input-ui__label",
          class: _vm.error ? "text-danger" : null,
          attrs: { for: _vm.id },
          on: { click: _vm.focusInput },
        },
        [
          _vm._t("label", function () {
            return [_vm._v(" " + _vm._s(_vm.hintValue || _vm.labelValue) + " ")]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }