import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayouts from "../layouts/default";
import PageLayouts from "../layouts/page";
import prototypes from "../utils/prototypes";

Vue.use(VueRouter);

const TITLE = process.env.VUE_APP_TITLE;
const POSTFIX = ` | ${process.env.VUE_APP_TITLE_POSTFIX}`;
const BASE_URL = process.env.VUE_APP_BASE_URL;

const routes = [
  {
    path: "/",
    component: DefaultLayouts,
    pathToRegexpOptions: { strict: true },
    children: [
      {
        path: "",
        name: "MainPage",
        component: () => import("../views/MainPage"),
      },
    ],
  },
  {
    path: "",
    component: PageLayouts,
    pathToRegexpOptions: { strict: true },
    meta: {
      title: "Страница" + POSTFIX,
    },
    children: [
      {
        path: "decoration",
        redirect: "decoration/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "decoration/",
        name: "ApartmentDecorationPage",
        component: () => import("../views/ApartmentDecorationPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Отделка" + POSTFIX,
          breadcrumb: "Отделка",
        },
      },
      {
        path: "about",
        redirect: "about/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "about/",
        name: "AboutPage",
        component: () => import("../views/AboutPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "О проекте" + POSTFIX,
          description:
            "ЖК MAINSTREET создан для тех, кто ценит свободу перемещений, комфортное жилье и возможность жить гармоничной жизнью",
          breadcrumb: "О проекте",
        },
      },
      {
        path: "apartments/whitebox",
        redirect: "apartments/whitebox/",
        pathToRegexpOptions: { strict: true },
      },
      {
        name: "WhiteBox",
        path: "apartments/whitebox/",
        component: () => import("../views/FilterPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Выбор апартаментов" + POSTFIX,
          description:
            "Узнайте о доступных предложениях в концептуальном квартале MAINSTREET",
          ogImagePath: "assets/images/gallery/architecture/1.jpeg",
          breadcrumb: "Подбор по параметрам",
          keepGetParams: true,
          defaultFilter: {
            with_decoration: true,
            quantity: "3",
          },
        },
      },
      {
        path: "apartments",
        redirect: "apartments/",
        pathToRegexpOptions: { strict: true },
      },
      {
        name: "FilterPage",
        path: "apartments/",
        component: () => import("../views/FilterPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Выбор апартаментов" + POSTFIX,
          description:
            "Узнайте о доступных предложениях в концептуальном квартале MAINSTREET",
          ogImagePath: "assets/images/gallery/architecture/1.jpeg",
          breadcrumb: "Подбор по параметрам",
        },
      },
      {
        path: "/apartments/korp:korp/sec:sec/floor:floor/fnumb:fnumb/numb:numb/quantity:quantity/id:id",
        redirect:
          "/apartments/korp:korp/sec:sec/floor:floor/fnumb:fnumb/numb:numb/quantity:quantity/id:id/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "/apartments/korp:korp/sec:sec/floor:floor/fnumb:fnumb/numb:numb/quantity:quantity/id:id/",
        name: "ApartmentPage",
        component: () => import("../views/ApartmentPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Купить апартамент" + POSTFIX,
          description:
            "Выберите понравившуюся вам планировку в концептуальном квартале MAINSTREET",
          ogImagePath: "assets/images/gallery/architecture/1.jpeg",
          breadcrumb: "",
          breadcrumbs: [
            {
              name: "FilterPage",
              title: "Подбор по параметрам",
            },
          ],
        },
      },
      {
        path: "holidays",
        redirect: "holidays/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "holidays/",
        name: "HolidaysPage",
        component: () => import("../views/HolidaysPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "КАНИКУЛЫ НА ТВОЕЙ УЛИЦЕ" + POSTFIX,
          description:
            "Выберите понравившуюся вам планировку в концептуальном квартале MAINSTREET",
          breadcrumb: "Каникулы",
        },
      },
      {
        path: "contacts",
        redirect: "contacts/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "contacts/",
        name: "ContactPage",
        component: () => import("../views/ContactPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Контакты" + POSTFIX,
          description:
            "Получить уникальное предложение и консультацию можно в офисе продаж ЖК MAINSTREET",
          breadcrumb: "Контакты",
        },
      },
      {
        path: "form-callback/",
        name: "ContactPage",
        component: () => import("../views/FormCallbackPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Обратная связь " + POSTFIX,
          description:
            "Получить уникальное предложение и консультацию можно в офисе продаж ЖК MAINSTREET",
          breadcrumb: "Обратная связь",
        },
      },
      {
        path: "documents",
        redirect: "documents/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "documents/",
        name: "DocumentsPage",
        component: () => import("../views/DocumentsPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Список документов" + POSTFIX,
          description: "Документация проекта MAINSTREET от ГК «Основа»",
          breadcrumb: "Список документов",
        },
      },
      {
        path: "online-broadcast",
        redirect: "online-broadcast/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "online-broadcast/",
        name: "OnlineBroadcastPage",
        component: () => import("../views/OnlineBroadcastPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Онлайн-трансляция" + POSTFIX,
          description:
            "Следите за ходом строительства концептуального квартала MAINSTREET",
          breadcrumb: "Онлайн-трансляция",
        },
      },
      {
        path: "panorama",
        redirect: "panorama/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "panorama/",
        name: "PanoramaPage",
        component: () => import("../views/PanoramaPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Панорама" + POSTFIX,
          description:
            "Оцените местоположение концептуального квартала MAINSTREET с высоты птичьего полета",
          breadcrumb: "Панорама",
        },
      },
      {
        path: "location",
        redirect: "location/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "location/",
        name: "LocationPage",
        component: () => import("../views/LocationPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Расположение" + POSTFIX,
          description:
            "Оцените преимущества места, где соединяются Кутузовский проспект и Рублевское шоссе",
          breadcrumb: "Расположение",
        },
      },
      {
        path: "condition",
        redirect: "condition/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "condition/",
        name: "ConditionPage",
        component: () => import("../views/ConditionPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Способы покупки" + POSTFIX,
          description:
            "Выгодные варианты покупки и предложения от банков в концептуальном квартале MAINSTREET",
          ogImagePath: "assets/images/gallery/architecture/1.jpeg",
          breadcrumb: "Способы покупки",
        },
      },
      {
        name: "TradeInSpecialPage",
        path: "trade-in-special",
        redirect: "trade-in-special/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "trade-in-special/",
        name: "TradeInSpecialPage",
        component: () => import("../views/TradeInSpecialPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Условия покупки" + POSTFIX,
          breadcrumb: "Условия покупки",
        },
      },
      {
        path: "buyonline",
        redirect: "buyonline/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "buyonline/",
        name: "OnlineShopping",
        component: () => import("../views/OnlineShoppingPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Онлайн-покупка" + POSTFIX,
          description:
            "Заключите договор на покупку апартаментов в ЖК MAINSTREET не выходя из дома",
          ogImagePath: "assets/images/gallery/architecture/1.jpeg",
          breadcrumb: "Приобретите апартаменты, не выходя из дома!",
        },
      },
      {
        path: "parking",
        redirect: "parking/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "parking/",
        name: "ParkingPage",
        component: () => import("../views/ParkingPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Паркинг" + POSTFIX,
          description:
            "Выберите машиноместо в концептуальном квартале MAINSTREET",
          ogImagePath: "assets/images/og/parking.jpg",
          breadcrumb: "Паркинг",
        },
      },
      {
        path: "news",
        redirect: "news/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "news/",
        name: "NewsCatalogPage",
        component: () => import("../views/NewsCatalogPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Новости" + POSTFIX,
          description:
            "Узнайте последние новости о ходе строительства концептуального квартала MAINSTREET",
          ogImagePath: "assets/images/gallery/architecture/1.jpeg",
          breadcrumb: "Новости",
        },
      },
      {
        path: "news/:slug",
        redirect: "news/:slug/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "news/:slug/",
        name: "InnerNewsPage",
        component: () => import("../views/InnerNewsPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Новости" + POSTFIX,
          description:
            "Узнайте последние новости о ходе строительства концептуального квартала MAINSTREET",
          ogImagePath: "assets/images/gallery/architecture/1.jpeg",
          breadcrumb: "",
          breadcrumbs: [
            {
              name: "NewsCatalogPage",
              title: "Новости",
            },
          ],
        },
      },
      {
        path: "step",
        redirect: "step/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "step/",
        name: "ProgressPage",
        component: () => import("../views/ProgressPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Ход строительства" + POSTFIX,
          description:
            "Следите за ходом строительства концептуального квартала MAINSTREET",
          ogImagePath: "assets/images/og/step.jpg",
          breadcrumb: "Ход строительства",
        },
      },
      {
        path: "step-building/:slug",
        redirect: "step-building/:slug/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "step-building/:slug/",
        name: "InnerStepPage",
        component: () => import("../views/InnerNewsPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Ход строительства" + POSTFIX,
          description:
            "Следите за ходом строительства концептуального квартала MAINSTREET",
          ogImagePath: "assets/images/og/step.jpg",
          breadcrumb: "",
          breadcrumbs: [
            {
              name: "ProgressPage",
              title: "Ход строительства",
            },
          ],
        },
      },
      {
        path: "stocks",
        redirect: "stocks/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "stocks/",
        name: "StocksPage",
        component: () => import("../views/StocksPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Акции" + POSTFIX,
          breadcrumb: "Акции",
        },
      },
      {
        path: "agreement",
        redirect: "agreement/",
        pathToRegexpOptions: { strict: true },
      },
      {
        path: "agreement/",
        name: "UserAgreementPage",
        component: () => import("../views/UserAgreementPage"),
        pathToRegexpOptions: { strict: true },
        meta: {
          title: "Пользовательское соглашение" + POSTFIX,
          description:
            "Пользовательское соглашение официального сайта ЖК MAINSTREET от ГК «Основа»",
          breadcrumb: "Пользовательское соглашение",
        },
      },
      {
        path: "404",
        name: "404",
        component: () => import("../views/PageNotFound"),
        meta: {
          title: "404" + POSTFIX,
        },
      },
      {
        path: "*",
        redirect: "/404",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash && to.name === from.name) {
      return { x: 0, y: 0 };
    } else if (to.hash && to.name === from.name) {
      return { selector: to.hash };
    } else if (to.hash && to.name !== from.name) {
      return new Promise((resolve) => {
        // setTimeout(() => {
        //   resolve({ selector: to.hash })
        // }, 900)
        resolve();
      });
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (savedPosition) {
            resolve(savedPosition);
          } else {
            resolve({ x: 0, y: 0 });
          }
        }, 900);
      });
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  prototypes.$setMeta({
    title: to.meta.title || TITLE,
    description: to.meta.description || process.env.VUE_APP_DESCRIPTION,
    image: to.meta.ogImagePath
      ? BASE_URL + to.meta.ogImagePath
      : BASE_URL + process.env.VUE_APP_OG_IMAGE_PATH,
    url: BASE_URL + to.path.slice(1),
  });
  next();
});

export default router;
