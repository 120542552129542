/* eslint-disable prefer-promise-reject-errors */
import Vue from "vue";
import Vuex, { Store } from "vuex";
import {
  contactDocuments,
  features,
  imageGallery,
  personsGallery,
  locations,
  crisis,
  conditions,
  specials,
} from "@/content";

Vue.use(Vuex);

export default new Store({
  state: {
    storage: {
      // Карта
      addr_list: [],

      // Ход строительства
      dev_engineering_networks: 0,
      dev_laying_of_walls: 0,
      dev_monolithic_works: 0,
      dev_parking_lot_construction: 0,

      // Теги в шапке
      header_tags: [],

      // Рассрочка
      pay_parking: [],
      pay_parking_show: false,
      pay_programs: [],

      // Презентация
      pdf_presentation: "",
      banner_description: "",

      // Ипотека
      min_mortgage_percent: 0,
      mortgage_promo_card: "",
      mortgage_description: "",
      project_banks: [],

      building_progress: [],

      // Others
      apartment_manager: "",
      layouts: [],
      promo: {
        banners: [],
      },
      building_broadcast: null,
    },
    alternativePlans: [],
    ipoteka: [],
    purchaseOptions: [],
    payPrograms: [],
    minPercent: "",
    apartments: [],
    apartmentsLoaded: false,
    menuIsOpened: false,
    isFixed: false,
    parking: null,
    parkingLoaded: false,
    building: [],
    buildingPercent: [],
    monolithicWorks: 0,
    layingOfWalls: 0,
    parkingLotConstruction: 0,
    engineeringNetworks: 0,
    map: [],

    documents: [],
    specials: specials,
    locations: locations,
    crisis: crisis,
    features: features,
    conditions: conditions,
    imageGallery: imageGallery,
    personsGallery: personsGallery,
    contactDocuments: contactDocuments,
    newApartmentsDecor: false,
    // ToDo - news ?
    news: [],
    modalSuccess: false,
    breadcrumb: "",
    mascotFlag: false,
    dateMortgage: new Date(2022, 12, 30, 23, 59),
    streetCompass: {
      default: {
        north: "",
        south: "ул. Ивана Франко",
        west: "Рублевское Шоссе",
        east: "",
        compass: -30,
      },
    },
    workTime: `<p><span>9.05 - выходной</span><br>07-08.05 - с 10.00 до 20.00<br>10.05 - с 10.00 до 20.00</p><p>Пн-Пт с 09:00 до 21:00 <br> Сб-Вс с 10:00 до 20:00</p>`,
  },
  getters: {
    storage: (state) => state.storage,
    apartments: (state) => state.apartments,
    menuIsOpened: (state) => state.menuIsOpened,
    isFixed: (state) => state.isFixed,
    parking: (state) => state.parking,
    parkingLoaded: (state) => state.parkingLoaded,
    building: (state) => state.building,
    alternativePlans: (state) => state.alternativePlans,
    // monolithicWorks: state => state.monolithicWorks,
    // layingOfWalls: state => state.layingOfWalls,
    // parkingLotConstruction: state => state.parkingLotConstruction,
    // engineeringNetworks: state => state.engineeringNetworks,
    ipoteka: (state) => state.ipoteka,
    purchaseOptions: (state) => state.purchaseOptions,
    payPrograms: (state) => state.payPrograms,
    buildingProgress: (state) => state.buildingProgress,
    minPercent: (state) => state.minPercent,
    documents: (state) => state.documents,
    news: (state) => state.news,
    map: (state) => state.map,
    dateMortgage: (state) => state.dateMortgage,

    GET_MORTGAGE_PROPS: (state) => {
      return {
        apartment: {
          initialPayment: 27,
          minPayment: 27,
          calcBet: 9.99
        },
        flat: {
          initialPayment: 20.1,
          minPayment: 20.1,
          calcBet: 5.8
        },
      }
    },

    newApartmentsDecor: (state) => state.newApartmentsDecor,
    modalSuccess: (state) => state.modalSuccess,
    breadcrumb: (state) => state.breadcrumb,
    layouts: (state) => {
      return state.alternativePlans.reduce((r, a) => {
        if (typeof a.name === "string") {
          r[a.name] = [...(r[a.name] || []), a];
          return r;
        }
        if (typeof a.name === "object") {
          for (const name of a.name) {
            r[name] = [...(r[name] || []), a];
          }
          return r;
        }
      }, {});
    },
    getStreetCompass: (state) => state.streetCompass,
  },
  mutations: {
    SET_STORAGE: (state, payload) => {
      state.storage = payload;
    },
    SET_MENU_OPENED: (state, payload) => {
      state.menuIsOpened =
        payload === undefined ? !state.menuIsOpened : payload;
    },
    SET_FIXED: (state, payload) => {
      state.isFixed = payload;
    },
    SET_PARKING: (state, payload) => {
      state.parking = payload;
      state.parkingLoaded = true;
    },
    SET_APARTMENTS: (state, payload) => {
      state.apartments = payload;
      state.apartmentsLoaded = true;
    },
    SET_NEW_APARTMENTS_DECOR: (state, payload) => {
      state.newApartmentsDecor = payload;
    },
    SET_BUILDING: (state, payload) => {
      state.building = payload;
    },
    SET_ALTERNATIVE: (state, payload) => {
      state.alternativePlans = payload;
    },
    SET_BUILDING_PERCENT: (state, payload) => {
      state.buildingPercent = payload;
    },
    SET_IPOTEKA: (state, payload) => {
      state.ipoteka = payload;
    },
    SET_PURCHASE_OPTIONS: (state, payload) => {
      state.purchaseOptions = payload;
    },
    SET_PAY_PROGRAMS: (state, payload) => {
      state.payPrograms = payload;
    },
    SET_MIN_PERCENT_MORTGAGE: (state, payload) => {
      state.minPercent = payload;
    },
    SET_DOCUMENTS: (state, payload) => {
      state.documents = payload;
    },
    SET_NEWS: (state, payload) => {
      state.news = payload;
    },
    SET_MAP: (state, payload) => {
      state.map = payload;
    },
    SET_MODAL_SUCCESS: (state, payload) => {
      state.modalSuccess =
        payload === undefined ? !state.modalSuccess : payload;
    },
    SET_BREADCRUMB: (state, payload) => {
      state.breadcrumb = payload;
    },
    SET_MASCOT_FLAG: (state, payload) => {
      state.mascotFlag = payload;
    },
    SET_WORK_TIME: (state, payload) => {
      state.workTime = payload;
    },
  },
  actions: {
    FETCH_STORAGE: function ({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .get(`api/v1/storage/${process.env.VUE_APP_PROJECT_ID}`)
          .then(({ data }) => {
            var storage = data.data;
            commit("SET_STORAGE", storage);
            commit(
              "SET_MIN_PERCENT_MORTGAGE",
              storage.mortgage.min_mortgage_percent
            );
            commit("SET_IPOTEKA", storage.mortgage.project_banks);
            commit("SET_PURCHASE_OPTIONS", storage.mortgage.purchase_options);
            commit("SET_PAY_PROGRAMS", storage.mortgage.pay_programs);
            commit("SET_MAP", storage.addresses.addr_list);
            commit(
              "SET_BUILDING_PERCENT",
              storage.building_progress.building_progress
            );
            commit("SET_ALTERNATIVE", storage.alternative_layouts.layouts);
            commit(
              "SET_WORK_TIME",
              storage.addresses.addr_list[1].addr_work_time
            );
            resolve(data.data);
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            // commit('SET_STORAGE_LOADING', true)
          });
      });
    },
    FETCH_BUILDING_PROGRESS: function ({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .get("api/v1/publications/", {
            params: {
              project: process.env.VUE_APP_PROJECT_ID,
              category: process.env.VUE_APP_BUILDINGS_ID,
              language: "ru",
            },
          })
          .then(({ data }) => {
            commit("SET_BUILDING", data.data);
            resolve(data.data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    FETCH_APARTMENTS: function ({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .get(
            process.env.VUE_APP_APARTMENTS_API + "4/properties?type=apartment&detail=1"
          )
          .then(({ data }) => {
            const adapterApartList = [];
            const apartList = data.data;
            const associativeValues = {
              S: "Студия",
              E1: "Евро-1",
              E2: "Евро-2",
              E3: "Евро-3",
              E4: "Евро-4",
              E5: "Евро-5",
            };
            apartList.forEach((item, i) => {
              const apart = {};
              apart.id = item.uuid;
              apart.korpus = item.building;
              apart.section = item.section;
              apart.floor = item.floor.number;
              apart.number_on_floor = item.number_on_floor;
              apart.number = item.number;
              if (associativeValues[item.layout.room_count] === undefined) {
                apart.quantity = item.layout.room_count;
              } else {
                apart.quantity = associativeValues[item.layout.room_count];
              }
              apart.area = item.layout.area;
              apart.amount = item.cost;
              apart.discount = item.discount;
              apart.discount_cost = item.discount_cost;
              apart.status = item.status;
              apart.plan_type = item.properties.plan_type;
              apart.layout = item.layout;
              apart.layouttype = item.layout.code !== "";
              apart.floor_image = item.floor.plan_image.source;
              apart.plan_image = item.layout.plan_image.source;
              apart.properties = item.properties;
              apart.area_points = item.area_points;
              adapterApartList.push(apart);
            });
            this.adapterApartList = adapterApartList;
            commit("SET_APARTMENTS", adapterApartList);
            resolve(adapterApartList);
          })
          .catch(() => {
            reject();
          });
      });
    },
    FETCH_PARKING: function ({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .get(process.env.VUE_APP_PARKING_API)
          .then(({ data }) => {
            const parkingFloors = {};
            const floors = {};
            data.data.forEach((item, i) => {
              if (!floors[item.floor.number]) {
                floors[item.floor.number] = true;
                this._vm.$api
                  .get(
                    "api/v1/building-objects/4/floor/1/1/" +
                      item.floor.number +
                      "?fullList=1"
                  )
                  .then(({ data }) => {
                    const out = {};
                    parkingFloors[item.floor.number] = data.data;
                    Object.assign(out, parkingFloors);
                    if (Object.keys(floors).length === Object.keys(out).length)
                      commit("SET_PARKING", out);
                  });
              }
            });
            resolve(data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    FETCH_DOCUMENTS: function ({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .get("api/v1/publication/slug/spisok-dokumentov-zhk-mainstreet")
          .then(({ data }) => {
            const stringToDate = (dateString) => {
              const strippedDateString = dateString.replace(/<[^>]*>?/gm, "");

              return new Date([
                strippedDateString.slice(0, 10).split(".").reverse(),
                strippedDateString.substring(11),
              ]);
            };

            const blocks = data.data.content[0].body.blocks;

            commit(
              "SET_DOCUMENTS",
              blocks.sort(
                (a, b) => stringToDate(b.data.date) - stringToDate(a.data.date)
              )
            );
            resolve(blocks);
          })
          .catch(() => reject());
      });
    },
  },
  modules: {},
});
