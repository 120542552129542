var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_c("keep-alive", [_c("router-view")], 1)],
        1
      ),
      _c("success-modal-dialog", { ref: "modal-success" }),
      _c("apartment-modal-dialog", { ref: "modal-apartment" }),
      _c("banner-modal-dialog", { ref: "modal-banner" }),
      _c("subscribe-modal-dialog", { ref: "modal-subscribe" }),
      _c("parking-modal-dialog", { ref: "modal-parking" }),
      _c("presentation-modal-dialog", { ref: "modal-presentation" }),
      _c("smart-house-modal-dialog", { ref: "modal-smart-house" }),
      _vm.mascotActive
        ? _c("popup-mascot", {
            ref: "mascot",
            attrs: {
              title: _vm.mascot.title,
              button: _vm.mascot.text_button,
              formName: _vm.mascot.form_name,
            },
          })
        : _vm._e(),
      _c("video-review-dialog", { ref: "video-review" }),
      false
        ? _c("december-promo-popup", { ref: "modal-december-promo" })
        : _vm._e(),
      _c("parking-promo-modal-dialog", { ref: "modal-parking-promo" }),
      _c("video-promo-dialog", { ref: "modal-video-promo" }),
      _c("countdown-popup", { ref: "modal-countdown" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }