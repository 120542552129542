<template>
  <footer-block />
</template>

<script>
import FooterBlock from "@/components/blocks/FooterBlock";

export default {
  name: "TheFooter",
  components: {
    FooterBlock,
  },
  data: () => {
    return {
      heading: "this-footer",
    };
  },
};
</script>

<style lang="scss" scoped></style>
