var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "popup-fade" }, on: { "after-enter": _vm.afterEnter } },
    [
      _vm.visible
        ? _c("div", { staticClass: "popup-mascot" }, [
            _c(
              "div",
              {
                staticClass: "popup-mascot__speech",
                on: { click: _vm.playAudio },
              },
              [
                _c("svg-speech-balloon"),
                _c("div", { staticClass: "popup-mascot__msg" }, [
                  _c("h6", {
                    staticClass: "popup-mascot__title",
                    domProps: { innerHTML: _vm._s(_vm.title) },
                  }),
                  _c("span", { staticClass: "popup-mascot__text" }),
                  _c(
                    "button",
                    {
                      staticClass: "popup-mascot__btn",
                      on: { click: _vm.handleButtonClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.button) + " ")]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "popup-mascot__content",
                on: { click: _vm.playAudio },
              },
              [
                _c("svg-mascot"),
                _c("button", {
                  staticClass: "popup-mascot__close",
                  attrs: { tabindex: "1" },
                  on: { click: _vm.close },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }