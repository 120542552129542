var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    { attrs: { visible: _vm.visible }, on: { close: _vm.close } },
    [
      !_vm.success
        ? _c("template", { slot: "title" }, [
            _vm._v(
              " " + _vm._s(_vm.title ? _vm.title : "Прописка уже скоро!") + " "
            ),
          ])
        : _vm._e(),
      _vm.success
        ? _c(
            "div",
            {
              staticClass: "presentation-modal",
              attrs: { slot: "content" },
              slot: "content",
            },
            [_c("modal-success", { on: { close: _vm.close } })],
            1
          )
        : _vm._e(),
      !_vm.success
        ? _c(
            "div",
            {
              staticClass: "presentation-modal",
              attrs: { slot: "content", id: _vm.idModal },
              slot: "content",
            },
            [
              _c("div", { staticClass: "presentation-modal__advantages" }, [
                _vm.shownPresentation
                  ? _c("div", { staticClass: "presentation advantages" }, [
                      _c(
                        "div",
                        { staticClass: "advantages-item" },
                        [
                          _c("icon-type-finish"),
                          _c("p", { staticClass: "advantages-item__desc" }, [
                            _vm._v("Виды отделки с примерами"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "advantages-item" },
                        [
                          _c("icon-use-material"),
                          _c("p", { staticClass: "advantages-item__desc" }, [
                            _vm._v("используемые материалы"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "advantages-item" },
                        [
                          _c("icon-features"),
                          _c("p", { staticClass: "advantages-item__desc" }, [
                            _vm._v("Особенности жилого комплекса"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _c("div", { staticClass: "booklet advantages" }, [
                      _c(
                        "div",
                        { staticClass: "advantages-item" },
                        [
                          _c("icon-advantag-apartartament"),
                          _c("p", { staticClass: "advantages-item__desc" }, [
                            _vm._v("Презентация апартаментов"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "advantages-item" },
                        [
                          _c("icon-material-features"),
                          _c("p", { staticClass: "advantages-item__desc" }, [
                            _vm._v("Материалы и особенности"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "advantages-item" },
                        [
                          _c("icon-unique"),
                          _c("p", { staticClass: "advantages-item__desc" }, [
                            _vm._v("Акционные предложения"),
                          ]),
                        ],
                        1
                      ),
                    ]),
              ]),
              _c(
                "form",
                {
                  ref: "presentation-form",
                  staticClass: "presentation-modal__form presentation-form",
                  attrs: { id: _vm.id, method: "POST" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "presentation-form__row" }, [
                    _c(
                      "div",
                      { staticClass: "presentation-form__col" },
                      [
                        _c("ui-input", {
                          ref: "form-name",
                          attrs: {
                            id: _vm.id + "-name",
                            "placeholder-value": "Имя",
                            hint: "Введите корректные данные",
                            type: "text",
                            "placeholder-white": "",
                            required: "",
                          },
                          model: {
                            value: _vm.name,
                            callback: function ($$v) {
                              _vm.name = $$v
                            },
                            expression: "name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "presentation-form__col" },
                      [
                        _c("ui-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "+7 (###) ###-##-##",
                              expression: "'+7 (###) ###-##-##'",
                            },
                          ],
                          ref: "form-phone",
                          attrs: {
                            id: _vm.id + "-phone",
                            type: "tel",
                            hint: "Введите корректный номер телефона",
                            "placeholder-value": "телефон",
                            "placeholder-white": "",
                            required: "",
                          },
                          model: {
                            value: _vm.phone,
                            callback: function ($$v) {
                              _vm.phone = $$v
                            },
                            expression: "phone",
                          },
                        }),
                        !_vm.isPhoneValid
                          ? _c("div", { staticClass: "form-phone_valid" }, [
                              _vm._v(" Введите корректный номер телефона "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "presentation-form__row" },
                    [
                      _c(
                        "ui-button",
                        {
                          attrs: { type: "submit" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" " + _vm._s(_vm.buttonName) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "presentation-form__row" },
                    [
                      _c("ui-input", {
                        ref: "form-agreement",
                        staticClass: "row",
                        attrs: {
                          id: _vm.id + "-agreement",
                          type: "checkbox",
                          dark: "",
                          required: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "Нажимая кнопку «Отправить», я соглашаюсь с политикой обработки "
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: { name: "UserAgreementPage" },
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v("персональных данных")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3188345207
                        ),
                        model: {
                          value: _vm.checkbox,
                          callback: function ($$v) {
                            _vm.checkbox = $$v
                          },
                          expression: "checkbox",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }