<template>
  <div
    :class="{
      'time-block__container': true,
      'time-block__container_mobile': mobile,
    }"
  >
    <div class="time-block__content">
      <div class="time-block__list">
        <div v-for="(item, index) in items" :key="index">
          <div
            :class="[
              'time-block__item',
              'time-item',
              index === items.length - 1 || dismissAfterElem
                ? 'dismiss-after'
                : '',
            ]"
          >
            <div class="time-item__content">
              <div class="time-item__header">
                <span class="time-item__header_big">{{ item.big }}</span>
                <span class="time-item__header_normal">{{ item.normal }}</span>
              </div>
              <div class="time-item__text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="time-block__text">
      <slot name="block" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeBlock",
  props: {
    items: {
      type: Array,
      required: true,
    },
    dismissAfterElem: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.time-block {
  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: $screen-mini) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__content {
    z-index: 9;
    position: relative;

    &::before {
      content: " ";
      width: 2000px;
      height: 78px;
      right: 0;
      position: absolute;
      background: #111111;
      display: block;
      bottom: 0;
    }

    @media screen and (min-width: $screen-sm) {
      margin: 0 50px 15px 0;
    }

    @media screen and (min-width: $screen-lg) {
      position: absolute;
    }
  }

  &__container_mobile {
    flex-direction: column;

    .time-block__text {
      margin-bottom: 50px;
      order: -1;
      font-size: 13px;
    }

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;

      .time-block__text {
        margin-bottom: 0;
        order: 0;
        font-size: 16px;
      }
    }
  }
  &__list {
    display: flex;
    align-items: center;
    color: #ffffff;
    position: relative;
  }

  &__text {
    position: relative;
    left: 0;
    width: auto;
    font-family: Rubik, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 0.5);

    @media screen and (min-width: $screen-lg) {
      --l: 375px;

      left: var(--l);
      width: calc(100% - var(--l));
    }
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;
    bottom: 13px;

    &::after {
      content: "";
      width: 64px;
      height: 2px;
      background: rgba(255, 255, 255, 0.5);
      transform: rotate(-45deg);
      position: relative;
      top: 29px;
      margin: 0 5px;

      @media screen and (min-width: $screen-md) {
        margin: 0 10px;
      }
    }

    &.after {
      margin-right: 115px;

      &::after {
        width: 0;
        display: none;
      }
    }

    &.dismiss-after {
      margin-right: 29px;

      &::after {
        width: 0;
        display: none;
      }
    }
  }
}
.time-item {
  &__header {
    &_big {
      font-family: "Bebas Neue", sans-serif;
      font-size: 80px;
      letter-spacing: 0.03em;
      margin-right: 8px;
    }

    &_normal {
      font-size: 18px;
      letter-spacing: 0.03em;

      @media screen and (min-width: $screen-sm) {
        font-size: 20px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 132.5%;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.7;
  }
}
</style>
