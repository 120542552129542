var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      staticClass: "december-promo-popup__wrapper",
      attrs: { visible: _vm.visible },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          staticClass: "december-promo-popup",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c(
            "div",
            {
              staticClass: "december-promo-popup__close",
              attrs: { role: "button", "aria-label": "закрыть" },
              on: { click: _vm.close },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "32",
                    height: "32",
                    viewBox: "0 0 32 32",
                    fill: "none",
                  },
                },
                [
                  _c("g", { attrs: { "clip-path": "url(#clip0_11_6)" } }, [
                    _c("rect", {
                      attrs: {
                        x: "5.94913",
                        y: "8.0509",
                        width: "2.81042",
                        height: "25.2938",
                        transform: "rotate(-45 5.94913 8.0509)",
                        fill: "white",
                      },
                    }),
                    _c("rect", {
                      attrs: {
                        x: "7.93652",
                        y: "25.9363",
                        width: "2.81042",
                        height: "25.2938",
                        transform: "rotate(-135 7.93652 25.9363)",
                        fill: "white",
                      },
                    }),
                  ]),
                  _c("defs", [
                    _c("clipPath", { attrs: { id: "clip0_11_6" } }, [
                      _c("rect", {
                        attrs: { width: "32", height: "32", fill: "white" },
                      }),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c("picture", [
            _c("source", {
              attrs: {
                srcset: require("../../assets/images/promo-december/img-desk.png"),
                media: "(min-width: 768px)",
              },
            }),
            _c("img", {
              staticClass: "december-promo-popup__image",
              attrs: {
                src: require("../../assets/images/promo-december/img-mob.png"),
                width: "280",
                height: "330",
                alt: "promo-december",
              },
            }),
          ]),
          _c("div", { staticClass: "december-promo-popup__background" }),
          _c("div", { staticClass: "december-promo-popup__content" }, [
            _c("div", { staticClass: "december-promo-popup__title" }, [
              _vm._v(" декабрь - время чудес? "),
            ]),
            _c("div", { staticClass: "december-promo-popup__subtitle" }, [
              _vm._v(" а что если? "),
            ]),
            _c(
              "div",
              { staticClass: "december-promo-popup__buttons" },
              [
                _c(
                  "UiButton",
                  {
                    attrs: {
                      "data-popup-with-chats": "",
                      "data-popup-with-chats-title": "Узнать подробнее",
                      type: "color-variable",
                      "data-popup-with-chats-source-id": "decemberPopupYes",
                    },
                    on: { click: _vm.close },
                  },
                  [_vm._v(" Да ")]
                ),
                _c(
                  "UiButton",
                  {
                    attrs: {
                      "data-popup-with-chats": "",
                      "data-popup-with-chats-title": "Узнать подробнее",
                      "data-popup-with-chats-source-id": "decemberPopupNo",
                      light: "",
                    },
                    on: { click: _vm.close },
                  },
                  [_vm._v(" Нет ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }