var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal-fade", mode: "out-in" } }, [
    _vm.isVisible
      ? _c(
          "div",
          { staticClass: "modal-dialog", attrs: { id: "modal-dialog" } },
          [
            _c("div", {
              staticClass: "modal-dialog__overlay",
              on: { click: _vm.close },
            }),
            _c("div", { staticClass: "modal-dialog__body modal-body" }, [
              _c(
                "div",
                {
                  staticClass: "modal-body__close",
                  attrs: { tabindex: "2" },
                  on: { click: _vm.close },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "15",
                        height: "14",
                        viewBox: "0 0 15 14",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          opacity: "0.5",
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M6.29634 7.00005L0.00390625 13.2928L0.710995 13.9999L7.00343 7.70717L13.296 14.0001L14.0031 13.293L7.71052 7.00005L14.0031 0.707125L13.296 0L7.00343 6.29292L0.710996 0.000168525L0.00390625 0.707293L6.29634 7.00005Z",
                          fill: "white",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  class: [
                    "modal-body__title",
                    { "modal-body__title--small": _vm.isSmallTitle },
                  ],
                },
                [_vm._t("title")],
                2
              ),
              _c(
                "div",
                { staticClass: "modal-body__content" },
                [_vm._t("content")],
                2
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }