var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    { attrs: { visible: _vm.visible }, on: { close: _vm.close } },
    [
      _c("template", { slot: "title" }, [_vm._v(" Выгода до 450 т.р. ")]),
      _c(
        "div",
        {
          staticClass: "subscribe-modal",
          attrs: { slot: "content", id: _vm.idModal },
          slot: "content",
        },
        [
          _c("div", { staticClass: "subscribe-modal__desc" }, [
            _vm._v(" * при контрактации до 31.12.2021 г."),
            _c("br"),
            _vm._v("Размер скидки уменьшается ежедневно "),
          ]),
          _c(
            "ui-button",
            {
              staticClass: "phone-content__recall",
              attrs: {
                "data-popup-with-chats": "",
                "data-popup-with-chats-title": "Узнать подробнее",
              },
            },
            [_vm._v(" Узнать подробнее ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }