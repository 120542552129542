var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      staticClass: "video-review",
      attrs: { visible: _vm.visible },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          staticClass: "video-review__container",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c(
            "button",
            { staticClass: "video-review__close", on: { click: _vm.close } },
            [_vm._v("×")]
          ),
          _c("iframe", {
            staticClass: "video-review__frame",
            attrs: {
              src: "https://www.youtube.com/embed/4TqDZ71GPKk?autoplay=1&controls=1&disablekb=1&fs=0&modestbranding=1&loop=1&rel=0",
              title:
                "ЖК «MainStreet» | Обзор проекта и его окружения | Ключевые факторы при выборе правильного жилья",
              frameborder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }