<template>
  <div
    ref="parent"
    :class="[
      {
        'is-focused': isFocus,
        'is-valid': valid,
        'has-value': value,
        'has-error': error,
        'is-disabled': disabled,
        'is-dark': dark,
        'has-hint': hint,
      },
      size,
    ]"
    class="vue-input-ui"
    @click="focusInput"
  >
    <input
      :id="id"
      ref="VueInputUi"
      v-model="inputValue"
      v-bind="$attrs"
      :placeholder="placeholderValue"
      :type="type"
      :class="{
        'vue-input-ui__input': true,
        'vue-input-ui__input--placheholder-white': placeholderWhite,
      }"
      :disabled="disabled"
      :required="required"
      @keydown="keyDown"
      @keyup="keyUp"
      @focus="onFocus"
      @blur="onBlur"
      @click="$emit('click')"
    />
    <label
      ref="label"
      :for="id"
      :class="error ? 'text-danger' : null"
      class="vue-input-ui__label"
      @click="focusInput"
    >
      <slot name="label"> {{ hintValue || labelValue }} </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "UiInput",
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    placeholderValue: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: Boolean,
    },
    color: {
      type: String,
      default: "dodgerblue",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    darkColor: {
      type: String,
      default: "#424242",
    },
    id: {
      type: String,
      default: "VueInputUi",
    },
    size: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholderWhite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    labelValue() {
      let { label } = this;
      const res = this.required && label ? (label += "") : label;
      return res;
    },
    hintValue() {
      let { hint } = this;
      const res = this.required && hint ? (hint += "") : hint;
      return res;
    },
  },
  methods: {
    focusInput() {
      this.$refs.VueInputUi.focus();
    },
    onFocus() {
      this.$emit("focus");
      this.isFocus = true;
    },
    onBlur() {
      this.$emit("blur");
      this.isFocus = false;
    },
    clear() {
      this.$emit("input", null);
      this.$emit("clear");
    },
    keyUp(e) {
      this.$emit("keyup", e);
    },
    keyDown(e) {
      this.$emit("keydown", e);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff5926;
$second-color-light: rgba(24, 24, 27, 0.5);
$second-color-dark: rgba(255, 255, 255, 0.52);
$third-color-light: #bababa;
$third-color-dark: #bababa;
$bg-color-light: #fff;
$bg-color-dark: #000;
$error-color: #ed0000;
$disabled-color: #747474;

.vue-input-ui {
  position: relative;
  min-width: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $screen-sm) {
    min-width: 200px;
  }
  @media screen and (min-width: $screen-lg) {
    min-width: 220px;
  }

  &.row {
    flex-direction: row;
  }

  &:not(.vue-input-ui__input.textarea) {
    height: 42px;
    min-height: 42px;
  }
  &__label {
    font-family: Rubik;
    position: relative;
    cursor: pointer;
    left: 0;
    transform: translateY(25%);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 10px;
    color: $second-color-light;
    opacity: 0;
    text-transform: none;
    height: 30px;

    @media screen and (min-width: $screen-sm) {
      font-size: 11px;
    }
  }
  &__input {
    cursor: pointer;
    background-color: transparent;
    transition-duration: 0.3s;
    position: relative;
    width: 100%;
    padding: 0 0;
    font-weight: 400;
    appearance: none;
    outline: none;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $third-color-light;
    font-size: 14px;
    text-transform: uppercase;
    z-index: 0;
    caret-color: $primary-color;

    &:not(.textarea) {
      height: 33px;
      min-height: 33px;
    }

    &.attempt:invalid {
      box-shadow: none;
      border-color: $error-color !important;

      + .vue-input-ui__label {
        opacity: 1;
        color: $error-color !important;
      }
    }

    &[type="checkbox"] {
      min-width: auto;
      width: 16px;
      height: 16px;
      min-height: 16px;
      border: 1px solid $third-color-light;
      position: absolute;
      z-index: -1;
      opacity: 0;
      display: none;

      &.attempt:invalid {
        box-shadow: none;
        border-color: $error-color !important;

        + .vue-input-ui__label {
          &:before {
            border-color: $error-color !important;
          }
        }
      }

      + .vue-input-ui__label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        left: 0;
        top: 0;
        opacity: 1;

        &:before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          flex-grow: 0;
          border: 1px solid $third-color-light;
          margin-right: 8px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
          transition: 0.5s;
        }
        &:hover {
          &:before {
            border-color: #ff5926 !important;
          }
        }
      }
      &:checked {
        + .vue-input-ui__label {
          &:before {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='rgba(24, 24, 27, 0.5)' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
          }
          &:hover {
            &:before {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FF5926' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
            }
          }
        }
      }
    }

    &.textarea {
      padding: 8px 12px 0 12px;
      min-height: 88px !important;
      resize: vertical;
    }
    &::-webkit-input-placeholder {
      font-family: Bebas Neue;
      color: $second-color-light;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    &::-moz-placeholder {
      font-family: Bebas Neue;
      color: $second-color-light;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    &:-ms-input-placeholder {
      font-family: Bebas Neue;
      color: $second-color-light;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    &::-ms-input-placeholder {
      font-family: Bebas Neue;
      color: $second-color-light;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    &:-moz-placeholder {
      font-family: Bebas Neue;
      color: $second-color-light;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    &::placeholder {
      font-family: Bebas Neue;
      color: $second-color-light;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    &__input:-webkit-autofill,
    &__input:-webkit-autofill:hover,
    &__input:-webkit-autofill:focus,
    &__input:-webkit-autofill:active {
      box-shadow: 0 0 0 1000px $bg-color-light inset !important;
      -webkit-text-fill-color: $second-color-light !important;
    }
    &--placheholder-white {
      color: #747476;

      &::-webkit-input-placeholder {
        color: #747476;
      }
      &::-moz-placeholder {
        color: #747476;
      }
      &:-ms-input-placeholder {
        color: #747476;
      }
      &::-ms-input-placeholder {
        color: #747476;
      }
      &:-moz-placeholder {
        color: #747476;
      }
      &::placeholder {
        color: #747476;
      }
    }
  }
  &.is-dark {
    .vue-input-ui {
      &__label {
        color: $second-color-dark;
        font-size: 11px;
        line-height: 132.5%;
        a {
          color: $bg-color-light;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &__input {
        background-color: $bg-color-dark;
        border-bottom: 1px solid $third-color-dark;
        color: $second-color-dark;
        &::-webkit-input-placeholder {
          color: $second-color-dark;
        }
        &::-moz-placeholder {
          color: $second-color-dark;
        }
        &:-ms-input-placeholder {
          color: $second-color-dark;
        }
        &::-ms-input-placeholder {
          color: $second-color-dark;
        }
        &:-moz-placeholder {
          color: $second-color-dark;
        }
        &::placeholder {
          color: $second-color-dark;
        }
        &__input:-webkit-autofill,
        &__input:-webkit-autofill:hover,
        &__input:-webkit-autofill:focus,
        &__input:-webkit-autofill:active {
          box-shadow: 0 0 0 1000px $bg-color-dark inset !important;
          -webkit-text-fill-color: $second-color-dark !important;
        }

        &[type="checkbox"] {
          min-width: auto;
          width: 16px;
          height: 16px;
          min-height: 16px;
          border: 1px solid $third-color-dark;
          position: absolute;
          z-index: -1;
          opacity: 0;
          display: none;

          + .vue-input-ui__label {
            display: inline-flex;
            align-items: center;
            user-select: none;
            left: 0;
            top: 0;

            &:before {
              content: "";
              display: inline-block;
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              flex-grow: 0;
              border: 1px solid $third-color-dark;
              margin-right: 8px;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 50% 50%;
            }
          }
          &:checked {
            + .vue-input-ui__label {
              &:before {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
              }
            }
          }
        }
      }
    }
  }
  &.is-focused {
    .vue-input-ui {
      &__input {
        border-color: $primary-color;
      }
      &__label {
        color: $primary-color;
      }
    }
  }
  &.is-disabled {
    cursor: not-allowed;
    .vue-input-ui__input {
      border-color: #ccc;
      background-color: #f2f2f2;
      color: $disabled-color;
      &::-webkit-input-placeholder {
        color: $disabled-color;
      }
      &::-moz-placeholder {
        color: $disabled-color;
      }
      &:-ms-input-placeholder {
        color: $disabled-color;
      }
      &::-ms-input-placeholder {
        color: $disabled-color;
      }
      &:-moz-placeholder {
        color: $disabled-color;
      }
      &::placeholder {
        color: $disabled-color;
      }
    }
    .vue-input-ui__label,
    .vue-input-ui__input,
    .vue-input-ui__toggle__arrow {
      cursor: not-allowed;
      color: $disabled-color;
    }
  }
  &.sm {
    &:not(.vue-input-ui__input.textarea) {
      height: 36px;
      min-height: 36px;
    }
    .vue-input-ui__input {
      font-size: 12px;
      &:not(.textarea) {
        height: 36px;
        min-height: 36px;
      }
    }
    .vue-input-ui__label {
      font-size: 10px;
    }
    &.has-value {
      .vue-input-ui__input {
        padding-top: 12px;
        &.textarea {
          padding-top: 18px;
        }
      }
    }
  }
  &.lg {
    &:not(.vue-input-ui__input.textarea) {
      height: 48px;
      min-height: 48px;
    }
    .vue-input-ui__input {
      font-size: 16px;
      &:not(.textarea) {
        height: 48px;
        min-height: 48px;
      }
    }
    .vue-input-ui__label {
      font-size: 14px;
    }
    &.has-value {
      .vue-input-ui__input {
        padding-top: 16px;
        &.textarea {
          padding-top: 26px;
        }
      }
    }
  }
}
</style>
