<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="19.5"
      cy="19.5"
      r="19"
      fill="white"
      fill-opacity="0.2"
      stroke="url(#paint0_linear)"
    />
    <path d="M13.5 26.5V11H26.5V26.5" stroke="white" stroke-width="0.7" />
    <path d="M29 19H32V27" stroke="white" stroke-width="0.7" />
    <path d="M11 19H8V27" stroke="white" stroke-width="0.7" />
    <path d="M16.5 14.5H17.5" stroke="white" stroke-width="0.7" />
    <path d="M16.5 18H17.5" stroke="white" stroke-width="0.7" />
    <path d="M16.5 22H17.5" stroke="white" stroke-width="0.7" />
    <path d="M16.5 26H17.5" stroke="white" stroke-width="0.7" />
    <path d="M20 14.5H21" stroke="white" stroke-width="0.7" />
    <path d="M20 18H21" stroke="white" stroke-width="0.7" />
    <path d="M20 22H21" stroke="white" stroke-width="0.7" />
    <path d="M20 26H21" stroke="white" stroke-width="0.7" />
    <path d="M23 14.5H24" stroke="white" stroke-width="0.7" />
    <path d="M23 18H24" stroke="white" stroke-width="0.7" />
    <path d="M23 22H24" stroke="white" stroke-width="0.7" />
    <path d="M23 26H24" stroke="white" stroke-width="0.7" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="19.5"
        y1="0"
        x2="19.5"
        y2="39"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconAdvantagApart",
};
</script>

<style scoped lang="scss"></style>
