<template>
  <modal-dialog class="december-promo-popup__wrapper" :visible="visible" @close="close">
    <div class="december-promo-popup" slot="content">
      <div class="december-promo-popup__close" role="button" aria-label="закрыть" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <g clip-path="url(#clip0_11_6)">
            <rect x="5.94913" y="8.0509" width="2.81042" height="25.2938" transform="rotate(-45 5.94913 8.0509)" fill="white"/>
            <rect x="7.93652" y="25.9363" width="2.81042" height="25.2938" transform="rotate(-135 7.93652 25.9363)" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_11_6">
              <rect width="32" height="32" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <picture>
        <source srcset="../../assets/images/promo-december/img-desk.png" media="(min-width: 768px)" />
        <img class="december-promo-popup__image" src="../../assets/images/promo-december/img-mob.png" width="280" height="330" alt="promo-december" />
      </picture>
      <div class="december-promo-popup__background"></div>
      <div class="december-promo-popup__content">
        <div class="december-promo-popup__title"> декабрь - время чудес? </div>
        <div class="december-promo-popup__subtitle"> а что если? </div>
        <div class="december-promo-popup__buttons">
          <UiButton
            data-popup-with-chats
            data-popup-with-chats-title="Узнать подробнее"
            type="color-variable"
            data-popup-with-chats-source-id="decemberPopupYes"
            @click="close"
          >
            Да
          </UiButton>
          <UiButton
            data-popup-with-chats
            data-popup-with-chats-title="Узнать подробнее"
            data-popup-with-chats-source-id="decemberPopupNo"
            light
            @click="close"
          >
            Нет
          </UiButton>
        </div>
      </div>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";
import UiButton from "../../components/ui/UIButton";
export default {
  name: "DecemberPromoPopup",
  components: {
    UiButton,
    ModalDialog
  },
  data () {
    return {
      visible: false,
    }
  },
  methods: {
    show(
    ) {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  }
}
</script>

<style lang="scss">
.december-promo-popup {
  background-color: #000000;
  box-sizing: border-box;
  font-family: var(--font-family-2);
  position: relative;
  height: 330px;
  width: 280px;

  @media screen and (min-width: $screen-sm) {
    width: 432px;
    height: 450px;
  }

  &__wrapper.modal-dialog {
    display: flex;
    .modal-body {
      background: none;
      width: auto;
      left: 50%;
      transform: translate(-50%, 0);
      right: unset;
      padding: 0;
      height: auto;
      margin: 0;
      position: absolute;
    }
    .modal-body__title,
    .modal-body__close {
      display: none;
    }

  }
  &__close {
    position: absolute;
    top: 18px;
    right: 18px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    z-index: 10;
    img {
      display: block;
      width: 100%;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(24, 24, 27, 0.3), rgba(24, 24, 27, 0.3));
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    box-sizing: border-box;

    @media screen and (min-width: $screen-sm) {
      padding: 50px;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;

    @media screen and (min-width: $screen-sm) {
      margin-top: 36px;
    }

    & > .ui-button {
      width: 43%;

      &--light {
        border-color: white;
        background: transparent;

        &:hover {
          border-color: white;
          background: transparent;
        }
      }
    }
  }

  &__title {
    font-size: 24px;
    line-height: 105%;
    letter-spacing: 0.05em;
    margin-bottom: 12px;
    color: #FFFFFF;

    @media screen and (min-width: $screen-sm) {
      font-size: 36px;
    }
  }

  &__subtitle {
    font-size: 20px;
    line-height: 105%;
    letter-spacing: 0.05em;
    color: #FFFFFF;

    @media screen and (min-width: $screen-sm) {
      font-size: 32px;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
  }
}
</style>
