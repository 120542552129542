var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        breadcrumbs: true,
        "breadcrumbs--dark": _vm.dark,
        "breadcrumbs--light": _vm.light,
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "router-link",
            {
              staticClass: "breadcrumbs__link",
              attrs: { to: { name: "MainPage" } },
            },
            [_vm._v(" " + _vm._s(_vm.mainLinkName) + " ")]
          ),
          _vm._v(" / "),
        ],
        1
      ),
      _vm._l(_vm.$route.meta.breadcrumbs, function (crumb, index) {
        return _c(
          "span",
          { key: index },
          [
            _c(
              "router-link",
              {
                staticClass: "breadcrumbs__link",
                attrs: { to: { name: crumb.name } },
              },
              [_vm._v(" " + _vm._s(crumb.title) + " ")]
            ),
            _vm._v(" / "),
          ],
          1
        )
      }),
      _c("span", { staticClass: "breadcrumbs__inactive-item" }, [
        _vm._v(" " + _vm._s(_vm._f("removeTag")(_vm.breadcrumb)) + " "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }