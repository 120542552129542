var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("the-header", { attrs: { "banner-visible": "" } }),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("keep-alive", [_c("router-view")], 1)],
        1
      ),
      _c("the-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }