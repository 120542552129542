<template>
  <svg
    :class="{ 'icon-arrow-right': true, 'icon-arrow-right--hovered': hovered }"
    width="48"
    height="13"
    viewBox="0 0 48 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="7" r="4.5" :stroke="color" />
    <path d="M17 6.5H47M47 6.5L41.1667 1M47 6.5L41.1667 12" :stroke="color" />
  </svg>
</template>

<script>
export default {
  name: "IconArrowRight",
  props: {
    hovered: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style scoped lang="scss">
.icon-arrow-right {
  &--hovered {
    &:hover {
      circle,
      path {
        stroke: #e14313;
      }
    }
  }
}
</style>
