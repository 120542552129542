<template>
  <div class="info-line">
    <component
      :is="item.href ? 'a' : 'div'"
      v-for="(item, i) in list"
      :key="i"
      :class="[
        'info-line__item',
        { 'info-line__item--animate': list.length > 1 && i === activeIndex },
        { 'info-line__item--no-animate': list.length === 1 },
      ]"
      :href="item.href"
      target="_blank"
      @click="!item.href ? $emit('click', $event, item) : null"
    >
      <div class="info-line__text" v-html="item.text" />
    </component>
  </div>
</template>

<script>
export default {
  name: "InfoLine",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    slideDuration: {
      type: Number,
      default: 4000,
    },
  },
  data() {
    return {
      activeIndex: 0,
      interval: null,
    };
  },
  activated() {
    if (this.list.length > 1) {
      this.interval = setInterval(this.setActiveIndex, this.slideDuration);
      console.log(this.list)
    }
  },
  deactivated() {
    clearInterval(this.interval);
  },
  methods: {
    setActiveIndex() {
      this.activeIndex++;
      if (this.activeIndex === this.list.length) {
        this.activeIndex = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-line {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  height: 70px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 20px;

  @media (min-width: $screen-sm) {
    font-size: 14px;
    line-height: 1.15;
  }

  @media (min-width: $screen-lg) {
    font-size: 16px;
  }

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 600vw;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(34, 31, 32),
      rgb(34, 31, 32) 35%,
      rgb(235, 76, 27) 50%,
      rgb(34, 31, 32) 65%,
      rgb(34, 31, 32)
    );
    background-size: 100%;
    background-repeat: repeat-x;
    animation-name: gradient;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }

  ::v-deep {
    b {
      font-weight: 600;
    }
  }

  &__item {
    z-index: 1;
    display: none;
    width: 100%;
    height: 100%;
    color: #fff;
    font-family: $font-family-bebas;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    @media screen and (min-width: $screen-xs) {
      font-size: 16px;
    }

    @media screen and (min-width: $screen-sm) {
      font-size: 24px;
    }

    &--no-animate,
    &--animate {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--animate {
      animation: rotate 4s;
    }
  }
}

@keyframes gradient {
  0% {
    left: 0;
  }

  100% {
    left: -400vw;
  }
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}
</style>
