<template>
  <div v-if="dayCount > 0" class="countdown">
    <div class="countdown__box" v-html="title" />
    <div class="countdown__table">
      <div
        :key="intervalDayCount"
        class="countdown__cards"
        :class="{ 'countdown__cards--flip': animationActive }"
      >
        <div class="countdown__card">
          <div class="countdown__card-top">{{ intervalDayCount }}</div>
          <div class="countdown__card-bottom" :data-i="intervalDayCount + 1" />
          <div class="countdown__card-back" :data-i="intervalDayCount + 1">
            <div class="countdown__card-bottom" :data-i="intervalDayCount" />
          </div>
        </div>
      </div>
      <div class="countdown__line" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    finalDate: {
      type: Date,
      default: () => new Date(),
    },
    extraDayCount: {
      type: Number,
      default: 3,
    },
    title: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      dayCount: 0,
      interval: null,
      intervalDayCount: 0,
      animationActive: false,
      animationInited: false,
      animationDelay: 750,
      animationInterval: 300,
    };
  },
  mounted() {
    this.initDayCount();
    this.startAnimation();
  },
  activated() {
    this.startAnimation();
  },
  deactivated() {
    this.animationInited = false;
  },
  methods: {
    initDayCount() {
      const today = new Date();
      const difference = this.finalDate - today;

      if (difference > 0) {
        this.dayCount = new Date(difference).getDate();
      }
    },
    startAnimation() {
      if (this.dayCount === 0 || this.animationInited) {
        return;
      }
      this.intervalDayCount = this.dayCount + this.extraDayCount;
      setTimeout(() => {
        this.interval = setInterval(() => {
          if (this.intervalDayCount === this.dayCount) {
            return clearInterval(this.interval);
          }
          this.animationActive = true;
          this.intervalDayCount -= 1;
        }, this.animationInterval);
      }, this.animationDelay);
      this.animationInited = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  $c: &;
  --bgc: #111;

  position: relative;
  width: 98px;
  height: 102px;

  &__table {
    z-index: 1;
    position: relative;
    width: 84px;
    margin: 0 auto;
  }

  --card-h: 34px;
  --line-h: 2px;

  &__card {
    width: 100%;
    height: var(--card-h);
    border-radius: 4px;
    background-color: #fff;

    &:not(:last-child) {
      margin-bottom: var(--line-h);
    }
  }

  &__date {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #e14313;
    font-family: $font-family-bebas;
    font-size: 80px;
    font-weight: 400;
    text-align: center;
  }

  &__line {
    z-index: 1;
    position: absolute;
    top: var(--card-h);
    width: 100%;
    height: var(--line-h);
    background-color: var(--bgc);

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 12px;
      border-radius: 10px;
      background-color: inherit;
    }

    --p: 6px;

    &::before {
      left: var(--p);
    }

    &::after {
      right: var(--p);
    }
  }

  &__box {
    position: absolute;
    top: 25px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 77px;
    box-sizing: border-box;
    padding: 0 4px 4px;
    background-color: var(--bgc);
    color: #b8b8b8;
    font-family: Rubik, sans-serif;
    font-size: 8px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
  }

  &__table {
    width: 84px;
    height: 70px;
    perspective: 400px;
    text-align: center;
    margin: 0 auto;

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
  }

  &__cards {
    z-index: 1;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    &--flip {
      #{$c} {
        &__card {
          &-back {
            &::before {
              transform-origin: center bottom;
              animation: flip-top 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35) both;
            }

            #{$c}__card-bottom {
              transform-origin: center top;
              animation: flip-bottom 0.4s cubic-bezier(0.15, 0.45, 0.28, 1) both;
            }
          }
        }
      }
    }
  }

  $card-height: 34px;

  &__card {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: $card-height;
    color: #e14313;
    font-family: $font-family-bebas;
    font-size: 80px;
    font-weight: 400;

    &-top,
    &-bottom,
    &-back::before,
    &-back::after {
      transform: translateZ(0);
      transform-style: preserve-3d;
      display: block;
      width: 100%;
      height: $card-height;
      border-radius: 4px;
      border-top: 1px solid #bdbdbd;
      padding-top: 0.25em;
      background-color: #e0e0e0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    &-bottom {
      position: absolute;
      top: 50%;
      left: 0;
      border-top: none;
      background-color: #fff;
      overflow: hidden;

      &::after {
        display: block;
        margin-top: -$card-height;
      }
    }

    &-back::before,
    &-bottom::after {
      content: attr(data-i);
    }

    &-back {
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100%;

      &::before {
        z-index: -1;
        position: relative;
        overflow: hidden;
      }
    }
  }

  @keyframes flip-top {
    0% {
      z-index: 2;
      transform: rotateX(0deg);
    }

    0%,
    99% {
      opacity: 1;
    }

    100% {
      transform: rotateX(-180deg);
      opacity: 0;
    }
  }

  @keyframes flip-bottom {
    0%,
    50% {
      z-index: -1;
      transform: rotateX(180deg);
      opacity: 0;
    }

    51% {
      opacity: 0.99;
    }

    100% {
      z-index: 5;
      transform: rotateX(0deg);
      opacity: 0.99;
    }
  }
}
</style>
