<template>
  <transition name="popup-fade" @after-enter="afterEnter">
    <div v-if="visible" class="popup-mascot">
      <div class="popup-mascot__speech" @click="playAudio">
        <svg-speech-balloon />
        <div class="popup-mascot__msg">
          <h6 class="popup-mascot__title" v-html="title"></h6>
          <span class="popup-mascot__text" />
          <button class="popup-mascot__btn" @click="handleButtonClick">
            {{ button }}
          </button>
        </div>
      </div>
      <div class="popup-mascot__content" @click="playAudio">
        <svg-mascot />
        <button class="popup-mascot__close" tabindex="1" @click="close" />
      </div>
    </div>
  </transition>
</template>

<script>
import SvgSpeechBalloon from "@/components/icons/SVGSpeechBalloon";
import SvgMascot from "@/components/icons/SVGMascot";

export default {
  name: "PopupMascot",
  components: {
    SvgSpeechBalloon,
    SvgMascot,
  },
  data() {
    return {
      visible: false,
      audioSource: require("@/assets/audio/incoming.wav"),
    };
  },
  computed: {
    audio() {
      return new Audio(this.audioSource);
    },
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: ''
    },
    formName: {
      type: String,
      default: ''
    }
  },
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    afterEnter(el) {
      el.classList.add("active");
    },
    playAudio() {
      this.audio.play();
    },
    handleButtonClick() {
      this.showSubscribeDialog(
        this.formName,
        "",
        "Отправить",
        "mascot-callback",
        true
      );
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
$screen-xs: 390px;
$screen-xxs: 370px;

.popup-fade-enter-active,
.popup-fade-leave-active {
  transition: all 0.3s;
}

.popup-fade-enter,
.popup-fade-leave-to {
  transform: translateX(25%);
  opacity: 0;
}

.popup-mascot {
  z-index: 9999;
  position: fixed;
  right: -41px;
  bottom: 10%;
  width: 430px;
  height: 480px;

  @media screen and (max-width: $screen-xs) {
    width: 410px;
  }

  @media screen and (max-width: $screen-xxs) {
    width: 360px;
    height: 382px;
  }

  &.active {
    .popup-mascot__speech {
      opacity: 1;
    }
  }

  &__speech {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s 0.3s;

    @media screen and (max-width: $screen-xs) {
      & > svg {
        width: 223.8px;
        height: 245.65px;
      }
    }

    @media screen and (max-width: $screen-xxs) {
      & > svg {
        width: 195.04px;
        height: 212.52px;
      }
    }
  }

  &__msg {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66%;
  }

  &__title {
    color: #484848;
    font-family: $font-family-bebas;
    font-size: 16px;
    line-height: 23px;
    margin-top: 0;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 500;

    @media screen and (max-width: $screen-xs) {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 6px;
    }

    @media screen and (max-width: $screen-xxs) {
      font-size: 13px;
      line-height: 17px;
    }
    &-whitebox {
      margin: 0;
      font-weight: bold;
      font-family: inherit;
      display: -webkit-inline-box;
    }
  }

  &__text {
    color: #808080;
    font-family: $font-family-rubik;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    text-transform: none;

    @media screen and (max-width: $screen-xs) {
      font-size: 11px;
      line-height: 17px;
    }

    @media screen and (max-width: $screen-xxs) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__btn {
    @extend %btn--rounded;
    margin: 40px auto 0;
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-3px);
    }

    @media screen and (max-width: $screen-xs) {
      font-size: 12px;
    }

    @media screen and (max-width: $screen-xxs) {
      font-size: 11px;
    }
  }

  &__content {
    position: absolute;
    top: 180px;
    right: 0;

    @media screen and (max-width: $screen-xs) {
      top: 158px;
    }

    @media screen and (max-width: $screen-xxs) {
      top: 137px;

      & > svg {
        width: 260px;
        height: 260px;
      }
    }
  }

  &__close {
    @extend %close-btn--circle;
    position: absolute;
    top: -28px;
    right: 55px;
  }
}
</style>
