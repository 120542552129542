<template>
  <div class="modal-success">
    <div slot="body" class="modal-success__body">
      <icon-success class="modal-success__icon" />
      <div class="modal-success__title">Заявка принята!</div>
      <div class="modal-success__text">
        консультант свяжется с Вами в ближайшее время
      </div>
    </div>
    <div slot="footer" class="modal-success__footer">
      <ui-button type="submit" @click="$emit('close')"> хорошо! </ui-button>
    </div>
  </div>
</template>

<script>
import IconSuccess from "@/components/icons/IconSuccess";
import UiButton from "@/components/ui/UIButton";

export default {
  name: "ModalSuccess",
  components: {
    IconSuccess,
    UiButton,
  },
};
</script>

<style lang="scss" scoped>
.modal-success {
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    margin-bottom: 45px;
  }
  &__title {
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 105%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 18px;
  }
  &__text {
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 132.5%;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 35px;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
}
</style>
