<template>
  <div class="slider-navigation">
    <div
      :class="{
        'slider-navigation__button': true,
        'slider-navigation__button--left': true,
        'slider-navigation__button--active': activePrev,
        'slider-navigation__button--disable': disablePrev,
      }"
      @click="goToPrev"
    >
      <icon-arrow-left :color="colorArrow" />
    </div>
    <div
      :class="{
        'slider-navigation__button': true,
        'slider-navigation__button--active': activeNext,
        'slider-navigation__button--disable': disableNext,
      }"
      @click="goToNext"
    >
      <icon-arrow-right :color="colorArrow" />
    </div>
  </div>
</template>

<script>
import IconArrowLeft from "@/components/icons/IconArrowLeft";
import IconArrowRight from "@/components/icons/IconArrowRight";

export default {
  name: "SliderNavigationArrows",
  components: { IconArrowRight, IconArrowLeft },
  props: {
    colorArrow: String,
    activeNext: Boolean,
    activePrev: Boolean,
    disableNext: Boolean,
    disablePrev: Boolean,
  },
  methods: {
    goToNext() {
      this.$emit("next");
    },
    goToPrev() {
      this.$emit("prev");
    },
  },
};
</script>

<style lang="scss">
.slider-navigation {
  &__button {
    circle,
    path {
      transition: all 300ms ease-out;
    }

    &--active {
      circle,
      path {
        stroke: #e14313;
      }
    }

    &--disable {
      opacity: 0;
    }

    &:hover {
      circle,
      path {
        stroke: #e14313;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.slider-navigation {
  max-width: 120px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 0 10px 30px;
  height: 30px;
  display: flex;
  align-items: center;

  &__button {
    cursor: pointer;

    &--disabled {
      opacity: 0.5;
      cursor: auto;
    }

    &--left {
      margin-right: 20px;
    }
  }
}
</style>
