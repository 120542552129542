var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "header",
      attrs: { mascot: "" },
      on: {
        touchstart: function ($event) {
          return _vm.$root.showMascot($event)
        },
      },
    },
    [
      _vm.lineVisible
        ? _c("info-line", {
            staticClass: "header__line",
            attrs: { list: _vm.line.list },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "header__box" },
        [
          _c("header-block", { attrs: { fixed: _vm.fixed, phone: _vm.phone } }),
          _vm.bannerVisible
            ? _c(
                "swiper",
                {
                  ref: "swiper",
                  staticClass: "header__swiper",
                  attrs: { options: _vm.swiperOptions },
                },
                _vm._l(_vm.slides, function (slide, i) {
                  return _c(
                    "swiper-slide",
                    { key: i, staticClass: "header__slide" },
                    [
                      i === 0
                        ? _c(
                            "video",
                            {
                              key: _vm.updateKey,
                              ref: "video",
                              refInFor: true,
                              staticClass: "header__video",
                              attrs: {
                                poster: require("@/assets/images/offer/video-poster.jpg"),
                                autoplay: "",
                                loop: "",
                                muted: "",
                                playsinline: "",
                              },
                              domProps: { muted: true },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  type: "video/mp4",
                                  src: require("@/assets/videos/offer/01-hd.mp4"),
                                  media: "(min-width: 768px)",
                                },
                              }),
                              _c("source", {
                                attrs: {
                                  type: "video/mp4",
                                  src: require("@/assets/videos/offer/01.mp4"),
                                  media: "(min-width: 0px)",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "picture",
                            [
                              _vm._l(slide.picture, function (source, j) {
                                return _c("source", {
                                  key: j,
                                  attrs: {
                                    type: source.type,
                                    "data-srcset": source.srcset,
                                    media: source.media,
                                  },
                                })
                              }),
                              _c("img", {
                                staticClass: "header__img swiper-lazy",
                                attrs: {
                                  "data-src": slide.picture[0].src,
                                  alt: "",
                                },
                              }),
                            ],
                            2
                          ),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "header__content" },
            [
              _vm.bannerVisible && _vm.firstSlide
                ? _c(
                    "header-content",
                    {
                      attrs: {
                        title: _vm.firstSlide.title,
                        subtitle: _vm.firstSlide.subtitle,
                        addition: _vm.firstSlide.addition_text_2,
                        phone: _vm.phone,
                      },
                    },
                    [
                      _vm.swiperActive
                        ? _c("slider-navigation-arrows", {
                            staticClass: "header__nav",
                            attrs: { slot: "navigation" },
                            on: { next: _vm.slideNext, prev: _vm.slidePrev },
                            slot: "navigation",
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }