var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      staticClass: "parking-promo-modal-dialog",
      attrs: { visible: _vm.visible },
      on: { close: _vm.close },
    },
    [
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "parking-promo-modal-dialog__content" }, [
          _c("img", {
            staticClass: "parking-promo-modal-dialog__back",
            attrs: {
              src: require("@/assets/images/banners/parking-promo.jpg"),
            },
          }),
          _c(
            "div",
            { staticClass: "parking-promo-modal-dialog__text" },
            [
              _c("div", { staticClass: "parking-promo-modal-dialog__title" }, [
                _vm._v("Возможно ты уже"),
                _c("br"),
                _vm._v("готов получить"),
                _c("br"),
                _vm._v("парковку в подарок?"),
              ]),
              _c(
                "ui-button",
                {
                  attrs: {
                    type: "submit",
                    "data-popup-with-chats": "",
                    "data-popup-with-chats-title":
                      "Возможно ты уже готов получить парковку в подарок?",
                  },
                  on: { click: _vm.close },
                },
                [_vm._v(" Узнать условия ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }