<template>
  <modal-dialog class="countdown-popup__wrapper" :visible="visible" @close="close">
    <div class="countdown-popup" slot="content">
      <div class="countdown-popup__left">
        <div class="countdown-popup__close" role="button" aria-label="закрыть" @click="close">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <g opacity="0.7" clip-path="url(#clip0_2_450)">
            <rect x="7.94897" y="9.93637" width="2.81042" height="25.2938" transform="rotate(-45 7.94897 9.93637)" fill="white"/>
            <rect x="9.93652" y="27.8218" width="2.81042" height="25.2938" transform="rotate(-135 9.93652 27.8218)" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_2_450">
            <rect width="36" height="36" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <div class="countdown-popup__title" v-html="data.title"></div>
        <div class="countdown-popup__timer-wrapper" v-if="data.date">
          <MsTimer
            class="countdown-popup__timer"
            :title="data.timerTitle"
            :date="data.date"
          />
        </div>
        <div class="countdown-popup__text" v-html="data.text"></div>
        <div class="countdown-popup__button">
          <UiButton
            data-popup-with-chats
            :data-popup-with-chats-title="data.title"
            type="color-variable"
            @click="close"
          >
            Узнать подробнее
          </UiButton>
        </div>
      </div>
      <div v-if="data.image" class="countdown-popup__right">
        <img :src="data.image">
      </div>
    </div>
  </modal-dialog>
</template>

<script>
import { MsTimer } from "@osnova-adv/ms3-blocks";
import ModalDialog from "@/components/dialogs/ModalDialog";
import UiButton from "@/components/ui/UIButton";
export default {
  name: "CountdownPopup",
  components: {
    MsTimer,
    UiButton,
    ModalDialog
  },
  data () {
    return {
      visible: false,
      data: {
        title: '',
        text: '',
        image: '',
        timerTitle: '',
        date: null
      }
    }
  },
  methods: {
    show( data
    ) {
      this.data.title = data.title ? data.title : ''
      this.data.text = data.text ? data.text : ''
      this.data.image = data.image ? data.image : ''
      this.data.date = data.date ? data.date : ''
      this.data.timerTitle = data.timerTitle ? data.timerTitle : ''
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
  mounted () {
    window.addEventListener('popup-with-chats-send', this.close)
  },
  destroyed () {
    window.addEventListener('popup-with-chats-send', this.close)
  },
}
</script>

<style lang="scss">
.countdown-popup {
  background-color: #000000;
  width: 100%;
  max-width: 360px!important;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family-2);
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: $screen-sm) {
    max-width: unset!important;
    flex-direction: row;
  }
  &__left {
    width: 360px;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  &__right {
    width: auto;
    img {
      display: block;
      width: 100%;
      @media screen and (min-width: $screen-sm) {
        width: auto;
      }
    }
  }
  &__wrapper.modal-dialog {
    display: flex;
    .modal-body {
      background: none;
      width: auto;
      left: 50%;
      transform: translate(-50%, 0);
      right: unset;
      padding: 0;
      height: auto;
      margin: 0;
      position: absolute;
    }
    .modal-body__title,
    .modal-body__close {
      display: none;
    }
    
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
    img {
      display: block;
      width: 100%;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  &__text {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 120%;
    color: #FFFFFF;
    opacity: 0.7;
    margin-bottom: 10px;
  }
  &__button {
    .button {
      padding: 15px 50px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 92%;
    }
  }
  &__timer.ms-timer-block {
    width: 100%;
  }
  &__timer-wrapper {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 10px;
    @media screen and (min-width: $screen-sm) {
      margin-left: 0;
      margin-right: 0;
    }
    .ms-timer-block {
      padding-top: 0;
    }
  }
}
</style>
