var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "39",
        height: "39",
        viewBox: "0 0 39 39",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "19.5",
          cy: "19.5",
          r: "19",
          fill: "white",
          "fill-opacity": "0.2",
          stroke: "url(#paint0_linear)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28 27.1895V11.8086H11V27.1895H23.9524",
          stroke: "white",
          "stroke-width": "0.7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.2606 27.1896V21.1182M16.2606 21.1182H13.832M16.2606 21.1182V16.2611H21.9273V13.4277",
          stroke: "white",
          "stroke-width": "0.7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M28.0015 19.9043H19.9062V23.1424",
          stroke: "white",
          "stroke-width": "0.7",
        },
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear",
                x1: "19.5",
                y1: "0",
                x2: "19.5",
                y2: "39",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { "stop-color": "white", "stop-opacity": "0" },
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": "white" } }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }