<template>
	<modal-dialog class="parking-promo-modal-dialog" :visible="visible" @close="close">
		<template slot="content">
			<div class="parking-promo-modal-dialog__content">
        <img src="@/assets/images/banners/parking-promo.jpg" class="parking-promo-modal-dialog__back">
				<div class="parking-promo-modal-dialog__text">
          <div class="parking-promo-modal-dialog__title">Возможно ты уже<br>готов получить<br>парковку в подарок?</div>
          <ui-button @click="close" type="submit" data-popup-with-chats data-popup-with-chats-title="Возможно ты уже готов получить парковку в подарок?"> Узнать условия </ui-button>
        </div>
			</div>
		</template>
	</modal-dialog>
</template>
  
<script>
  import ModalDialog from "@/components/dialogs/ModalDialog";
  import UiButton from "@/components/ui/UIButton";
  
  export default {
    name: "MortgageModalDialog",
    components: { ModalDialog, UiButton },
    data: () => ({
      visible: false,
    }),
    methods: {
      show() {
        this.visible = true;
      },
      close() {
        this.visible = false;
      },
    },
  };
</script>
  
<style lang="scss">
.parking-promo-modal-dialog {
  &.modal-dialog {
    .modal-body {
      width: 432px;
      height: 450px;
      box-sizing: border-box;
      padding: 0;
      display: flex;
      @media screen and (max-width: $screen-mini) {
        width: 280px;
        height: 330px;
      }
    }
    .modal-body__title {
      display: none;
    }
    .modal-body__close {
      z-index: 2;
    }
    .modal-body__content {
      width: 100%;
    }
  }

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  &__text {
    position: relative;
    z-index: 2;
    text-align: center;
    margin: auto;
  }
  &__content {
    display: flex;
    height: 100%;
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 105%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-bottom: 36px;
    @media screen and (max-width: $screen-mini) {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
}
</style>
  