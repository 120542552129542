export default {
  $setMeta({ title, description, image, url }) {
    if (title) {
      document.title = title;
      document.querySelector("meta[property='og:title']").content = title;
    }
    if (description) {
      document.querySelector("meta[name='description']").content = description;
      document.querySelector("meta[property='og:description']").content =
        description;
    }
    if (image) {
      document.querySelector("meta[property='og:image']").content = image;
    }
    if (url) {
      document.querySelector("meta[property='og:url']").content = url;
    }
  },
};
