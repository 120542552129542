<template>
  <div class="content-item">
    <div class="content-item__icon">
      <slot name="icon" />
    </div>
    <div class="content-item__title">
      <div class="content-item__text">
        <slot name="text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentItem",
};
</script>

<style lang="scss" scoped>
.content-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;

  @media screen and (min-width: $screen-mini) {
    align-items: flex-start;
    flex-direction: column;
    width: auto;
    margin-bottom: 0;
  }
  @media screen and (min-width: $screen-md) {
    align-items: center;
    flex-direction: row;
  }
  @media screen and (min-width: $screen-lg) {
    margin-right: 70px;
  }

  &:last-child {
    margin-right: 0;
  }

  &__icon {
    margin-right: 15px;
    width: 39px;
    height: 38px;
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    @media screen and (min-width: $screen-mini) {
      margin-bottom: 15px;
    }
    @media screen and (min-width: $screen-md) {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    width: 100%;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    box-sizing: border-box;
    padding: 5px 6px 0;
    background: rgba(255, 255, 255, 0.1);
    line-height: 1;
  }
}
</style>
