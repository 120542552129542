var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "menu-button",
      on: {
        touchstart: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "menu-button__link",
          on: {
            click: function ($event) {
              return _vm.setMenuOpened()
            },
          },
        },
        [
          _c(
            "div",
            {
              class: [_vm.menuIsOpened ? "gg-close" : "gg-menu-left"],
              attrs: { "data-color": "white" },
            },
            [_c("span")]
          ),
        ]
      ),
      _c("transition", { attrs: { name: "menu-fade", mode: "out-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.menuIsOpened,
                expression: "menuIsOpened",
              },
            ],
            class: {
              "menu-button__block": true,
              "menu-button__block--closed": !_vm.menuIsOpened,
            },
          },
          [
            _c("div", { staticClass: "menu-button__left" }),
            _c("div", { staticClass: "menu-button__right" }, [
              _c("div", { staticClass: "menu-content" }, [
                _c(
                  "div",
                  { staticClass: "menu-button__list" },
                  _vm._l(_vm.menu, function (item, index) {
                    return _c(
                      "menu-block",
                      { key: index, staticClass: "menu-button__content" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "menu-button__item",
                            class: { active: item.active },
                            attrs: {
                              slot: "link",
                              exact: "",
                              to: item.anchor,
                              "active-class":
                                item.anchor !== "#" ? "active-always" : "",
                            },
                            slot: "link",
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "menu-button__links" },
                  [
                    _c(
                      "ui-button",
                      {
                        staticClass: "menu-button__button",
                        attrs: {
                          "data-popup-with-chats": "",
                          "data-popup-with-chats-title": "Позвоните мне",
                        },
                      },
                      [_vm._v(" позвоните мне ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "menu-button__socials socials-block" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "socials-block__link",
                            attrs: {
                              href: "https://t.me/kvartal_mainstreet",
                              target: "__blank",
                            },
                          },
                          [_c("icon-telegram-menu")],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "socials-block__link",
                            attrs: {
                              href: "https://vk.com/osnova_mainstreet",
                              target: "__blank",
                            },
                          },
                          [_c("icon-vk-menu")],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }