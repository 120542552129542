var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slider-navigation" }, [
    _c(
      "div",
      {
        class: {
          "slider-navigation__button": true,
          "slider-navigation__button--left": true,
          "slider-navigation__button--active": _vm.activePrev,
          "slider-navigation__button--disable": _vm.disablePrev,
        },
        on: { click: _vm.goToPrev },
      },
      [_c("icon-arrow-left", { attrs: { color: _vm.colorArrow } })],
      1
    ),
    _c(
      "div",
      {
        class: {
          "slider-navigation__button": true,
          "slider-navigation__button--active": _vm.activeNext,
          "slider-navigation__button--disable": _vm.disableNext,
        },
        on: { click: _vm.goToNext },
      },
      [_c("icon-arrow-right", { attrs: { color: _vm.colorArrow } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }