export default function () {
  function mainTeletype() {
    var teletypeWidget = document.querySelector(
      "#teletype-widget-component-popup"
    ).contentDocument;
    var telegramLink = teletypeWidget.querySelectorAll(".telegram");

    for (let i = 0; i < telegramLink.length; i++) {
      telegramLink[i].addEventListener("click", function () {
        window.dataLayer.push({
          event: "telegramIconClick",
        });
      });
    }

    var messengerLink = teletypeWidget.querySelectorAll(".messenger");

    for (let i = 0; i < messengerLink.length; i++) {
      messengerLink[i].addEventListener("click", function () {
        window.dataLayer.push({
          event: "messengerIconClick",
        });
      });
    }

    var viberLink = teletypeWidget.querySelectorAll(".viber");

    for (let i = 0; i < viberLink.length; i++) {
      viberLink[i].addEventListener("click", function () {
        window.dataLayer.push({
          event: "viberIconClick",
        });
      });
    }

    var whatsappLink = teletypeWidget.querySelectorAll(".whatsapp");

    for (let i = 0; i < whatsappLink.length; i++) {
      whatsappLink[i].addEventListener("click", function () {
        window.dataLayer.push({
          event: "whatsappIconClick",
        });
      });
    }

    window.addEventListener("teletype.opened", function () {
      window.dataLayer.push({
        event: "teletypeOpenWidget",
      });
    });
  }

  document.addEventListener("teletype.ready", function () {
    var teletypeWidget = document.querySelector(
      "#teletype-widget-component-popup"
    );
    var teletypeWidgetDocument = teletypeWidget.contentDocument;
    var limit = 10;
    var timeout = setInterval(function () {
      limit--;
      var buttonsContainer = teletypeWidgetDocument.querySelector(
        ".teletype-messengers-buttons"
      );
      if (buttonsContainer) {
        mainTeletype();
        clearInterval(timeout);
      }
      if (limit === 0) {
        clearInterval(timeout);
      }
    }, 100);

    document.addEventListener("teletype.dialogStarted", function (e) {
      if (typeof Comagic !== "undefined") {
        window.Comagic.addOfflineRequest(
          {
            name: "Имя: ЧАТ | Форма: ЧАТ",
            phone: "",
            message: "Текст: " + e.detail.message,
          },
          function (res) {
            var response = JSON.parse(res.response);
            window.dataLayer.push({
              event: "chatSendMessage",
              form_name: "ЧАТ",
            });

            if (response.success === true) {
              console.log("Заявка успешно получена CoMagic");
            } else {
              console.log("Ошибка обработки заявки на стороне CoMagic");
            }
          }
        );
      }
    });
  });
}
