<template>
  <svg
    width="86"
    height="86"
    viewBox="0 0 86 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="43" cy="43" r="42.5" stroke="url(#paint0_linear)" />
    <path d="M26 38.1562L40.902 53L66 28" stroke="white" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="43"
        y1="0"
        x2="43"
        y2="86"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconSuccess",
};
</script>

<style scoped lang="scss"></style>
