<template>
  <modal-dialog :visible="visible" @close="close">
    <modal-success slot="content" @close="close" />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";
import ModalSuccess from "@/components/dialogs/ModalSuccess";

export default {
  name: "SuccessModalDialog",
  components: { ModalSuccess, ModalDialog },
  data: () => ({
    visible: false,
  }),
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
