<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    version="1.1"
  >
    <g>
      <path
        :fill="color"
        d="M 7.996094 12.554688 C 7.710938 12.554688 7.425781 12.445312 7.207031 12.226562 L 0.328125 5.351562 C -0.109375 4.914062 -0.109375 4.203125 0.328125 3.765625 C 0.765625 3.328125 1.476562 3.328125 1.914062 3.765625 L 7.996094 9.851562 L 14.082031 3.765625 C 14.519531 3.328125 15.230469 3.328125 15.667969 3.765625 C 16.105469 4.203125 16.105469 4.914062 15.667969 5.351562 L 8.789062 12.226562 C 8.570312 12.445312 8.285156 12.554688 7.996094 12.554688 Z M 7.996094 12.554688 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ArrowDownIcon",
  props: {
    width: {
      type: String,
      default: "16pt",
    },
    height: {
      type: String,
      default: "16pt",
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
};
</script>

<style scoped></style>
