var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "39",
        height: "39",
        viewBox: "0 0 39 39",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "19.5",
          cy: "19.5",
          r: "19",
          fill: "white",
          "fill-opacity": "0.2",
          stroke: "url(#paint0_linear)",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.5 26.5V11H26.5V26.5",
          stroke: "white",
          "stroke-width": "0.7",
        },
      }),
      _c("path", {
        attrs: { d: "M29 19H32V27", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M11 19H8V27", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M16.5 14.5H17.5", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M16.5 18H17.5", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M16.5 22H17.5", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M16.5 26H17.5", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M20 14.5H21", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M20 18H21", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M20 22H21", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M20 26H21", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M23 14.5H24", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M23 18H24", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M23 22H24", stroke: "white", "stroke-width": "0.7" },
      }),
      _c("path", {
        attrs: { d: "M23 26H24", stroke: "white", "stroke-width": "0.7" },
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear",
                x1: "19.5",
                y1: "0",
                x2: "19.5",
                y2: "39",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { "stop-color": "white", "stop-opacity": "0" },
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": "white" } }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }