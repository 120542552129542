var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "86",
        height: "86",
        viewBox: "0 0 86 86",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "43", cy: "43", r: "42.5", stroke: "url(#paint0_linear)" },
      }),
      _c("path", {
        attrs: { d: "M26 38.1562L40.902 53L66 28", stroke: "white" },
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear",
                x1: "43",
                y1: "0",
                x2: "43",
                y2: "86",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", { attrs: { "stop-color": "white" } }),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "white",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }