<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0083 28.8823L19.6 35C20.2 35 20.4651 34.7256 20.8 34.4L23.6827 31.5969L29.68 36.0278C30.7837 36.6368 31.5803 36.3215 31.8552 35.0031L35.7922 16.4505C36.1953 14.8345 35.1762 14.1015 34.1203 14.5809L11.0023 23.4951C9.4243 24.128 9.4335 25.0084 10.7147 25.4007L16.6473 27.2523L30.3819 18.5873C31.0303 18.1941 31.6253 18.4055 31.1369 18.839L20.008 28.882L20.0083 28.8823Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconTelegramFooter",
};
</script>
