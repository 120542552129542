var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "content-wrapper-block",
        { staticClass: "header-block__wrapper" },
        [
          _c(
            "div",
            { staticClass: "header-block__left" },
            [
              _c(
                "div",
                {
                  staticClass: "header-block__logo",
                  on: {
                    click: _vm.click,
                    touchstart: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.setMenuOpened(false)
                        },
                      },
                    },
                    [_c("logo", { staticClass: "header-block__logo-svg" })],
                    1
                  ),
                ],
                1
              ),
              !_vm.menuIsOpened
                ? _c(
                    "menu-block",
                    { staticClass: "header-block__menu" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "header-block__link",
                          attrs: {
                            slot: "link",
                            to: { name: "AboutPage" },
                            "active-class": "header-block__link--active",
                          },
                          slot: "link",
                        },
                        [_vm._v(" О проекте ")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "header-block__link header-block__link--border",
                          attrs: {
                            slot: "link",
                            to: { name: "FilterPage" },
                            "active-class": "header-block__link--active",
                          },
                          slot: "link",
                        },
                        [_vm._v(" Планировки ")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "header-block__link",
                          attrs: {
                            slot: "link",
                            to: { name: "NewsCatalogPage" },
                            "active-class": "header-block__link--active",
                          },
                          slot: "link",
                        },
                        [_vm._v(" Новости ")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "header-block__link",
                          attrs: {
                            slot: "link",
                            to: { name: "ContactPage" },
                            "active-class": "header-block__link--active",
                          },
                          slot: "link",
                        },
                        [_vm._v(" Контакты ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "header-block__phone-column",
              on: {
                touchstart: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              !_vm.menuIsOpened
                ? _c("div", { staticClass: "header-block__right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "header-block__booking-phone",
                        attrs: {
                          "data-popup-with-chats": "",
                          "data-popup-with-chats-title": "Заказать звонок",
                        },
                      },
                      [_vm._v("Заказать звонок")]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "header-block__right" }, [
                _c(
                  "a",
                  {
                    staticClass: "header-block__phone comagic_phone1",
                    attrs: { href: "tel:" + _vm.phone },
                  },
                  [_vm._v(" " + _vm._s(_vm.phone) + " ")]
                ),
              ]),
            ]
          ),
          _c("menu-button-block"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }