<template>
  <svg
    width="243.8"
    height="265.65"
    viewBox="0 0 212 231"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M116.97 1.41746L116.97 1.41749C174.895 7.46272 216.967 59.3828 210.922 117.314C208.435 140.701 198.522 161.53 183.67 177.627L183.508 177.802L183.543 178.039L191.166 230.049L146.216 204.05L146.004 203.927L145.776 204.02C130.199 210.411 112.86 213.14 95.0018 211.274C37.0761 205.195 -4.99516 153.274 1.08408 95.3436L1.08411 95.3433C7.1288 37.4127 59.0443 -4.66233 116.97 1.41746Z"
      fill="white"
      stroke="#4F4E4E"
    />
  </svg>
</template>

<script>
export default {
  name: "SVGSpeechBalloon",
};
</script>
