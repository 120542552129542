<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ContentWrapperBlock",
  props: {
    noOverflow: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "content-wrapper": true,
        "content-wrapper--no-overflow": this.noOverflow,
        "content-wrapper--no-padding": this.noPadding,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrapper {
  &--no-overflow {
    overflow: visible;
  }

  overflow: hidden;
  width: 100%;
  padding: 15px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (min-width: $screen-lg) {
    padding: 15px;
    width: 1200px;
  }

  &--no-padding {
    padding: 0;
  }
}
</style>
