<template>
  <div class="smart-house-modal">
    <div class="smart-house-modal__left">
      <div class="smart-house-modal__title" v-html="titleContent" />
      <div class="smart-house-modal__desc" v-html="desc" />
      <a :href="href" target="_blank" class="smart-house-modal__link">
        {{ linkText }}
      </a>
    </div>
    <div class="smart-house-modal__right">
      <picture>
        <source :srcset="imageDesk" media="(min-width: 768px)" />
        <img :src="imageMob" />
      </picture>
      <div class="smart-house-modal__close" role="button" @click="hide">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.2924 20L13 26.2928L13.7071 26.9999L19.9995 20.7072L26.2921 27.0001L26.9992 26.293L20.7066 20L26.9992 13.7071L26.2921 13L19.9995 19.2929L13.7071 13.0002L13 13.7073L19.2924 20Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmartHouseModalBlock",
  props: {
    href: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default:
        "Пройдите тестирование и узнайте, какой Умный Дом вам нужен на самом деле",
    },
    titleContent: {
      type: String,
      default: "Сделай своё жильё Умным",
    },
    linkText: {
      type: String,
      default: "Пройти опрос",
    },
    imageDesk: {
      type: String,
      default: require("@/assets/images/promo/img_desk_tabl.png"),
    },
    imageMob: {
      type: String,
      default: require("@/assets/images/promo/img_mob.png"),
    },
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.smart-house-modal {
  width: 671px;
  max-width: 100%;
  background: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  &__left {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.05em;
    color: rgba(72, 72, 72, 0.8);
    padding: 6px 15px 20px 15px;
    @media screen and (min-width: 768px) {
      width: calc(100% - 304px);
      padding: 30px;
      padding-right: 77px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }
  &__right {
    position: relative;
    @media screen and (min-width: 768px) {
      width: 304px;
    }
    picture {
      display: block;
    }
    img {
      width: 100%;
      display: block;
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  &__title {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &__desc {
    margin-bottom: 30px;
    @media screen and (min-width: 768px) {
      margin-bottom: auto;
    }
  }
  &__link {
    padding: 8px;
    display: block;
    width: 100%;
    background: #ff5722;
    border-radius: 3px;
    box-sizing: border-box;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 130%;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    transition: all 300ms ease-out;
    text-decoration: none;
    &:focus,
    &:visited {
      text-decoration: none;
      color: #fff;
    }
    &:hover {
      text-decoration: none;
      background: #df2d00;
      color: #fff;
      box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.08);
    }
  }
}
</style>
