var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "39",
        height: "39",
        viewBox: "0 0 39 39",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "19.5",
          cy: "19.5",
          r: "19",
          fill: "white",
          "fill-opacity": "0.2",
          stroke: "url(#paint0_linear)",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M14.0085 6.5L15.7664 11.2506L20.5171 13.0085L15.7664 14.7664L14.0085 19.5171L12.2506 14.7664L7.5 13.0085L12.2506 11.2506L14.0085 6.5ZM14.0085 8.51708L12.7954 11.7954L9.51708 13.0085L12.7954 14.2216L14.0085 17.5L15.2216 14.2216L18.5 13.0085L15.2216 11.7954L14.0085 8.51708Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M25.5085 15.5L26.8613 19.1558L30.5171 20.5085L26.8613 21.8613L25.5085 25.5171L24.1558 21.8613L20.5 20.5085L24.1558 19.1558L25.5085 15.5ZM25.5085 17.5171L24.7006 19.7006L22.5171 20.5085L24.7006 21.3165L25.5085 23.5L26.3165 21.3165L28.5 20.5085L26.3165 19.7006L25.5085 17.5171Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M17.0085 24.5L17.9562 27.0609L20.5171 28.0085L17.9562 28.9562L17.0085 31.5171L16.0609 28.9562L13.5 28.0085L16.0609 27.0609L17.0085 24.5ZM17.0085 26.5171L16.6057 27.6057L15.5171 28.0085L16.6057 28.4114L17.0085 29.5L17.4114 28.4114L18.5 28.0085L17.4114 27.6057L17.0085 26.5171Z",
          fill: "white",
        },
      }),
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "paint0_linear",
                x1: "19.5",
                y1: "0",
                x2: "19.5",
                y2: "39",
                gradientUnits: "userSpaceOnUse",
              },
            },
            [
              _c("stop", {
                attrs: { "stop-color": "white", "stop-opacity": "0" },
              }),
              _c("stop", { attrs: { offset: "1", "stop-color": "white" } }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }