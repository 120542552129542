<template>
  <modal-dialog :class="['video-promo', banner.class]" :visible="visible" @close="close">
    <template slot="content">
      <button class="video-promo__close" @click="close" />
      <div class="video-promo__img">
        <picture v-if="banner.img">
          <template v-for="(item, i) in banner.img.sources">
            <source v-if="item.webp" :srcset="item.webp" type="image/webp" :media="item.media" />
            <source v-if="item.jpg" :srcset="item.jpg" :media="item.media" />
          </template>
          <img :src="banner.img.default" loading="lazy" class="video-promo__image" />
        </picture>
        <video 
          :poster="banner.video.poster"
          autoplay="autoplay"
          loop="loop"
          muted
          playsinline
          class="video-promo__video"
          ref="video"
          v-if="banner.video"
        >
        <source v-for="(item, i) in banner.video.sources" :key="i" :type="item.type" :src="item.src" :media="item.media">
      </video>
      </div>
      <div class="video-promo__content">
        <div class="video-promo__content-box">
          <h1 class="video-promo__title" v-html="banner.title" />
          <div class="video-promo__text" v-html="banner.description" />
        </div>
        <UiButton class="video-promo__btn"
          data-popup-with-chats
          :data-popup-with-chats-title="banner.text_button || 'Узнать подробнее'"
          @click="close"
        >
          {{ banner.text_button || 'Узнать подробнее' }}
        </UiButton>
      </div>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";
import UiButton from "@/components/ui/UIButton";

export default {
  name: "VideoPromoDialog",
  components: { UiButton, ModalDialog },
  data: () => ({
    id: "subscribe",
    visible: false,
    checkbox: true,
    success: false,
    banner: {
      video: null,
      img: null,
      title: '',
      description: '',
    }
  }),
  computed: {
    isPhoneValid() {
      return this.phone.length >= 18 || this.phone.length < 1;
    },
  },
  methods: {
    show(
      banner = null
    ) {
      if (banner) {
        this.banner = banner;
      }
      this.visible = true;
      this.checkbox = true;
      this.success = false;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.video-promo {
  display: flex;
  ::v-deep {
    .modal-body {
      padding: 0;
      height: auto;
      width: 280px;
      position: relative;
      margin: auto;
      top: 0;
      @media (min-width: $screen-md) {
        display: flex;
        flex-direction: row-reverse;
        width: 920px;
      }
    }
    .modal-body__content {
      justify-content: space-between;
      width: 100%;
      background-color: #18181B;
      @media (min-width: $screen-md) {
        display: flex;
        flex-direction: row-reverse;
      }
    }
    .modal-body__title {
      display: none;
    }
  }

  span {
    display: inline-block;
  }

  &__close {
    $offset: 5px;

    position: absolute;
    z-index: 2;
    top: $offset;
    right: $offset;
    width: 40px;
    aspect-ratio: 1;
    border: none;
    border-radius: 50%;
    padding: 0;
    overflow: hidden;
    background-color: transparent;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 8.3px;
      left: calc(50% - 1px);
      transform: rotate(45deg);
      width: 2px;
      height: 25px;
      background-color: #18181b;
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__img {
    width: auto;
    height: auto;
    position: relative;
    video {
      width: 100%;
      display: block;
      @media (min-width: $screen-md) {
        height: 100%;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }

  &__content {
    box-sizing: border-box;
    padding: 20px;
    margin-top: 0;
    height: 100%;

    ul {
      margin: 0;
      padding-left: 20px;
      li {
        list-style-type: none;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: -11px;
          top: 8px;
          border-radius: 50%;
          background-color: #333;
          width: 2px;
          height: 2px;
        }
      }
    }

    @media (min-width: $screen-md) {
      width: calc(100% - 460px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 85.5px 40px;
    }
  }

  &__subtitle,
  &__title,
  &__text {
    font-weight: 400;
  }
  &__small-font {
    font-size: 11px;
    line-height: 15px;
    @media (min-width: $screen-md) {
      font-size: 70%;
    }
  }

  &__subtitle,
  &__title {
    text-transform: uppercase;
  }

  &__subtitle {
    color: #fff;
    font-size: 10px;
    margin-bottom: 15px;

    @media (min-width: $screen-md) {
      margin-bottom: 20px;
    }
  }

  &__title,
  &__text {
    color: #fff;
    font-family: var(--font-family-2);
  }

  &__title {
    font-size: 24px;
    line-height: 1.05;
    letter-spacing: 0.05em;
    margin-top: 0;
    margin-bottom: 24px;

    @media (min-width: $screen-md) {
      font-size: 36px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 132.5%;
    margin-bottom: 30px;

    @media (min-width: $screen-md) {
      font-size: 16px;
      line-height: 120%;
    }
  }

  &__btn {
    display: block;
    width: 180px;
    height: 38px;
    padding-right: unset;
    padding-left: unset;
    margin: 0 auto;

    @media (min-width: $screen-md) {
      width: 220px;
      height: 49px;
      margin: unset;
    }
  }

  &.variant-1 {
    .video-promo {
      &__content {
        @media (min-width: $screen-md) {
          padding: 60px 40px;
        }
      }

      &__title {
        width: auto;
        margin-bottom: 12px;
      }

      &__text {
        font-family: $font-family-rubik;
        font-weight: 300;
        margin-bottom: 24px;

        @media (min-width: $screen-md) {
          margin-bottom: 64px;
        }

        ::v-deep {
          ul {
            margin: 0;
            padding-left: 20px;

            li {
              &::after {
                background-color: currentColor;
              }
            }
          }
        }
      }
    }
  }

  &.variant-1 {
    .video-promo {
      &__close {
        &::before,
        &::after {
          background-color: #18181B;
        }
      }

      &__content {
        padding: 20px;

        @media (min-width: $screen-md) {
          padding: 60px 40px;
        }
      }

      &__title {
        width: auto;
        margin-bottom: 12px;
      }

      &__text {
        font-family: $font-family-rubik;
        font-weight: 300;
        margin-bottom: 24px;

        @media (min-width: $screen-md) {
          margin-bottom: 64px;
        }

        ::v-deep {
          ul {
            margin: 0;
            padding-left: 20px;

            li {
              &::after {
                background-color: currentColor;
              }
            }
          }
        }
      }
    }
  }

  &.variant-2 {
    .video-promo {
      &__content {
        @media (min-width: $screen-md) {
          padding-top: 66px;
          padding-bottom: 66px;
        }
      }

      &__title {
        line-height: 1.05;
        margin-bottom: 10px;

        @media (min-width: $screen-md) {
          margin-bottom: 20px;
        }
      }

      &__text {
        @media (min-width: $screen-md) {
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>
