<template>
  <modal-dialog :visible="visible" @close="close">
    <template v-if="!success" slot="title">
      {{ title }}
    </template>
    <div v-if="success" slot="content" class="subscribe-modal">
      <modal-success @close="close" />
    </div>
    <div v-if="!success" :id="idModal" slot="content" class="subscribe-modal">
      <div class="subscribe-modal__desc">
        наш консультант свяжется с вами для подтверждения брони
      </div>
      <parking-info
        class="subscribe-modal__parking"
        :floor="floor"
        :number="number"
        :area="area"
        :price="price"
      />
      <form
        :id="id"
        ref="subscribe-form"
        method="POST"
        class="subscribe-modal__form subscribe-form"
        @submit.prevent="submit"
      >
        <div class="subscribe-form__row">
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-name'"
              ref="form-name"
              v-model="name"
              placeholder-value="Имя"
              hint="Введите корректные данные"
              type="text"
              placeholder-white
              required
            />
          </div>
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-phone'"
              ref="form-phone"
              v-model="phone"
              v-mask="'+7 (###) ###-##-##'"
              type="tel"
              hint="Введите корректный номер телефона"
              placeholder-value="телефон"
              placeholder-white
              required
            />
            <div v-if="!isPhoneValid" class="form-phone_valid">
              Введите корректный номер телефонa
            </div>
          </div>
        </div>
        <div class="subscribe-form__row">
          <ui-button type="submit" @click="submitForm"> Отправить </ui-button>
        </div>
        <div class="subscribe-form__row">
          <ui-input
            :id="id + '-agreement'"
            ref="form-agreement"
            v-model="checkbox"
            class="row"
            type="checkbox"
            dark
            required
          >
            <template #label>
              <span
                >Нажимая кнопку «Отправить», я соглашаюсь с политикой обработки
                <router-link :to="{ name: 'UserAgreementPage' }" target="_blank"
                  >персональных данных</router-link
                >
              </span>
            </template>
          </ui-input>
        </div>
      </form>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";
import UiInput from "@/components/ui/UIInput";
import UiButton from "@/components/ui/UIButton";
import ModalSuccess from "@/components/dialogs/ModalSuccess";
import ParkingInfo from "@/components/elements/ParkingInfo";

export default {
  name: "ParkingModalDialog",
  components: { ParkingInfo, ModalSuccess, UiButton, UiInput, ModalDialog },
  props: {
    title: {
      type: String,
      default: "Ваше собственное место на парковке!",
    },
  },
  data: () => ({
    id: "subscribe",
    visible: false,
    name: "",
    phone: "",
    checkbox: true,
    success: false,
    idModal: "parking-block__place",

    floor: "",
    number: "",
    area: "",
    price: "",
  }),
  computed: {
    isPhoneValid() {
      return this.phone.length >= 18 || this.phone.length < 1;
    },
  },
  methods: {
    show(floor, number, area, price) {
      this.visible = true;
      this.name = "";
      this.phone = "";
      this.checkbox = true;
      this.success = false;

      this.floor = floor;
      this.number = number;
      this.area = area;
      this.price = price;
    },
    close() {
      this.visible = false;
    },
    sendMessage() {
      window.Comagic.addOfflineRequest(
        {
          name: "Имя: " + this.name + " | " + "Форма: " + this.title,
          phone: this.phone,
          message:
            "Описание: " +
            this.title +
            " | " +
            "URL: " +
            document.URL +
            " | " +
            "ID: " +
            this.idModal,
        },
        () => {}
      );
      this.name = "";
      this.phone = "";
    },
    submit(e) {
      if (this.phone.length === 18 && this.name.trim()) {
        this.success = true;
        this.sendMessage();
      }
      e.preventDefault();
    },
    submitForm() {
      this.$refs["form-name"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-phone"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-agreement"].$refs.VueInputUi.classList.add("attempt");
    },
  },
};
</script>

<style scoped lang="scss">
.subscribe-modal {
  &__desc {
    font-family: $font-family-bebas;
    font-weight: 300;
    font-size: 14px;
    line-height: 132.5%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.6;
    margin-bottom: 35px;
  }

  &__parking {
    max-width: 100%;

    @media screen and (min-width: $screen-sm) {
      max-width: 80%;
    }
  }

  &__form,
  .subscribe-form {
    &__row {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
      }
    }

    &__col {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      margin-bottom: 10px;

      @media screen and (min-width: $screen-sm) {
        width: 50%;
      }

      &:first-child {
        padding-right: 0;

        @media screen and (min-width: $screen-sm) {
          padding-right: 15px;
        }
      }

      &:last-child {
        padding-left: 0;

        @media screen and (min-width: $screen-sm) {
          padding-left: 15px;
        }
      }
      .form-phone_valid {
        position: absolute;
        bottom: -7px;
        font-family: Rubik;
        font-size: 12px;
        text-transform: none;
        color: #ed0000;
        line-height: 132.5%;
      }
    }
  }
}
</style>
