<template>
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="19.5"
      cy="19.5"
      r="19"
      fill="white"
      fill-opacity="0.2"
      stroke="url(#paint0_linear)"
    />
    <path
      d="M11.5 28H27.5V16.5H28.5V14.5L19.5 9L10.5 14.5V16.5H11.5V28Z"
      stroke="white"
    />
    <circle cx="17" cy="17.5" r="1" stroke="white" />
    <circle cx="22" cy="22.5" r="1" stroke="white" />
    <path d="M22.5 16.5L16.5 23.5" stroke="white" stroke-linejoin="round" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="19.5"
        y1="0"
        x2="19.5"
        y2="39"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconUnique",
};
</script>

<style scoped lang="scss"></style>
