var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-dialog",
    {
      staticClass: "countdown-popup__wrapper",
      attrs: { visible: _vm.visible },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          staticClass: "countdown-popup",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c("div", { staticClass: "countdown-popup__left" }, [
            _c(
              "div",
              {
                staticClass: "countdown-popup__close",
                attrs: { role: "button", "aria-label": "закрыть" },
                on: { click: _vm.close },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "36",
                      height: "36",
                      viewBox: "0 0 36 36",
                      fill: "none",
                    },
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          opacity: "0.7",
                          "clip-path": "url(#clip0_2_450)",
                        },
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "7.94897",
                            y: "9.93637",
                            width: "2.81042",
                            height: "25.2938",
                            transform: "rotate(-45 7.94897 9.93637)",
                            fill: "white",
                          },
                        }),
                        _c("rect", {
                          attrs: {
                            x: "9.93652",
                            y: "27.8218",
                            width: "2.81042",
                            height: "25.2938",
                            transform: "rotate(-135 9.93652 27.8218)",
                            fill: "white",
                          },
                        }),
                      ]
                    ),
                    _c("defs", [
                      _c("clipPath", { attrs: { id: "clip0_2_450" } }, [
                        _c("rect", {
                          attrs: { width: "36", height: "36", fill: "white" },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _c("div", {
              staticClass: "countdown-popup__title",
              domProps: { innerHTML: _vm._s(_vm.data.title) },
            }),
            _vm.data.date
              ? _c(
                  "div",
                  { staticClass: "countdown-popup__timer-wrapper" },
                  [
                    _c("MsTimer", {
                      staticClass: "countdown-popup__timer",
                      attrs: {
                        title: _vm.data.timerTitle,
                        date: _vm.data.date,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", {
              staticClass: "countdown-popup__text",
              domProps: { innerHTML: _vm._s(_vm.data.text) },
            }),
            _c(
              "div",
              { staticClass: "countdown-popup__button" },
              [
                _c(
                  "UiButton",
                  {
                    attrs: {
                      "data-popup-with-chats": "",
                      "data-popup-with-chats-title": _vm.data.title,
                      type: "color-variable",
                    },
                    on: { click: _vm.close },
                  },
                  [_vm._v(" Узнать подробнее ")]
                ),
              ],
              1
            ),
          ]),
          _vm.data.image
            ? _c("div", { staticClass: "countdown-popup__right" }, [
                _c("img", { attrs: { src: _vm.data.image } }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }