<template>
  <div class="menu-button" @touchstart.stop>
    <a class="menu-button__link" @click="setMenuOpened()">
      <div
        :class="[menuIsOpened ? 'gg-close' : 'gg-menu-left']"
        data-color="white"
      >
        <span />
      </div>
    </a>
    <transition name="menu-fade" mode="out-in">
      <div
        v-show="menuIsOpened"
        :class="{
          'menu-button__block': true,
          'menu-button__block--closed': !menuIsOpened,
        }"
      >
        <div class="menu-button__left" />
        <div class="menu-button__right">
          <div class="menu-content">
            <div class="menu-button__list">
              <menu-block
                v-for="(item, index) in menu"
                :key="index"
                class="menu-button__content"
              >
                <router-link
                  slot="link"
                  exact
                  :to="item.anchor"
                  :class="{ active: item.active }"
                  :active-class="item.anchor !== '#' ? 'active-always' : ''"
                  class="menu-button__item"
                >
                  {{ item.title }}
                </router-link>
              </menu-block>
            </div>
            <div class="menu-button__links">
              <ui-button
                class="menu-button__button"
                data-popup-with-chats
                data-popup-with-chats-title="Позвоните мне"
              >
                позвоните мне
              </ui-button>
              <div class="menu-button__socials socials-block">
                <a
                  class="socials-block__link"
                  href="https://t.me/kvartal_mainstreet"
                  target="__blank"
                >
                  <icon-telegram-menu />
                </a>
                <a
                  class="socials-block__link"
                  href="https://vk.com/osnova_mainstreet"
                  target="__blank"
                >
                  <icon-vk-menu />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import UiButton from "@/components/ui/UIButton";
import IconTelegramMenu from "@/components/icons/IconTelegram";
import IconVkMenu from "@/components/icons/IconVk";
import MenuBlock from "@/components/blocks/header/MenuBlock";

export default {
  name: "Menu",
  components: {
    UiButton,
    IconTelegramMenu,
    IconVkMenu,
    MenuBlock,
  },
  computed: {
    ...mapState({
      menuIsOpened: (state) => state.menuIsOpened,
    }),
  },
  data() {
    return {
      value: null,
      isOpenMenu: false,
      menu: [
        {
          anchor: { name: "AboutPage" },
          title: "О проекте",
        },
        {
          anchor: { name: "PanoramaPage" },
          title: "Панорама",
        },
        {
          anchor: { name: "LocationPage" },
          title: "Расположение",
        },
        // {
        //   anchor: { name: "ApartmentDecorationPage" },
        //   title: "Отделка",
        // },
        {
          anchor: { name: "FilterPage" },
          title: "Выбор апартаментов",
          active: true,
        },
        {
          anchor: { name: "ParkingPage" },
          title: "Паркинг",
        },
        {
          anchor: { name: "ConditionPage" },
          title: "Способы покупки",
        },
        {
          anchor: { name: "ConditionPage", hash: "#mortgage" },
          title: "Ипотека",
        },
        {
          anchor: { name: "OnlineShopping" },
          title: "Онлайн-покупка",
        },
        {
          anchor: { name: "NewsCatalogPage" },
          title: "Новости",
        },
        {
          anchor: { name: "DocumentsPage" },
          title: "Документация",
        },
        {
          anchor: { name: "ContactPage" },
          title: "Контакты",
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setMenuOpened: "SET_MENU_OPENED",
    }),
  },
};
</script>

<style lang="scss">
.menu-button__block {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  max-height: 800px;

  &--closed {
    max-height: 0;

    @media screen and (min-width: $screen-md) {
      max-height: 800px;
    }
  }
}

.menu-fade-enter-active,
.menu-fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.menu-fade-enter {
  opacity: 0;
}

.menu-fade-leave-to {
  opacity: 0;
}
</style>

<style scoped lang="scss">
.socials-block {
  display: flex;
  margin-left: 20px;
  margin-bottom: 25px;

  @media screen and (min-width: $screen-mini) {
    margin: 0 0 30px 20px;
    display: flex;
  }

  &__link {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 11;
    opacity: 1;
    cursor: pointer;
    transition: 0.3s;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.6;
    }

    @media screen and (min-width: $screen-md) {
      width: 24px;
    }
  }
}

.menu-button {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-right: -15px;
  background: rgba(6, 5, 6, 0.21);

  @media screen and (min-width: $screen-xs) {
    height: auto;
    width: auto;
    margin-right: 0;
    background: transparent;
  }

  &__overlay {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    top: 55px;
    // background: rgba(36, 36, 36, 0.5);
    background: url("../../../assets/images/menu/menu-background-right.png")
      no-repeat 100% 0;
    background-size: cover;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 15;

    @media screen and (min-width: $screen-xs) {
      top: 70px;
    }

    @media screen and (min-width: $screen-md) {
      background: rgba(36, 36, 36, 0.5);
    }
  }

  &__block {
    // overflow: scroll;
    position: absolute;
    width: 103%;
    top: 55px;
    left: 0;
    display: flex;
    align-items: center;
    height: 100vh;

    @media screen and (min-width: $screen-xs) {
      top: 65px;
    }

    @media screen and (min-width: $screen-md) {
      top: 70px;
      height: 672px;
      overflow: inherit;
      width: 100%;
    }

    @media screen and (max-height: 768px) and (min-width: $screen-md) {
      height: calc(100vh - 71px);
    }
  }

  &__left {
    background: url("../../../assets/images/menu/menu-background-left.jpg")
      no-repeat 0 0;
    background-size: cover;
    width: calc(50% + 120px);
    height: 100%;
    display: none;
    z-index: 20;

    @media screen and (min-width: $screen-md) {
      display: flex;
    }
  }

  &__right {
    background: url("../../../assets/images/menu/menu-background-right.png")
      no-repeat 100% 0;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 25;
    // overflow: auto;

    @media screen and (min-width: $screen-sm) {
      height: calc(100% - 40px);
    }

    @media screen and (min-width: $screen-md) {
      width: 50%;
      height: calc(100% - 120px);
      padding: 60px 64px;
    }
    @media screen and (max-height: 560px) {
      // height: calc(100vh - 100px);
    }
    @media screen and (max-height: 560px) and (min-width: $screen-md) {
      height: calc(100vh - 190px);
    }

    .menu-content {
      // overflow: auto;
      height: 90%;
      -webkit-overflow-scrolling: touch;
      @media screen and (min-width: 374px) {
        // height: 100%;
      }

      @media screen and (max-width: $screen-md) {
        overflow: auto;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #fff;
      -webkit-box-shadow: #a9a3ae;
    }

    &::-webkit-scrollbar-track {
      background-color: #a9a3ae;
    }

    @media screen and (min-width: $screen-md) {
      height: calc(100% - 0px);
      overflow: hidden;
      // flex-wrap: wrap;
    }
    @media screen and (min-width: $screen-md) {
      height: calc(100% - 0px);
      flex-wrap: wrap;
    }
    @media screen and (min-width: $screen-lg) {
      // height: calc(100% - 145px);
      overflow-y: auto;
      overflow: visible;
    }
    @media screen and (min-width: $screen-md) and (max-height: 700px) {
      flex-wrap: nowrap;
      overflow: auto;
      flex-direction: column;
    }
  }

  &__links {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    justify-content: space-between;
    margin-top: 29px;
    padding-top: 21px;
    padding-bottom: 100px;

    @media screen and (min-width: 374px) {
      margin-top: 0;
    }

    @media screen and (min-width: $screen-mini) {
      display: flex;
    }

    @media screen and (min-width: $screen-md) {
      padding-bottom: 0;
    }

    @media screen and (min-width: $screen-xs) {
      justify-content: flex-start;
      margin-top: 0;
    }
    @media screen and (min-width: $screen-lg) {
      margin-top: 30px;
      padding-top: 20px;
    }
  }

  &__button {
    margin-right: 0;

    @media only screen and (min-width: $screen-sm) {
      margin-right: 38px;
    }
  }

  &__item {
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    line-height: 115%;
    text-transform: uppercase;
    color: #ffffff;
    padding: 10px;
    text-decoration: none;
    border: 1px solid transparent;
    transition: border-color 0.5s;

    &.active {
      border: 1px solid #e14313;

      &:hover {
        color: #e14313;
      }
    }

    &.active-always {
      color: #e14313;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: #18181b;
    }
  }

  &__link {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 11;
    opacity: 1;
    cursor: pointer;
    transition: 0.3s;
    opacity: 1;

    &:hover {
      color: #999;

      .gg-close {
        opacity: 0.7;
      }
    }

    .gg-menu-left {
      position: relative;
      z-index: 11;
      display: block;
      margin-top: 10px;
      span {
        display: block;
        border: 3px solid transparent;
        border-right: 0 solid #003;
        border-bottom: 0 solid #000;
      }
    }
  }
}
</style>
