<template>
  <svg
    width="162"
    height="60"
    viewBox="0 0 162 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="header__logo_desktop"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.4696 19.3298V41.9963L50.7957 50.3196V27.6559L42.4696 19.3298ZM41.6981 42.7678H19.0373L27.3661 51.0967H50.027L41.6981 42.7678ZM17.483 42.7678V18.5668L9.17383 10.2464V51.0967H25.8231L17.4943 42.7678H17.483ZM18.2461 17.7868H42.4612L50.7957 26.1101V9.47485H9.94533L18.2573 17.7868H18.2461Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.3534 30.604C63.3534 34.9458 64.5811 36.6211 66.8027 36.6211C69.1172 36.6211 70.2773 34.9317 70.2773 30.604C70.2773 26.2762 69.0919 24.6178 66.8027 24.6178C64.5135 24.6178 63.3534 26.2903 63.3534 30.604ZM74.5206 30.604C74.5206 36.2044 71.6373 39.5833 66.8027 39.5833C61.9681 39.5833 59.1299 36.303 59.1299 30.604C59.1299 25.0345 62.0132 21.6528 66.8224 21.6528C71.6316 21.6528 74.5403 24.9106 74.5403 30.604H74.5206Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M89.1313 23.5281L87.1603 25.8708C86.333 25.0891 85.2405 24.6495 84.1024 24.6403C81.9287 24.6403 80.4026 26.3889 80.4026 30.5815C80.4026 34.7009 81.909 36.4522 84.1756 36.4522C85.53 36.4522 86.5183 35.7849 87.5094 35.019L89.3284 37.3871C88.6258 38.0882 87.7913 38.6432 86.8731 39.0202C85.9549 39.3971 84.9711 39.5885 83.9785 39.5833C79.2932 39.5833 76.1621 36.4269 76.1621 30.573C76.1621 24.8515 79.4678 21.6444 83.8575 21.6444C86.1776 21.6444 87.7291 22.3371 89.1341 23.5197L89.1313 23.5281Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.1742 31.84V39.1609H91.1309V22.0751H95.1742V28.6808H100.302V22.0751H104.348V39.1609H100.302V31.84H95.1742Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M110.632 30.604C110.632 34.9458 111.865 36.6211 114.084 36.6211C116.404 36.6211 117.567 34.9317 117.567 30.604C117.567 26.2762 116.379 24.6178 114.084 24.6178C111.789 24.6178 110.632 26.2903 110.632 30.604ZM121.802 30.604C121.802 36.2044 118.919 39.5833 114.084 39.5833C109.25 39.5833 106.395 36.303 106.395 30.604C106.395 25.0345 109.278 21.6528 114.084 21.6528C118.891 21.6528 121.802 24.9106 121.802 30.604Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M129.368 36.2297C131.269 36.2297 132.626 35.8327 132.626 33.9603C132.626 32.2342 131.421 31.5922 129.667 31.5922H127.893V36.2297H129.368ZM127.893 28.9089H129.396C131.086 28.9089 131.959 28.2669 131.959 26.8365C131.959 25.4061 131.024 24.9387 129.073 24.9387H127.893V28.9089ZM136.79 34.1827C136.79 38.0825 133.634 39.1637 129.346 39.1637H123.847V22.0751H128.678C133.262 22.0751 136.075 23.2324 136.075 26.4395C136.075 28.6076 134.521 29.7423 132.941 30.0999C134.842 30.466 136.788 31.4036 136.788 34.1658L136.79 34.1827Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M144.936 25.0092L143.14 32.5553H146.738L144.936 25.0092ZM142.45 35.478L141.586 39.1384H137.407L142.56 22.0527H147.468L152.612 39.1609H148.315L147.428 35.5005L142.45 35.478Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.2071 45.502V46.4058H60.8588V51.5417H59.7578V45.502H63.2071Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M65.8874 48.3965C66.0645 48.4311 66.2449 48.4453 66.4252 48.4387C67.2474 48.4387 67.7514 48.036 67.7514 47.3293C67.7514 46.6225 67.284 46.2987 66.5238 46.2987C66.3104 46.2949 66.0972 46.3128 65.8874 46.3522V48.3965ZM64.7949 45.5807C65.3534 45.4903 65.9187 45.4488 66.4843 45.4568C67.2896 45.4568 67.8922 45.6258 68.2667 45.9496C68.4578 46.1145 68.61 46.3197 68.7123 46.5505C68.8145 46.7813 68.8643 47.0319 68.858 47.2842C68.8709 47.5364 68.8313 47.7886 68.7416 48.0246C68.652 48.2607 68.5142 48.4755 68.3371 48.6555C67.9006 49.0947 67.2108 49.3003 66.4308 49.3003C66.2493 49.3085 66.0674 49.3 65.8874 49.275V51.5529H64.7949V45.5807Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M71.2377 45.4934L72.2598 47.8586C72.4203 48.2528 72.5414 48.6104 72.6878 48.968H72.716C72.8061 48.6864 72.9131 48.3063 73.0567 47.8417L73.8451 45.5047H75.008L73.8169 48.5513C73.5251 49.3811 73.1337 50.1725 72.6512 50.908C72.4802 51.147 72.2545 51.3414 71.9929 51.4752C71.7313 51.6089 71.4414 51.678 71.1476 51.6767C70.9569 51.6809 70.7667 51.6543 70.5845 51.5979L70.6746 50.7109C70.7989 50.7345 70.9253 50.7458 71.0519 50.7447C71.5615 50.7447 71.8966 50.3167 72.0374 49.976C72.062 49.9246 72.0727 49.8676 72.0682 49.8107C72.0638 49.7538 72.0444 49.6992 72.012 49.6522L69.9932 45.5047L71.2377 45.4934Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M81.1296 45.502V51.5417H80.0286V46.4058H77.5902V51.5417H76.4893V45.502H81.1296Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M87.9323 45.502V51.5417H86.8285V46.4058H84.3929V51.5417H83.292V45.502H87.9323Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M93.0309 48.9934L92.5578 47.5067C92.4396 47.1379 92.341 46.7268 92.2509 46.3804H92.234C92.1439 46.7296 92.0538 47.1491 91.9524 47.5067L91.4822 48.9934H93.0309ZM91.302 49.8381L90.7839 51.5501H89.6436L91.5808 45.5104H92.9886L94.9596 51.5501H93.7629L93.2195 49.8381H91.302Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M100.521 45.502V48.0896H100.701L102.545 45.502H103.88L101.695 48.2247C102.571 48.3852 102.94 48.9596 103.21 49.7227C103.432 50.3591 103.621 50.9672 103.889 51.5417H102.706C102.509 51.0962 102.341 50.6381 102.205 50.1704C101.963 49.4355 101.642 48.9061 100.78 48.9061H100.521V51.5417H99.4229V45.502H100.521Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M106.17 48.5485C106.17 49.7761 106.789 50.7616 107.845 50.7616C108.901 50.7616 109.512 49.7677 109.512 48.5091C109.512 47.3828 108.949 46.2875 107.845 46.2875C106.741 46.2875 106.17 47.3067 106.17 48.54V48.5485ZM110.675 48.4584C110.675 50.4829 109.447 51.6401 107.78 51.6401C106.113 51.6401 105.004 50.3393 105.004 48.5654C105.004 46.7098 106.167 45.4033 107.87 45.4033C109.636 45.4033 110.686 46.738 110.686 48.4584H110.675Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M117.579 49.0779C117.542 48.2979 117.497 47.3575 117.506 46.5437H117.48C117.283 47.2871 117.033 48.1008 116.762 48.8639L115.887 51.4797H115.042L114.237 48.9005C114.003 48.129 113.786 47.304 113.626 46.5437H113.609C113.58 47.3406 113.547 48.2979 113.502 49.1229L113.366 51.5529H112.336L112.739 45.5132H114.191L114.98 47.9403C115.194 48.6499 115.383 49.3651 115.543 50.038H115.571C115.732 49.3848 115.937 48.6302 116.171 47.9319L117.016 45.5132H118.449L118.798 51.5529H117.697L117.582 49.0891L117.579 49.0779Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M125.42 45.502V51.5417H124.317V46.4058H121.881V51.5417H120.777V45.502H125.42Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M130.52 48.9934L130.047 47.5067C129.928 47.1379 129.83 46.7268 129.74 46.3804H129.723C129.633 46.7296 129.543 47.1491 129.441 47.5067L128.96 48.9934H130.52ZM128.791 49.8381L128.27 51.5501H127.144L129.081 45.5104H130.489L132.46 51.5501H131.277L130.708 49.8381H128.791Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M135.27 45.502V47.9206H137.824V45.502H138.928V51.5417H137.824V48.8808H135.27V51.5417H134.169V45.502H135.27Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M142.098 45.5019V47.6333C142.098 48.5935 142.073 49.4805 142.028 50.2857H142.044C142.36 49.6072 142.754 48.8779 143.148 48.1993L144.745 45.4934H145.871V51.5331H144.84V49.3904C144.84 48.4415 144.84 47.67 144.922 46.89H144.902C144.592 47.6098 144.234 48.3078 143.83 48.9793L142.306 51.5331H141.067V45.5019H142.098Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M149.942 44.1757C149.959 44.4882 150.058 44.7219 150.407 44.7219C150.756 44.7219 150.846 44.4967 150.88 44.1757H151.651C151.606 44.8655 151.158 45.2147 150.379 45.2147C149.599 45.2147 149.216 44.8571 149.162 44.1757H149.942ZM149.035 45.5019V47.6334C149.035 48.5935 149.007 49.4805 148.962 50.2858H148.982C149.294 49.6072 149.689 48.8779 150.083 48.1993L151.679 45.4934H152.806V51.5331H151.767V49.3904C151.767 48.4415 151.767 47.67 151.846 46.89H151.829C151.519 47.6104 151.159 48.3084 150.753 48.9793L149.233 51.5416H148.005V45.5019H149.035Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "OsnovaLogo",
};
</script>
