<template>
  <div
    :class="{
      breadcrumbs: true,
      'breadcrumbs--dark': dark,
      'breadcrumbs--light': light,
    }"
  >
    <span>
      <router-link class="breadcrumbs__link" :to="{ name: 'MainPage' }">
        {{ mainLinkName }}
      </router-link>
      /
    </span>
    <span v-for="(crumb, index) in $route.meta.breadcrumbs" :key="index">
      <router-link class="breadcrumbs__link" :to="{ name: crumb.name }">
        {{ crumb.title }}
      </router-link>
      /
    </span>
    <span class="breadcrumbs__inactive-item">
      {{ breadcrumb | removeTag }}
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BreadCrumbs",
  props: {
    mainLinkName: {
      type: String,
      default: "Главная",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
    }),
  },
  filters: {
    removeTag(value) {
      if (value) {
        return value.replace(/<(.+)>/gi, "");
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  font-family: $font-family-rubik;
  font-weight: 400;
  font-size: 10px;
  line-height: 115%;
  opacity: 0.3;
  color: #ffffff;
  @media screen and (min-width: $screen-xs) {
    margin-top: 15px;
  }
  @media screen and (min-width: $screen-sm) {
    font-size: 15px;
  }
  &--dark {
    color: #000000;

    .breadcrumbs__link {
      color: #000000;
    }
  }

  &--light {
    font-size: 12px;
    line-height: 13.8px;
    letter-spacing: -0.01em;
    color: #ffffff;
    font-family: inherit;
    opacity: 1;

    .breadcrumbs__inactive-item {
      opacity: 0.5;
    }
  }

  &__link {
    color: #ffffff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
