<template>
  <div>
    <the-header fixed />
    <transition name="fade" mode="out-in">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

export default {
  name: "Page",
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>
