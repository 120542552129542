var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "smart-house-modal" }, [
    _c("div", { staticClass: "smart-house-modal__left" }, [
      _c("div", {
        staticClass: "smart-house-modal__title",
        domProps: { innerHTML: _vm._s(_vm.titleContent) },
      }),
      _c("div", {
        staticClass: "smart-house-modal__desc",
        domProps: { innerHTML: _vm._s(_vm.desc) },
      }),
      _c(
        "a",
        {
          staticClass: "smart-house-modal__link",
          attrs: { href: _vm.href, target: "_blank" },
        },
        [_vm._v(" " + _vm._s(_vm.linkText) + " ")]
      ),
    ]),
    _c("div", { staticClass: "smart-house-modal__right" }, [
      _c("picture", [
        _c("source", {
          attrs: { srcset: _vm.imageDesk, media: "(min-width: 768px)" },
        }),
        _c("img", { attrs: { src: _vm.imageMob } }),
      ]),
      _c(
        "div",
        {
          staticClass: "smart-house-modal__close",
          attrs: { role: "button" },
          on: { click: _vm.hide },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "40",
                height: "40",
                viewBox: "0 0 40 40",
                fill: "none",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M19.2924 20L13 26.2928L13.7071 26.9999L19.9995 20.7072L26.2921 27.0001L26.9992 26.293L20.7066 20L26.9992 13.7071L26.2921 13L19.9995 19.2929L13.7071 13.0002L13 13.7073L19.2924 20Z",
                  fill: "white",
                },
              }),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }