<template>
  <modal-dialog :visible="visible" @close="close">
    <template v-if="!success" slot="title">
      {{ title }} №{{ number }}
    </template>
    <div v-if="success" slot="content" class="subscribe-modal">
      <modal-success @close="close" />
    </div>
    <div v-if="!success" :id="idModal" slot="content" class="subscribe-modal">
      <form
        :id="id"
        ref="subscribe-form"
        method="POST"
        class="subscribe-modal__form subscribe-form"
        @submit.prevent="submit"
      >
        <div class="subscribe-form__row">
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-name'"
              ref="form-name"
              v-model="name"
              placeholder-value="Имя"
              hint="Введите корректные данные"
              type="text"
              placeholder-white
              required
            />
          </div>
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-last_name'"
              ref="form-last_name"
              v-model="lastName"
              placeholder-value="Фамилия"
              hint="Введите корректные данные"
              type="text"
              placeholder-white
              required
            />
          </div>
        </div>

        <div class="subscribe-form__row">
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-middle_name'"
              ref="form-middle_name"
              v-model="middleName"
              placeholder-value="Отчество"
              hint="Введите корректные данные"
              type="text"
              placeholder-white
              required
            />
          </div>
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-phone'"
              ref="form-phone"
              v-model="phone"
              v-mask="'+7 (###) ###-##-##'"
              type="tel"
              hint="Введите корректный номер телефона"
              placeholder-value="телефон"
              placeholder-white
              required
            />
            <div v-if="!isPhoneValid" class="form-phone_valid">
              Введите корректный номер телефонa
            </div>
          </div>
        </div>

        <div class="subscribe-form__row">
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-email'"
              ref="form-email"
              v-model="email"
              placeholder-value="E-mail"
              hint="Введите корректные данные"
              type="email"
              placeholder-white
              required
            />
          </div>
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-passport'"
              ref="form-passport"
              v-model="passportDetails"
              v-mask="'## ## ######'"
              type="tel"
              hint="Введите корректный номер телефона"
              placeholder-value="Серия и номер паспорта"
              placeholder-white
              required
            />
            <div v-if="!isPassportValid" class="form-phone_valid">
              Введите корректный данные
            </div>
          </div>
        </div>

        <div class="subscribe-form__row">
          <div class="subscribe-form__col">
            <ui-input
              :id="id + '-births'"
              ref="form-births"
              v-model="births"
              v-mask="'## ## ####'"
              type="tel"
              hint="Введите корректный номер телефона"
              placeholder-value="Дата рождения"
              placeholder-white
              required
            />
            <div v-if="!isBirthsValid" class="form-phone_valid">
              Введите корректный данные
            </div>
          </div>
          <div class="subscribe-form__col">
            <multiselect
              v-model="maritalStatus"
              :options="options"
              :searchable="false"
              :allow-empty="false"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Семейное пололожение"
            />
            <div
              v-if="isSelectValid"
              class="form-phone_valid form-phone_valid-select"
            >
              Выбирите один из вариантов
            </div>
          </div>
        </div>
        <div class="subscribe-form__row">
          <ui-button type="submit" @click="submitForm"> Отправить </ui-button>
        </div>
        <div class="subscribe-form__row">
          <ui-input
            :id="id + '-agreement'"
            ref="form-agreement"
            v-model="checkbox"
            class="row"
            type="checkbox"
            dark
            required
          >
            <template #label>
              <span
                >Нажимая кнопку «Отправить», я соглашаюсь с политикой обработки
                <router-link :to="{ name: 'UserAgreementPage' }" target="_blank"
                  >персональных данных</router-link
                >
              </span>
            </template>
          </ui-input>
        </div>
      </form>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";
import UiInput from "@/components/ui/UIInput";
import UiButton from "@/components/ui/UIButton";
import ModalSuccess from "@/components/dialogs/ModalSuccess";

export default {
  name: "ApartmentModalDialog",
  components: { ModalSuccess, UiButton, UiInput, ModalDialog },
  props: {
    title: {
      type: String,
      default: "Забронировать апартамент",
    },
  },
  data: () => ({
    id: "subscribe",
    visible: false,
    name: "",
    phone: "",
    passportDetails: "",
    lastName: "",
    middleName: "",
    births: "",
    email: "",
    number: "",
    floor: "",
    quantity: "",
    checkbox: true,
    success: false,
    idModal: null,
    isSelectValid: false,
    maritalStatus: "",
    options: [
      "женат / замужем",
      "в разводе",
      "вдовец / вдова",
      "гражданский брак",
      "холост / не замужем",
    ],
  }),
  computed: {
    isPhoneValid() {
      return this.phone.length >= 18 || this.phone.length < 1;
    },
    isPassportValid() {
      return (
        this.passportDetails.length >= 12 || this.passportDetails.length < 1
      );
    },
    isBirthsValid() {
      return this.births.length >= 10 || this.births.length < 1;
    },
  },
  methods: {
    show(number, floor, quantity, amount, idModal = null) {
      this.visible = true;
      this.name = "";
      this.lastName = "";
      this.middleName = "";
      this.births = "";
      this.email = "";
      this.phone = "";
      this.passportDetails = "";
      this.number = number;
      this.floor = floor;
      this.quantity = quantity;
      this.amount = amount;
      this.idModal = idModal;
      this.checkbox = true;
      this.success = false;
    },
    close() {
      this.visible = false;
    },
    sendMessage() {
      window.Comagic.addOfflineRequest({
        name:
          "Имя: " +
          this.name +
          " Фамилия: " +
          this.lastName +
          " | " +
          "Форма: " +
          this.title,
        email: this.email,
        phone: this.phone,
        message:
          "Апартаменты №: " +
          this.number +
          " , " +
          "этаж " +
          this.floor +
          " , " +
          this.quantity +
          " комнатная" +
          " , " +
          this.amount +
          " руб" +
          " | " +
          "URL: " +
          document.URL +
          " | " +
          "ID: " +
          this.idModal,
      });
      this.phone = "";
    },
    submit(e) {
      if (
        this.phone.length === 18 &&
        this.maritalStatus !== "" &&
        this.births.length === 10 &&
        this.passportDetails.length === 12 &&
        this.name.trim() &&
        this.lastName.trim() &&
        this.middleName.trim()
      ) {
        this.success = true;
        this.isSelectValid = false;
        this.maritalStatus = "";
        this.sendMessage();
      } else {
        this.isSelectValid = true;
      }
      e.preventDefault();
    },
    submitForm() {
      this.$refs["form-name"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-last_name"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-middle_name"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-phone"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-email"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-passport"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-births"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-agreement"].$refs.VueInputUi.classList.add("attempt");
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect__content-wrapper {
  border: none;
}
.subscribe-modal {
  &__desc {
    font-family: $font-family-bebas;
    font-weight: 300;
    font-size: 14px;
    line-height: 132.5%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.6;
    margin-bottom: 35px;
  }

  &__form,
  .subscribe-form {
    &__row {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
      }
    }

    &__col {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      margin-bottom: 10px;

      @media screen and (min-width: $screen-sm) {
        width: 50%;
      }

      &:first-child {
        padding-right: 0;

        @media screen and (min-width: $screen-sm) {
          padding-right: 15px;
        }
      }

      &:last-child {
        padding-left: 0;

        @media screen and (min-width: $screen-sm) {
          padding-left: 15px;
        }
      }

      &--full {
        @media screen and (min-width: $screen-sm) {
          padding-left: 0 !important;
          padding-right: 0 !important;
          width: 100%;
        }
      }
      .form-phone_valid-select {
        bottom: -21px !important;
        @media screen and (min-width: $screen-sm) {
          bottom: 8px !important;
        }
      }

      .form-phone_valid {
        position: absolute;
        bottom: -9px;
        font-family: Rubik;
        font-size: 11px;
        text-transform: none;
        color: #ed0000;
        line-height: 132.5%;
      }
    }
  }
}
</style>
