<template>
  <modal-dialog :visible="visible" @close="close">
    <template slot="title"> Выгода до&nbsp;450&nbsp;т.р. </template>
    <div :id="idModal" slot="content" class="subscribe-modal">
      <div class="subscribe-modal__desc">
        *&nbsp;при контрактации до&nbsp;31.12.2021&nbsp;г.<br />Размер скидки
        уменьшается ежедневно
      </div>
      <ui-button
        class="phone-content__recall"
        data-popup-with-chats
        data-popup-with-chats-title="Узнать подробнее"
      >
        Узнать подробнее
      </ui-button>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/dialogs/ModalDialog";
import UiButton from "@/components/ui/UIButton";

export default {
  name: "BannerModalDialog",
  components: { UiButton, ModalDialog },
  data: () => ({
    id: "subscribe",
    visible: false,
    name: "",
    phone: "",
    checkbox: true,
    success: false,
    description: null,
    title: null,
    buttonName: null,
    idModal: null,
    isSmallTitle: false,
  }),
  computed: {
    isPhoneValid() {
      return this.phone.length >= 18 || this.phone.length < 1;
    },
  },
  methods: {
    show(
      title = null,
      description = null,
      buttonName = null,
      idModal = null,
      isSmallTitle = false
    ) {
      this.visible = true;
      this.checkbox = true;
      this.success = false;
      this.title = title;
      this.buttonName = buttonName;
      this.description = description;
      this.idModal = idModal;
      this.isSmallTitle = isSmallTitle;
    },
    close() {
      this.visible = false;
    },
    sendMessage() {
      window.Comagic.addOfflineRequest(
        {
          name: "Имя: " + this.name + " | " + "Форма: " + this.title,
          phone: this.phone,
          message:
            "Описание: " +
            this.title +
            " | " +
            "URL: " +
            document.URL +
            " | " +
            "ID: " +
            this.idModal,
        },
        () => {}
      );
      this.name = "";
      this.phone = "";
    },
    submit(e) {
      if (this.phone.length === 18 && this.name.trim()) {
        this.success = true;
        this.sendMessage();
      }
      e.preventDefault();
    },
    submitForm() {
      this.$refs["form-name"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-phone"].$refs.VueInputUi.classList.add("attempt");
      this.$refs["form-agreement"].$refs.VueInputUi.classList.add("attempt");
    },
  },
};
</script>

<style scoped lang="scss">
.subscribe-modal {
  &__desc {
    font-family: $font-family-bebas;
    font-weight: 300;
    font-size: 14px;
    line-height: 132.5%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.6;
    margin-bottom: 35px;
  }

  &__form,
  .subscribe-form {
    &__row {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
      }
    }

    &__col {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      margin-bottom: 10px;

      @media screen and (min-width: $screen-sm) {
        width: 50%;
      }

      &:first-child {
        padding-right: 0;

        @media screen and (min-width: $screen-sm) {
          padding-right: 15px;
        }
      }

      &:last-child {
        padding-left: 0;

        @media screen and (min-width: $screen-sm) {
          padding-left: 15px;
        }
      }

      .form-phone_valid {
        position: absolute;
        bottom: -7px;
        font-family: Rubik;
        font-size: 12px;
        text-transform: none;
        color: #ed0000;
        line-height: 132.5%;
      }
    }
  }
}
</style>
