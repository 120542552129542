<template>
  <div :class="classes">
    <content-wrapper-block class="header-block__wrapper">
      <div class="header-block__left">
        <div class="header-block__logo" @click="click" @touchstart.stop>
          <router-link to="/" @click.native="setMenuOpened(false)">
            <logo class="header-block__logo-svg" />
          </router-link>
        </div>
        <menu-block v-if="!menuIsOpened" class="header-block__menu">
          <router-link
            slot="link"
            :to="{ name: 'AboutPage' }"
            class="header-block__link"
            active-class="header-block__link--active"
          >
            О проекте
          </router-link>
          <router-link
            slot="link"
            :to="{ name: 'FilterPage' }"
            class="header-block__link header-block__link--border"
            active-class="header-block__link--active"
          >
            Планировки
          </router-link>
          <router-link
            slot="link"
            :to="{ name: 'NewsCatalogPage' }"
            class="header-block__link"
            active-class="header-block__link--active"
          >
            Новости
          </router-link>
          <router-link
            slot="link"
            :to="{ name: 'ContactPage' }"
            active-class="header-block__link--active"
            class="header-block__link"
          >
            Контакты
          </router-link>
        </menu-block>
      </div>
      <div class="header-block__phone-column" @touchstart.stop>
        <div v-if="!menuIsOpened" class="header-block__right">
          <a
            class="header-block__booking-phone"
            data-popup-with-chats
            data-popup-with-chats-title="Заказать звонок"
            >Заказать звонок</a
          >
        </div>
        <div class="header-block__right">
          <a class="header-block__phone comagic_phone1" :href="'tel:' + phone">
            {{ phone }}
          </a>
        </div>
      </div>
      <menu-button-block />
    </content-wrapper-block>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import MenuButtonBlock from "@/components/blocks/header/MenuButtonBlock";
import MenuBlock from "@/components/blocks/header/MenuBlock";
import Logo from "@/components/icons/Logo";

export default {
  name: "HeaderBlock",
  components: {
    ContentWrapperBlock,
    Logo,
    MenuButtonBlock,
    MenuBlock,
  },
  computed: {
    ...mapState({
      menuIsOpened: (state) => state.menuIsOpened,
      isFixed: (state) => state.isFixed,
      storage: (state) => state.storage,
    }),
    info() {
      return this.storage.info_line;
    },
    classes() {
      return {
        "header-block": true,
        "header-block--fill":
          !this.isTransparent ||
          this.isFixed ||
          this.fixed ||
          this.menuIsOpened,
        "header-block--fixed": this.isFixed || this.fixed,
      };
    },
  },
  methods: {
    ...mapMutations({
      setMenuOpened: "SET_MENU_OPENED",
    }),
    click() {
      this.$emit("click");
      this.backToTop();
    },
    backToTop() {
      if (window.pageYOffset > 0) {
        window.scrollBy(0, -80);
        setTimeout(this.backToTop, 0);
      }
    },
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    isTransparent: {
      type: Boolean,
      default: true,
    },
    phone: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.header-block {
  &__logo {
    cursor: pointer;
    svg {
      width: 97px;
      overflow: visible;
    }

    @media screen and (min-width: $screen-sm) {
      margin-right: 50px;
      svg {
        width: 158px;
      }
    }
    @media screen and (min-width: $screen-md) {
      svg {
        width: auto;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.header-block {
  background: #1e1919;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;

  & > .header-block__wrapper {
    height: 55px;
  }

  @media screen and (min-width: $screen-xs) {
    & > .header-block__wrapper {
      height: auto;
    }
  }
  @media screen and (min-width: $screen-md) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  @media screen and (min-width: $screen-lg) {
    background: transparent;
  }

  &--fill {
    background: #1e1919;
  }

  &__menu {
    display: none;

    @media screen and (min-width: $screen-lg) {
      display: block;
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
    z-index: 99;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left {
    @media (max-width: $screen-lg) {
      margin-right: auto;
    }
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: 20px;
  }

  &__phone {
    text-align: right;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    margin-right: 17px;

    @media screen and (min-width: $screen-sm) {
      font-size: 18px;
      margin-right: 32px;
    }
  }
  &__phone-column {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: flex-end;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
    }
  }
  &__link {
    font-family: "Rubik", sans-serif;
    font-size: 10px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 10px;
    text-decoration: none;
    border: 1px solid transparent;
    transition: border-color 0.5s;
    margin-right: 5px;

    &:hover {
      border-bottom: 1px solid #fff;
    }

    &--active {
      // border: 1px solid #E14313;
      color: #e14313;

      &:hover {
        border-bottom: 1px solid #e14313;
      }
    }

    &--border {
      border: 1px solid #e14313;

      &:hover {
        border-bottom: 1px solid #e14313;
        color: #e14313;
      }
    }
  }
}
.header-block__booking-phone {
  text-align: right;
  cursor: pointer;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #e14313;
  font-size: 14px;
  margin-right: 17px;

  @media screen and (min-width: $screen-sm) {
    font-size: 18px;
    margin-right: 32px;
  }
}
</style>
